import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

import SelectField from '../../../components/Form/SelectField/SelectField';
import Buttons from '../../../components/Form/Button/Button';


import '../../../assets/css/users.css';
import { useDeleteGuardianMutation, useGetGuardianDetailsQuery, useUpdateGuardianMutation } from '../../../redux/features/users/guardianApi';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import DraggableDialog from '../../../components/hooks/Dialog';


const GuardiansDetails = ({ closeDrawer, userId, guardianUniqueId, userDetailsStatus, setUserDetailsStatus, userDetailsRelationship, setUserDetailsRelationship, updateGuardianOptions }) => {
  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({}))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(even)': {
        backgroundColor: '#F8F8F8',
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========//
  const { data, isLoading, error } = useGetGuardianDetailsQuery({ studentId: userId, guardianUniqueId }, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const [deleteGuardian, deleteGuardianOptions] = useDeleteGuardianMutation();


  useProtected(error);
  useProtected(deleteGuardianOptions?.error);

  const [userDetails, setUserDetails] = useState({
    firstName: "",
    lastName: "",
    email: "",
    preferredContact: "",
    homePhone: "",
    workPhone: "",
    address: "",
    occupation: "",
    preferredLanguage: "",
    highestEducation: "",
  });
  const [status, setStatus] = useState(userDetailsStatus);
  const [relationship, setRelationship] = useState(userDetailsRelationship);

  useEffect(() => {
    if (!isLoading && !error && data) {
      setStatus(data?.body?.data?.gurdian?.status);
      setUserDetailsStatus(data?.body?.data?.gurdian?.status)
      setRelationship(data?.body?.data?.gurdian?.relationShip);
      setUserDetailsRelationship(data?.body?.data?.gurdian?.relationShip)
      setUserDetails({
        ...userDetails,
        firstName: data?.body?.data?.gurdianDetails?.firstName,
        lastName: data?.body?.data?.gurdianDetails?.lastName,
        email: data?.body?.data?.gurdianDetails?.email,
        preferredContact: data?.body?.data?.gurdianDetails?.preferredContact,
        homePhone: data?.body?.data?.gurdianDetails?.homePhone,
        workPhone: data?.body?.data?.gurdianDetails?.workPhone,
        address: data?.body?.data?.gurdianDetails?.address ? data?.body?.data?.gurdianDetails?.address?.label : "",
        occupation: data?.body?.data?.gurdianDetails?.occupation,
        preferredLanguage: data?.body?.data?.gurdianDetails?.preferredLanguage,
        highestEducation: data?.body?.data?.gurdianDetails?.highestEducation
      });

    }
  }, [data, isLoading, error]);


  const statusOptions = [
    {
      label: 'Active',
      value: 'active'
    },
    {
      label: 'Inactive',
      value: 'inactive'
    }
  ];

  const relationShipOptions = [
    {
      label: 'Father',
      value: 'father'
    },
    {
      label: 'Mother',
      value: 'mother'
    },
    {
      label: 'Step-father',
      value: 'step-father'
    },
    {
      label: 'Step-mother',
      value: 'step-mother'
    },
    {
      label: 'Sibling',
      value: 'sibling'
    },
    {
      label: 'Aunt',
      value: 'aunt'
    },
    {
      label: 'Uncle',
      value: 'uncle'
    },
    {
      label: 'Brother',
      value: 'brother'
    },
    {
      label: 'Sister',
      value: 'sister'
    },
    {
      label: 'Cousin',
      value: 'cousin'
    },
    {
      label: 'Grandparent',
      value: 'grandparent'
    },
  ];

  const handleInputOnchange = (e) => {
    if (e.target.name === 'status') {
      setStatus(e.target.value);
      setUserDetailsStatus(e.target.value);
    } else if (e.target.name === "relationShip") {
      setRelationship(e.target.value);
      setUserDetailsRelationship(e.target.value);
    }
  }

  //===================== Delete dialog part start ====================================//
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDelete = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false);
  };
  const handleConfirmCourseDeleteDialog = async () => {
    handleDeleteGuardian()
  };
  const handleDeleteGuardian = async () => {
    const result = await deleteGuardian({ id: userId, guardianId: [guardianUniqueId] });
    if (result?.data && !result?.data?.error) {
      toast.success('Guardian removed successfully');
      // setRole('');
      setUserDetails(null);
      closeDrawer();
    } else {
      toast.error(result.data.message);
    }
    console.log({ result });
  };
  //===================== Delete dialog part end ====================================//

  return (
    <div className="ProfileTable">
      {
        (isLoading || updateGuardianOptions?.isLoading) ? (
          <Loader
            height="80px"
            width="80px"
            borderWidth="8px"
            isFullWidth={true}
            isOverlay={true}
          />
        ) : (
          <>
            <div className="TableContainer oddEvenTable borderGray">
              <TableContainer component={Paper}>
                <Table aria-label="customized table">
                  <TableBody>
                    <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Status
                      </StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">
                        <div className="UserTypeSelect">
                          < SelectField
                            label="Status"
                            options={statusOptions}
                            handleChange={handleInputOnchange}
                            selectedOption={status}
                            name="status"
                          />
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Name
                      </StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">
                        {userDetails?.firstName} {userDetails?.lastName}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Relation
                      </StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">
                        <div className="UserTypeSelect">
                          <SelectField
                            label="Relationship"
                            options={relationShipOptions}
                            handleChange={handleInputOnchange}
                            selectedOption={relationship}
                            name="relationShip"
                          />
                        </div>
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Preferred Contact
                      </StyledTableCell>
                      {userDetails?.preferredContact ? <StyledTableCell align="left" className="TableInfo">
                        +1 (123) 123-1234 <span style={{ fontWeight: "400" }}>(Home Phone)</span>
                      </StyledTableCell> : <StyledTableCell align="left" className="TableInfo">
                        {userDetails?.email || "N/A"}
                      </StyledTableCell>}
                      {/* <StyledTableCell align="left" className="TableInfo">
                        +1 (123) 123-1234 <span style={{ fontWeight: "400" }}>(Home Phone)</span>
                      </StyledTableCell> */}
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Email
                      </StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">
                        {userDetails?.email || <span className="TableNodate">No data available</span>}
                      </StyledTableCell>
                    </StyledTableRow>



                    <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Home Phone
                      </StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">
                        {userDetails?.homePhone || <span className="TableNodate">No data available</span>}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Work Phone
                      </StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">
                        {userDetails?.workPhone || <span className="TableNodate">No data available</span>}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Cell/Mobile Phone
                      </StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">
                        {userDetails?.cellPhone || <span className="TableNodate">No data available</span>}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Address
                      </StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">
                        {userDetails?.address || <span className="TableNodate">No data available</span>}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Highest Education
                      </StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">
                        {userDetails?.education || <span className="TableNodate">No data available</span>}
                      </StyledTableCell>
                    </StyledTableRow>


                    <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Occupation
                      </StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">
                        {userDetails?.occupation || <span className="TableNodate">No data available</span>}
                      </StyledTableCell>
                    </StyledTableRow>

                    <StyledTableRow>
                      <StyledTableCell align="left" className="TableLabel">
                        Preferred Language
                      </StyledTableCell>
                      <StyledTableCell align="left" className="TableInfo">
                        {userDetails?.preferredLanguage || <span className="TableNodate">No data available</span>}
                      </StyledTableCell>
                    </StyledTableRow>





                  </TableBody>
                </Table>
              </TableContainer>
            </div>

            <div className="DividerLine"></div>

            <div className="DeleteUserSection">
              <h2>Remove guardian/parent</h2>
              <p>Once a parent is removed they won't be able to access this student’s information. </p>

              <Buttons
                label="Remove Guardian/parent"
                buttonColor="#fff"
                border="#FFB606 1px solid"
                color="#000"
                width=""
                height="48px"
                fontSize="14px"
                link=""
                onClick={handleOpenDelete}
              />
            </div>
          </>
        )
      }

      {/* Delete Group*/}
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        title="Remove guardian/parent"
        body={<div>Are you sure you want to remove this guardian/parent?
        </div>}
        ModalFooter={true}
        handleConfirmCloseDialog={handleConfirmCourseDeleteDialog}
        actionButton="Delete"
      />

    </div>

  );
};

export default GuardiansDetails;
