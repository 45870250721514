import * as React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Button, IconButton } from '@mui/material';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import ErrorOutlineRoundedIcon from '@mui/icons-material/ErrorOutlineRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import './PaymentModal.css';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  p: 0,
  width: 552,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '12px',
  boxShadow: 24,
  overflow: 'hidden',
};

const PaymentModal = (props) => {
  const { open, handleClose, paymentStatus } = props;

  return (
    <>
      <div className="paymentModalContainer">
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="modalBodyContainer">
              <IconButton onClick={handleClose}>
                <CloseRoundedIcon
                  sx={{ color: `${paymentStatus ? '#ffffff' : '#FF6200'}` }}
                />
              </IconButton>
              <div
                className={`modalPaymentStatusContainer ${
                  paymentStatus ? 'payment' : 'pending'
                }`}
              >
                {paymentStatus ? (
                  <>
                    <CheckCircleRoundedIcon />
                    <h3>Payment Successfull</h3>
                  </>
                ) : (
                  <>
                    <ErrorOutlineRoundedIcon />
                    <h3>Payment Pending</h3>{' '}
                  </>
                )}
              </div>
              <div className="modalPaymentBody">
                <h2>Order placed successfully</h2>
                {paymentStatus ? (
                  <p>
                    Your payment has been processed! <br /> A receipt has been
                    emailed to you
                  </p>
                ) : (
                  <p>
                    Please send your payment at the earliest to confirm your
                    order inshaAllah
                  </p>
                )}
                <Button
                  variant="contained"
                  //   onClick={}
                  sx={{
                    padding: '8px 0',
                    width: '100%',
                    color: '#FFFFFF',
                    background: '#0450E1',
                    borderRadius: '8px',
                    boxShadow: 'none',
                    '&:hover': {
                      color: '#FFFFFF',
                      background: '#0450E1',
                      boxShadow: 'none',
                    },
                  }}
                >
                  Done
                </Button>
              </div>
            </div>
          </Box>
        </Modal>
      </div>
    </>
  );
};

export default PaymentModal;
