import React, { useState, forwardRef, useImperativeHandle, useRef } from 'react';
import TextField from '../Form/TextField/TextField';
import { Grid } from '@material-ui/core/';
import FileUplaoder from '../Form/FileUplaoder/FileUplaoder'
import SimpleBackdrop from '../../components/Form/Backdrop/SimpleBackdrop';
import './MediaLibrary.css';
import { set } from 'lodash';
import AWS from "aws-sdk";
import Loader from '../Loader/Loader';
import config from '../../config';
import { useSelector } from 'react-redux';
import { makeSafeString } from '../../hooks/HelperFunctions';
import { toast } from 'react-toastify';

const CONF_S3_DIRECTORY =
  config.env === "production"
    ? config.s3.s3_directory_prod
    : config.s3.s3_directory_dev;

const S3_BUCKET = config.s3.s3_bucket;
const S3_DIRECTORY = CONF_S3_DIRECTORY;
const S3_BASE_URL = config.s3.s3_base_url;
const REGION = config.s3.s3_region;
const S3_ACCESS_KEY = config.s3.s3_access_key;
const S3_SECRET_ACCESS_KEY = config.s3.s3_secret_access_key;

const REACT_APP_CDN_URL = config.s3.s3_cdn_url;

AWS.config.update({
  accessKeyId: S3_ACCESS_KEY,
  secretAccessKey: S3_SECRET_ACCESS_KEY,
});

const s3Bucket = new AWS.S3({
  params: { Bucket: S3_BUCKET },
  region: REGION,
});

const UploadFromDevice = forwardRef((props, ref) => {
  const { dba } = useSelector(state => state.auth.user);
  const childRef = useRef(null);

  const [orgId, setOrgId] = useState(localStorage.getItem('orgId'));

  const [progress, setProgress] = useState(0);

  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [imageName, setImageName] = useState(props.imageName);

  const [selectedFile, setSelectedFile] = useState(null);

  const [subDomain, setSubDomain] = useState("");

  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);

  const clickButton = () => {

    if (childRef.current) {
      childRef.current.clickButton();
    }
  }


  useImperativeHandle(ref, () => ({
    clickButton: () => clickButton(),
    uploadFile: () => uploadFile(),
  }));



  const handleValidation = (validationError) => {
    // set form validation error
    if (validationError) {

    }
  }
  const handleChanges = (fieldName) => (event) => {
    if (fieldName === "imageName") {
      setImageName(event.target.value);
      props.setImageName(event.target.value);
    }
  };

  const uploadFile = async () => {
    setOpenBackdrop(false);
    let file = selectedFile;
    if (!dba || dba == '') {
      toast.error("Something went wrong during upload file")
      return false
    }
    let formattedDba = makeSafeString(dba)
    let filePath = `${formattedDba}/media/`;

    let objFilePath = S3_DIRECTORY;
    // objFilePath += filePath + randString(5) + "-" + file.name;
    objFilePath += filePath + file.name;

    // console.log("Inside file upload", file, 100);

    // return '';

    const params = {
      ACL: "public-read",
      Body: file,
      Bucket: S3_BUCKET,
      Key: objFilePath,
    };

    await s3Bucket
      .putObject(params)
      .on("httpUploadProgress", (evt) => {
        setProgress(Math.round((evt.loaded / evt.total) * 100));
      })
      .send((err) => {
        if (err) console.log(err);
        props.onFileUpload(
          REACT_APP_CDN_URL + objFilePath,
          file.name,
          Number(file.size) / 1024,
          file.type,
          width,
          height
        );
        console.log("file info", REACT_APP_CDN_URL + objFilePath,
          file.name,
          Number(file.size) / 1024,
          file.type,
          width,
          height)
      });
    setOpenBackdrop(false);
  };

  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  const handleFileSelected = (file) => {
    setSelectedFile(file);
    props.fileSelected(true);
  };


  return (
    <>
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      {
        props?.isLoading ? (
          <Loader
            height="80px"
            width="80px"
            borderWidth="8px"
            isFullWidth={true}
            isOverlay={true}
          />
        ) : (
          <>
            <Grid container spacing={3}>

              <Grid item xs={12} md={12}>
                <div className='MediaLibPanelTextField'>
                  <TextField
                    label="Media Name"
                    // error={props.showImageNameError}
                    // helperText={props.imageErrorMessage}
                    required={true}
                    // email={true}
                    value={imageName}
                    handleValidation={handleValidation}
                    handleChange={(val, e) => handleChanges("imageName")(e)}
                    placeholder=""
                    count={50}
                    // helperText={imageName.length === 0 ? props.errors.name === "45645" : 4554}
                    helperText={props.errors.name}
                    error={Boolean(props.errors.name)}
                  />
                </div>
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <FileUplaoder
                  FieldError={props.showFileUploadError ? "FieldError" : ""}
                  label="Upload from your device*"
                  required={true}
                  filePath={`courses/course-images/`}
                  uploadButton="Upload Image"
                  //file = {courseData.courseImage} 
                  // fileType="image"
                  fileType={"image" || "audio" || "video"}
                  showThumb={true}
                  //onUpload={ (filePath) => handleChangeWithData("courseImage", filePath) }
                  //helpertext="Maximum size of 5.0MB."
                  helpertext="Maximum size of 5.0MB."
                  onUpload={props.onFileUpload}
                  mediaName={imageName}
                  nameMandatory="1"

                  ref={childRef}
                  setWidth={setWidth}
                  setHeight={setHeight}
                  setSelectedFile={handleFileSelected}
                  maxSize={5}
                  setFileUploadError={props.setFileUploadError}
                />

              </Grid>
            </Grid>

          </>
        )
      }

    </>


  );
});

export default UploadFromDevice;

