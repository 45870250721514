import axios from 'axios';
import config from '../../config';

const axiosApi = axios.create({
  baseURL: config.api_url, // Set your API's base URL using environment variables
  timeout: 10000, // Set a reasonable timeout
  headers: {
    'Content-Type': 'application/json',
    // You can add other common headers here if needed
  },
});

export default axiosApi;
