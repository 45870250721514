import React, { useState, useEffect } from "react";
import axios from 'axios';
import { useNavigate, useSearchParams } from "react-router-dom";

import AlrtMessage from '../../components/Form/AlrtMessage/AlertMessage';
import { Grid } from '@material-ui/core/';
import Buttons from '../../components/Form/Button/Button';
import TextField from '../../components/Form/TextField/TextField';
// import Header from './header/Header';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import OTPInput from "otp-input-react";
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
// import CustomizedSnackbar from '../../hooks/Snackbar';
import '../../assets/css/form.css';
import { useAddPhoneNumberMutation, useVerifyPhoneNumberOtpMutation } from "../../redux/features/users/userApi";
import useProtected from "../../hooks/useProtected";
import { toast } from "react-toastify";
import SimpleBackdrop from "../../components/Form/Backdrop/SimpleBackdrop";
import LogoHeader from "./LogoHeader/LogoHeader";


function VerifyOtp(props) {
    const [verifyPhoneNumber, verifyPhoneNumberOptions] = useVerifyPhoneNumberOtpMutation();
    const [addPhoneNumber, addPhoneNumberOptions] = useAddPhoneNumberMutation();

    useProtected(addPhoneNumberOptions.error);
    useProtected(verifyPhoneNumberOptions.error);

    const navigate = useNavigate();

    const [alertOpen, setAlertOpen] = useState(false);
    const [alertMessage, setAlertMessage] = useState("");
    const [alertSeverity, setAlertSeverity] = useState("error");

    const [OTP, setOTP] = useState("");
    const [token, setToken] = useState("");
    const [phone, setPhone] = useState("");

    const [renderButton, setRenderButton] = useState(true);
    const [renderTime, setRenderTime] = useState(9);

    const [continueButton, setContinueButton] = useState("disabled");

    const [resendOtpButton, setResendOtpButton] = useState("");

    //error state class for otp field
    const [errorStateOtp, setErrorStateOtp] = useState("OtpFields");

    const [errorMessage, setErrorMessage] = useState("");

    const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;


    const handleSetOtp = (otp) => {
        console.log(otp);
        setOTP(otp);
        //remove error message 
        setErrorMessage("");
        //remove error state class
        setErrorStateOtp("OtpFields");
        if (otp.length == 6) {
            setContinueButton("");
        } else {
            setContinueButton("disabled");
        }
    }

    // const processResponse = (response) => {
    //     console.log(response);
    //     if (response.status == 200) {
    //         setAlertSeverity("success");
    //         setAlertMessage(response.data.message);
    //         setAlertOpen(true);


    //     } else {
    //         setAlertSeverity("error");
    //         setAlertMessage(response.data.body.message);
    //         setAlertOpen(true);
    //     }
    // }

    const handleVerifyOtp = async () => {

        console.log(OTP);

        if (OTP == "") {

            setAlertSeverity("error");
            setAlertMessage("You should put an valid OTP");
            setAlertOpen(true);

            return false;

        } else {


            const data = { otp: OTP };

            const result = await verifyPhoneNumber(data);
            if (!result?.data?.error) {
                toast.success(result?.data?.message);
                navigate('/profile');
            } else {
                // toast.error(result?.data?.message)
                setErrorStateOtp("OtpFields errorStateOtp");
                setErrorMessage("Code didn't match, try again ");
            }

        }

    }

    const alertCloseHandler = () => {
        setAlertOpen(false);
    }

    // handle reference login
    let [searchParams, setSearchParams] = useSearchParams();
    let userToken = searchParams.get("token");
    const [countdown, setCountdown] = useState(120);

    useEffect(() => {
        const phone = searchParams.get('phone');
        if (phone) {
            setPhone(phone);
        }

    }, [searchParams]);

    //once resendOtpButton is disalbed then wait 2 mins and enable it
    useEffect(() => {
        if (resendOtpButton == "disabled") {
            const interval = setInterval(() => {
                setCountdown(countdown => countdown - 1);
            }, 1000);
            return () => clearInterval(interval);
        }
    }, [resendOtpButton]);



    const resendOtpCode = async () => {

        if (!phone) {
            toast.error("Phone number required");
            return;
        }
        const data = {
            // phone_number: phone.number,
            // country_code: phone.country.dialCode,
            phone,
            resendOtp: true
        };
        const result = await addPhoneNumber(data);
        if (!result?.data?.error) {
            toast.success(result?.data?.message);
            setResendOtpButton("disabled");
            //empty the otp field 
            setOTP("");
            //remove the error state class
            setErrorStateOtp("OtpFields");
            //set the error message to empty
            setErrorMessage("");
            //focus on the otp field 
            document.querySelector(".OtpField").focus();

        } else {
            toast.error(result?.data?.message)
        }

    }


    return (
        <>
            {/* <Header /> */}
            <div className="SignContainer">
                <LogoHeader />
                <SimpleBackdrop open={verifyPhoneNumberOptions.isLoading || addPhoneNumberOptions.isLoading} />
                <div className="SignContainerInner">
                    {/* <AlrtMessage open={alertOpen} message={alertMessage} severity={alertSeverity} closeHandler={alertCloseHandler} /> */}
                    {/* <CustomizedSnackbar open={alertOpen} severity={alertSeverity} message={alertMessage} handleClose={alertCloseHandler} /> */}

                    <div className="SignForm">
                        <div className="FormHeader">
                            <button className="BackButton" onClick={() => window.location.replace(`${window.location.origin}/add-phone-number`)}><ArrowBackIosNewRoundedIcon /></button>
                            <h1>Verify phone number</h1>
                            <span></span>
                        </div>

                        <div className="SignFormGrid">
                            <Grid container spacing={3}>

                                <Grid item xs={12} md={12}>
                                    <div className={errorStateOtp}>
                                        <OTPInput className="OtpField" value={OTP} onChange={handleSetOtp} autoFocus OTPLength={6} otpType="number" disabled={false} style={false} />
                                    </div>
                                    <div className="errorState">{errorMessage}</div>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <p style={{ textAlign: "center" }}>An SMS with a verification code has been sent to your mobile number. Enter the code to continue.</p>
                                </Grid>

                                <Grid item xs={12} md={12}>
                                    <Buttons
                                        label="Verify"
                                        buttonColor="#004FE0"
                                        border="#ccc 1px solid"
                                        color="#fff"
                                        width="100%"
                                        height="48px"
                                        fontSize="14px"
                                        disabled={continueButton}
                                        link=""
                                        onClick={handleVerifyOtp}
                                    />
                                    <div className="BottomButton" style={{ marginTop: "10px" }}>
                                        <Buttons
                                            label="Resend Code"
                                            buttonColor="#FFF"
                                            border="#ccc 0px solid"
                                            color="#004FE0"
                                            width="100%"
                                            height="48px"
                                            fontSize="14px"
                                            disabled={resendOtpButton}
                                            link=""
                                            onClick={resendOtpCode}
                                        />
                                    </div>
                                </Grid>

                                {/* <Grid item xs={12} md={12} style={{ textAlign: "center" }}>
                  <ResendOTP className="ResendButton" renderButton={renderButton} renderTime={renderTime} maxTime={10}/> 
                </Grid> */}

                            </Grid>
                        </div>
                    </div>

                </div>
                <div className="SignFormFooter">
                    <SecurityOutlinedIcon />
                    <span>SECURE SSL ENCRYPTION</span>
                </div>

            </div>
        </>

    );
}


export default VerifyOtp;

