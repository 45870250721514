import React, { useState } from "react";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import RoleDetails from './RoleDetails';
import Permissions from './Permissions';
import UsersRole from './UsersRole';
import { useCheckRoleNameExistedMutation, useGetRoleDetailsQuery } from "../../../redux/features/roles/roleApi";
import { useEffect } from "react";
import { Badge } from "@mui/material";
import useProtected from "../../../hooks/useProtected";
import { toast } from "react-toastify";


const
  CreateRole = (props) => {
    const {
      rolesPermissionsObject,
      setRolesPermissionsObject,
      errors,
      setErrors,
      roleUsers,
      setRoleUsers,
      setRoleUsersToUpdate,
      roleUsersToUpdate,
      roleId,
      permissions,
      roleUsersToRemove,
      setRoleUsersToRemove,
      openUserTab,
      setOpenUserTab,
      setExistNameChecked,
    } = props

    const { data: roleDetails, isLoading: isRoleDetailsLoading, isFetching: isRoleDetailsFetching, error: roleDetailsError } = useGetRoleDetailsQuery(roleId, {
      skip: !roleId,
      refetchOnMountOrArgChange: true,
    });
    const [checkRoleName, checkRoleNameOptions] = useCheckRoleNameExistedMutation()
    useProtected(checkRoleNameOptions.error)
    const [value, setValue] = React.useState(0);


    const handleChange = (event, newValue) => {
      setValue(newValue);
    };

    useEffect(() => {
      if (openUserTab) {
        setValue(2)
        setOpenUserTab(false)
      }
    }, [openUserTab])


    //================ existing name checking part start =================//
    useEffect(() => {
      const fetchData = async () => {
        if (value === 1) {
          const result = await checkedName(rolesPermissionsObject?.name);
          if (result === "yes") {
            toast.warn("Name already exists");
            setExistNameChecked(true)
          } else {
            setExistNameChecked(false)
          }
        }
      };
      fetchData();
    }, [value])

    const checkedName = async (nameData) => {
      let result = "no"
      let checkResult = await checkRoleName({ name: nameData })
      if (checkResult?.data && checkResult?.data?.body?.data && !roleId) {
        result = "yes"
      }
      return result
    }
    //================ existing name checking part end =================//

    useEffect(() => {
      if (!isRoleDetailsLoading && !isRoleDetailsFetching && roleDetails) {

        const {
          name,
          description,
          status,
          permissions,
          users,
          order
        } = roleDetails?.body?.data

        setRolesPermissionsObject({
          name,
          description,
          status,
          permissions,
          users,
          order
        })
        let newRoleUsers = users.map(({ id }) => id)

        setRoleUsers(newRoleUsers)
        setRoleUsersToUpdate(users)
      }
    }, [roleDetails, isRoleDetailsLoading, isRoleDetailsFetching])

    // console.error("errors ==>", errors.name)
    console.log("data ==>", rolesPermissionsObject.permissions, 345)



    return (
      <>

        <div className="StudentDetailsContainer">

          <div className="H_Tab_c">
            <Box sx={{ borderBottom: 1, borderColor: 'divider', }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">

                <Tab label={
                  <Badge
                    variant="dot"
                    color="error"
                    sx={{
                      "&.MuiBadge-root .MuiBadge-badge.MuiBadge-dot":
                        { marginRight: "-8px !important" }
                    }}
                    invisible={rolesPermissionsObject.name <= 1 ? false : true} // here you can conditionally visible or invisible the [ badge ]
                  >
                    Details
                  </Badge>
                } {...a11yProps(0)} />

                <Tab label={
                  <Badge
                    variant="dot"
                    color="error"
                    sx={{
                      "&.MuiBadge-root .MuiBadge-badge.MuiBadge-dot": { marginRight: "-8px !important" }
                    }}
                    invisible={rolesPermissionsObject.permissions.length === 0 ? false : true} // here you can conditionally visible or invisible the [ badge ]
                  >
                    Permissions
                  </Badge>
                } {...a11yProps(1)} />
                <Tab label="Users" {...a11yProps(2)} />
              </Tabs>
            </Box>

            <CustomTabPanel value={value} index={0}>
              <RoleDetails
                rolesPermissionsObject={rolesPermissionsObject}
                setRolesPermissionsObject={setRolesPermissionsObject}
                errors={errors}
                setErrors={setErrors} />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={1}>
              <Permissions
                rolesPermissionsObject={rolesPermissionsObject}
                setRolesPermissionsObject={setRolesPermissionsObject}
                errors={errors}
                setErrors={setErrors}
                permissions={permissions} />
            </CustomTabPanel>

            <CustomTabPanel value={value} index={2}>
              <UsersRole
                rolesPermissionsObject={rolesPermissionsObject}
                setRolesPermissionsObject={setRolesPermissionsObject}
                errors={errors}
                setErrors={setErrors}
                roleUsers={roleUsers}
                setRoleUsers={setRoleUsers}
                setRoleUsersToUpdate={setRoleUsersToUpdate}
                roleUsersToUpdate={roleUsersToUpdate}
                roleUsersToRemove={roleUsersToRemove}
                setRoleUsersToRemove={setRoleUsersToRemove} />
            </CustomTabPanel>

          </div>
        </div>


      </>
    );
  };

export default CreateRole;


function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Box sx={{ maxHeight: '70vh', overflow: 'auto' }}>{children}</Box>
        </Box>
      )}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}