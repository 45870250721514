import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import RedioBox from '../../Form/RedioBox/RedioBox';

import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import card from '../../../assets/imgs/paymentIcon/bank-card-line.svg';
import zelle from '../../../assets/imgs/paymentIcon/Zelle.svg';
import check from '../../../assets/imgs/paymentIcon/Check.svg';
import cash from '../../../assets/imgs/paymentIcon/Cash.svg';
import americaExpress from '../../../assets/imgs/paymentIcon/AmericanExpress.svg';
import master from '../../../assets/imgs/paymentIcon/MasterCard.svg';
import visa from '../../../assets/imgs/paymentIcon/Visa.svg';
import discover from '../../../assets/imgs/paymentIcon/Discover.svg';
import PaymentMethodBox from '../../PaymentMethodBox/PaymentMethodBox';
import './Payment.css';
import CheckPayment from './CheckPayment/CheckPayment';
import CashPayment from './CashPayment/CashPayment';
import { useUpdateOrderDetailsPaymentMutation } from '../../../redux/features/order/orderApi';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import PaymentModal from '../../PaymentModal/PaymentModal';

const Payment = ({ paymentDetails, closeDrawer }) => {
  const [updateOrderDetailsPayment, updateOrderDetailsPaymentOptions] =
    useUpdateOrderDetailsPaymentMutation();
  useProtected(updateOrderDetailsPaymentOptions.error);

  const [innerDetails, setInnerDetails] = useState(null);
  const [successPaymentModalOpen, setSuccessPaymentModalOpen] = useState(false);
  const [errorPaymentModalOpen, setErrorPaymentModalOpen] = useState(false);

  const tabsData = [
    {
      title: <img src={card} alt="zelleIcon" width={30} height={30} />,
      description: 'Card',
    },
    {
      title: <img src={check} alt="zelleIcon" width={30} height={30} />,
      description: 'Check',
    },
    {
      title: <img src={cash} alt="zelleIcon" width={30} height={30} />,
      description: 'Cash',
    },
  ];

  useEffect(() => {
    const details = { ...paymentDetails };
    delete details._id;
    details.method = tabsData?.findIndex(
      (item) => item.description === details.method,
    );

    setInnerDetails(details);
  }, [paymentDetails]);

  // const handleInputChange = (e) => {
  //   let details = innerDetails;
  //   details = { ...details, [e.target.name]: e.target.value };
  //   setInnerDetails(details);
  // };

  const handleAppointmentDateChange = (date) => {
    let details = innerDetails;
    details = { ...details, appointmentDate: date.toISOString() };
    setInnerDetails(details);
  };

  // const handleChangeCheck = (e) => {
  //   let details = innerDetails;
  //   details = { ...details, [e.target.name]: e.target.checked ? true : false };
  //   setInnerDetails(details);
  // };

  const handleUpdateOrderDetailsShippingInfo = async () => {
    const data = {
      method: tabsData[innerDetails?.method].description,
      appointmentDate: innerDetails?.appointmentDate,
    };

    const result = await updateOrderDetailsPayment({
      payment: { ...data },
      id: paymentDetails?._id,
    });

    if (!result?.data?.error) {
      toast.success(result?.data.message);
      setInnerDetails(null);
      // closeDrawer();
      setSuccessPaymentModalOpen(true);
      setErrorPaymentModalOpen(false);
    } else {
      toast.success(result?.data?.message);
      setSuccessPaymentModalOpen(false);
      setErrorPaymentModalOpen(true);
    }
  };

  const onChange = (event, newValue) => {
    let details = innerDetails;
    details = { ...details, method: newValue };
    setInnerDetails(details);
  };

  const panelDate = [
    {
      id: 0,
      panel: '',
    },

    {
      id: 1,
      panel: <CheckPayment />,
    },
    {
      id: 2,
      panel: (
        <CashPayment
          details={innerDetails}
          handleAppointmentDateChange={handleAppointmentDateChange}
        />
      ),
    },
  ];

  return (
    <>
      <PaymentMethodBox
        paymentTabsData={tabsData}
        paymentPanelData={panelDate}
        value={innerDetails?.method}
        onChange={onChange}
      />
      <StyledButton
        disabled={
          updateOrderDetailsPaymentOptions.isLoading ||
          innerDetails?.method === -1
        }
        onClick={handleUpdateOrderDetailsShippingInfo}
        startIcon={<LockOutlinedIcon />}
        variant="contained"
      >
        Continue to payment
      </StyledButton>
      <div className="paymentBox">
        <span
          className="status rounde statussmall"
          style={{
            marginRight: '5px',
            background: '#EDEDEF',
            color: '#676C7B',
            borderRadius: '1000px',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <LockOutlinedIcon />
          Coming soon
        </span>
        <div>
          <img src={visa} alt="visa" />
          <img src={master} alt="visa" />
          <img src={americaExpress} alt="visa" />
          <img src={discover} alt="visa" />
        </div>
      </div>

      <PaymentModal
        paymentStatus={false}
        open={errorPaymentModalOpen}
        handleClose={() => setErrorPaymentModalOpen(false)}
      />
      <PaymentModal
        paymentStatus={true}
        open={successPaymentModalOpen}
        handleClose={() => setSuccessPaymentModalOpen(false)}
      />
    </>
  );
};

export default Payment;

const StyledButton = styled(Button)(({ theme }) => ({
  padding: '14px 46px !important',
  width: '100% !important',
  background: '#FFB606 !important',
  boxShadow: 'none !important',
  color: '#000000 !important',
  fontWeight: '700 !important',
  borderRadius: '10px !important',
  position: 'relative !important',
  '& .MuiButton-startIcon ': {
    position: 'absolute !important',
    left: '16px !important',
  },
  '&:hover': {
    background: '#FFB606 !important',
    boxShadow: 'none !important',
  },
  [theme.breakpoints.down('sm')]: {
    marginTop: '26px !important',
  },
  '&:disabled': {
    background: '#CCCCCC !important', // Change to desired disabled background color
    color: '#888888 !important', // Change to desired disabled text color
    pointerEvents: 'none', // Prevent interactions when disabled
  },
}));
