import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { logout, setFirstTimeLogin } from '../redux/features/authSlice';

const useProtected = (errorResponse) => {

  const firstTime = useSelector(state => state.auth.firstTime);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleErrorResponse = () => {
      if ((errorResponse?.status === 401 || errorResponse?.status === 400) && !firstTime) {
        console.log(`You are logging out. ${errorResponse.message}`)
        dispatch(logout());
      }
      dispatch(setFirstTimeLogin(false))
    };

    if (errorResponse) {
      handleErrorResponse();
    }
  }, [dispatch, errorResponse]);
};

export default useProtected;
