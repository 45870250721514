import React, { useEffect, useState } from "react";
import styles from './IdCard.module.css';
import Logo from './quran-academy-for-young-student-logo.png';
import UserPhoto from './user-photo.jpeg';
import QrCode from "./qr-deenfund.png";




{/* <IdCard
  UserPhoto={UserPhoto}
  FirstName="Mohammad"
  LastName="Alam"
  Designation="Student"
  OrgNameStatus={true}
  OrgName="MIDDLE SCHOOL CLASS OF ‘26"
  IdNumber="10151008"
  QrCode={QrCode}
  StatusColor="#64BC08"

/> */}

const IdCard = (props) => {


  return (
    <div className={styles.IdCardContainer}>
      <div className={styles.IdCardLogo}>
        <img src={Logo} />
      </div>

      <div style={{ background: props.StatusColor, padding: "2px 0", }}></div>
      <div className={styles.IdCardInfoSection}>

        <div className={styles.IdCardPhotoSection}>
          <img src={props.UserPhoto} />
        </div>

        <div className={styles.IdCardDetailsSection}>
          <div className={styles.IdCardDetails}>
            <div className={styles.FirstName}>{props.FirstName}</div>
            <div className={styles.LastName}>{props.LastName}</div>
          </div>
          <div className={styles.Designation} style={{ background: props.StatusColor, }}>{props.Designation}</div>
        </div>

      </div>
      <div style={{ background: props.StatusColor, padding: "2px 0", }}></div>

      <div className={styles.QrCodeSection}>

        <div className={styles.QrCodeBox}>
          <img src={props.QrCode} />
        </div>

        <div className={styles.QrCodeIdDetailsSection}>

          <div className={styles.QrCodeIdSection}>
            <div>
              {props.OrgNameStatus &&
                <div className={styles.QrCodeOrgName}>{props.OrgName}</div>}
              <div className={styles.QrCodeNumber} style={{ color: props.StatusColor, }}><span>ID#</span> {props.IdNumber}</div>

            </div>
          </div>
        </div>

      </div>



    </div>

  );
};


export default IdCard;



