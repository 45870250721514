import React, { useEffect, useState } from "react";
import { Grid } from '@material-ui/core/';
import Buttons from '../../components/Form/Button/Button';
import PhoneInputField from '../../components/Form/PhoneInputField/PhoneInputField'
// import Header from './header/Header';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
import '../../assets/css/form.css';
import axios from 'axios';
import AlrtMessage from '../../components/Form/AlrtMessage/AlertMessage';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { useNavigate } from "react-router-dom";
import { useAddPhoneNumberMutation } from "../../redux/features/users/userApi";
import { toast } from "react-toastify";
import Loader from "../../components/Loader/Loader";
import useProtected from "../../hooks/useProtected";
import SimpleBackdrop from "../../components/Form/Backdrop/SimpleBackdrop";
import LogoHeader from "./LogoHeader/LogoHeader";

// import CustomizedSnackbar from '../../hooks/Snackbar';

function SignInAddPhoneNumber(props) {
    const [addPhoneNumber, addPhoneNumberOptions] = useAddPhoneNumberMutation();
    useProtected(addPhoneNumberOptions.error);
    const navigate = useNavigate();
    const [alertOpen, setAlertOpen] = useState(false);

    const [changePhoneNumber, setChangePhoneNumber] = useState(false);
    const [phone, setPhone] = React.useState({
        country: {},
        number: "",
    });
    const [phoneNumberFormated, setPhoneNumberFormated] = useState("");

    // useEffect(() => {
    //     const params = new URLSearchParams(window.location.search);
    //     const token = params.get("changePhoneNumber");
    //     const oldPhoneNumber = params.get("oldPhoneNumber");
    //     const oldCountry = params.get("oldCountry");
    //     if (token) {
    //         setChangePhoneNumber(true);
    //         setPhone({ country: { dialCode: oldCountry }, number: oldPhoneNumber });
    //     }
    // }, []);


    //handle phone change event
    const handlePhoneChange = (phone, country) => {
        console.log(country)
        console.log({ phone });
        setPhone({ country: country, number: phone });
    };

    //handle submit event
    const handleSubmit = async () => {
        console.log("submit");
        const data = {
            // phone_number: phone.number,
            // country_code: phone.country.dialCode,
            phone: `+${phone.number}`
        };
        const result = await addPhoneNumber(data);
        if (!result?.data?.error) {
            toast.success(result?.data?.message);
            navigate(`/verify-otp?phone=${phone.number}`);
        } else {
            toast.error(result?.data?.message)
        }
    };
    //handle skipBackToSettings
    const skipBackToSettings = () => {
        //redirect to settings page
        navigate('/profile');
    };

    const [showSnackbar, setShowSnackbar] = useState(false);
    const [snackbarSeverity, setSnackbarSeverity] = useState("success"); // severity can be: success, error, warning, info, success

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setShowSnackbar(false);
    };

    return (

        <>
            {/* <Header /> */}
            <SimpleBackdrop open={addPhoneNumberOptions.isLoading} />
            <div className="SignContainer">
                <LogoHeader />
                <div className="SignContainerInner">
                    {/* <CustomizedSnackbar open={alertOpen} severity={alertSeverity} message={alertMessage} handleClose={handleSnackbarClose} /> */}
                    <div className="SignForm">
                        <div className="FormHeader">
                            <span></span>
                            <h1 >{changePhoneNumber ? "Change phone number" : "Add a phone number"}  </h1>
                            <span></span>
                        </div>

                        <div className="formContainer">
                            <div className="SignFormGrid">
                                <Grid container spacing={3}>
                                    <Grid item xs={12} md={12}>
                                        <p>Be sure to enter a phone number you can always access. Confirming your mobile number
                                            helps you reset your password easily and secure your account.</p>
                                    </Grid>

                                    <Grid item xs={12} md={12}>

                                        <PhoneInputField
                                            phoneInputVariant={"materialui"} // default, materialui, underlineInput, underlineWithFlag
                                            country={'us'}
                                            value={phone.number}
                                            countryCodeEditable={false}
                                            onChange={(phone, country) => handlePhoneChange(phone, country)}
                                            onBlur={(e) => setPhoneNumberFormated(e.target.value)}
                                            onKeyDown={(e) => setPhoneNumberFormated(e.target.value)}
                                            variant="outlined"
                                            // onlyCountries={["us", "ca", "gb", "my"]}
                                            containerStyle={{}}
                                            buttonStyle={{}}
                                            inputStyle={{
                                                marginLeft: "0px",
                                                height: "40px",
                                                width: "100%",
                                                fontFamily: "Roboto-Regular",
                                                fontSize: "16px",
                                            }}
                                            containerClass={{}}
                                            errorText={"Phone number is required"}
                                            errorStyles={{ marginBottom: "5px" }}
                                            autoFormat={true}
                                            disableDropdown={false}
                                            disabled={false}
                                            disableCountryCode={false}
                                            enableSearch={false}
                                            disableSearchIcon={false}
                                            placeholder={""}
                                            inputProps={{}}
                                            specialLabel={'Primary Phone'}
                                        // preferredCountries={[]}
                                        // excludeCountries={[]}
                                        //error="error"
                                        />

                                    </Grid>

                                    <Grid item xs={12} md={12}>
                                        <Buttons
                                            label={
                                                addPhoneNumberOptions?.isLoading ? (
                                                    "Loading"
                                                ) : "Continue"
                                            }
                                            buttonColor="#004FE0"
                                            border="#ccc 1px solid"
                                            color="#fff"
                                            width="100%"
                                            height="48px"
                                            fontSize="14px"
                                            // link="/verify-otp"
                                            icon={
                                                addPhoneNumberOptions?.isLoading ? (
                                                    <Loader height="20px" width="20px" />
                                                ) : null
                                            }

                                            disabled={(phone.number.length < 11 || addPhoneNumberOptions?.isLoading) ? "disabled" : ""}
                                            onClick={handleSubmit}
                                        />
                                        <div className="BottomButton">
                                            <Buttons
                                                label={changePhoneNumber ? "Cancel" : "Cancel"}
                                                color="#004FE0"
                                                width="100%"
                                                height="48px"
                                                fontSize="14px"
                                                link=""
                                                onClick={skipBackToSettings}
                                            />
                                        </div>
                                    </Grid>

                                </Grid>
                            </div>
                        </div>
                    </div>



                </div>
                <div className="SignFormFooter">
                    <SecurityOutlinedIcon />
                    <span>SECURE SSL ENCRYPTION</span>
                </div>




            </div>
        </>
    );
}


export default SignInAddPhoneNumber;

