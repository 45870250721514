import React, { useEffect, useState } from 'react';
import '../../../assets/css/profile.css';
import Buttons from '../../../components/Form/Button/Button';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';


const ProfileSuccess = (props) => {

  return (
    <>
      <div className="ProfileFormContainer">

        <CheckCircleRoundedIcon style={{ marginTop: "20px", marginBottom: "20px", color: "#66BD50", fontSize: "40px" }} />

        <div className="ProfileFormHeader">
          <h3>Profile saved successfully.</h3>
          <p>We are working hard to make our parent portal live inshaAllah. Where you will be able manage all your students at one place.</p>
        </div>

        <div style={{ marginTop: "40px" }}>
          <Buttons
            label="Back to homepage"
            buttonColor="#FFB606"
            border="#FFB606 1px solid"
            color="#000"
            width=""
            height="48px"
            fontSize="14px"
          // onClick={ }
          />
        </div>
      </div>
    </>
  );
};

export default ProfileSuccess;
