import { api } from '../../api/apiSlice';

const userApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getInviteUserList: builder.query({
      query: () => ({
        url: '/invite-user/find-all',
      }),
      providesTags: ['InviteUser'],
    }),
    getInviteUserDetails: builder.query({
      query: (id) => ({
        url: `/invite-user/getSingle/${id}`,
      }),
      providesTags: ['InviteUserDetails'],
    }),
    deleteInviteUser: builder.mutation({
      query: (id) => ({
        url: `/invite-user/delete/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['InviteUser'],
    }),
    updateInviteUser: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/invite-user/update/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['InviteUser'],
    }),
    getInviteUsersSearchResult: builder.mutation({
      query: (data) => ({
        url: '/invite-user/search',
        method: 'POST',
        body: data,
      }),
    }),
    checkEmail: builder.mutation({
      query: (data) => ({
        url: '/invite-user/check-email',
        method: 'POST',
        body: data,
      }),
    }),
    createInviteUser: builder.mutation({
      query: (data) => ({
        url: '/invite-user/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['InviteUser', 'UserDetails'],
    }),
    resendInvitation: builder.mutation({
      query: (data) => ({
        url: '/invite-user/resend',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['InviteUserDetails'],
    }),
    getUserList: builder.query({
      query: () => ({
        url: '/users/private/findAll',
      }),
    }),
    getGuardianList: builder.query({
      query: () => ({
        url: '/users/private/getGuardians',
      }),
      providesTags: ['Guardians']
    }),
    getStudents: builder.query({
      query: () => ({
        url: '/users/private/getStudents',
      }),
    }),
    getSearchResult: builder.mutation({
      query: (data) => ({
        url: '/users/private/search',
        method: 'POST',
        body: data,
      }),
    }),
    getSearchAttendanceUsers: builder.mutation({
      query: (data) => ({
        url: '/attendance/searchUsersWithoutAttendance',
        method: 'POST',
        body: data,
      }),
    }),
    revokeAccess: builder.mutation({
      query: (id) => ({
        url: `/users/private/revokeAccess/${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['UserDetails']
    }),
    returnAccess: builder.mutation({
      query: (id) => ({
        url: `/users/private/returnAccess/${id}`,
        method: 'POST'
      }),
      invalidatesTags: ['UserDetails']
    }),
    getUserDetails: builder.query({
      query: (id) => ({
        url: `/users/private/getUser/${id}`,
      }),
      providesTags: ['UserDetails']
    }),
    getUserDetailsByUniqueId: builder.query({
      query: (id) => ({
        url: `/users/private/getUserByUniqueId/${id}`,
      }),
      // providesTags: ['UserDetails']
    }),
    getInvitedUserDetails: builder.query({
      query: (id) => ({
        url: `/public-api/invite-user/${id}`,
      }),
    }),
    getUserPhotoHistory: builder.query({
      query: (id) => ({
        url: `files/getUserIDPhotoHistory/${id}`
      }),
    }),
    deleteUserIDPhoto: builder.mutation({
      query: (id) => ({
        url: `users/private/deleteIdPicture/${id}`,
        method: 'PATCH'
      })
    }),
    getUserRoles: builder.query({
      query: () => ({
        url: '/roles/all',
      }),
    }),
    getUsersByRole: builder.query({
      query: (roleId) => ({
        url: `/users/private/getUsersByRole/${roleId}`,
      }),
    }),
    getUserByAttendance: builder.query({
      query: (roleId) => ({
        url: `/attendance/getUsersWithoutAttendance/${roleId}`,
      }),
    }),
    getSearchByRoleAndTermResult: builder.mutation({
      query: (data) => ({
        url: '/users/private/getUsersByRoleAndTerm',
        method: 'POST',
        body: data,
      }),
    }),
    getUsersByUserTypes: builder.mutation({
      query: ({ data, userTypes }) => ({
        url: `/users/private/getUsersByUserTypes/${userTypes}`,
        method: 'POST',
        body: data,
      }),
    }),
    updateSettings: builder.mutation({
      query: (data) => ({
        url: `/users/private/updateProfileSettings`,
        method: 'PATCH',
        body: data,
      }),
    }),
    addPhoneNumber: builder.mutation({
      query: (data) => ({
        url: `/users/private/addPhoneNumber`,
        method: 'PATCH',
        body: data,
      }),
    }),
    updateUser: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/users/private/updateProfile/${id}`,
        method: 'PATCH',
        body: data,
      }),
    }),
    verifyPhoneNumberOtp: builder.mutation({
      query: (data) => ({
        url: `/users/private/verifyPhoneNumber`,
        method: 'PATCH',
        body: data,
      }),
    }),
    changePassword: builder.mutation({
      query: (id) => ({
        url: `/users/private/changePassword/${id}`,
        method: 'PATCH'
      }),
    }),
    forgotPassword: builder.mutation({
      query: (data) => ({
        url: `/users/public/forgot-password`,
        method: 'POST',
        body: data
      }),
    }),
    getStudentsOfGuardian: builder.query({
      query: (id) => ({
        url: `/users/private/getGurdianUsers/${id}`,
      }),
      providesTags: ['GuardianStudents']
    }),
    addEmergencyContact: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/users/private/addEmergencyContact/${id}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['UserDetails']
    }),
    updateEmergencyContact: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/users/private/updateEmergencyContact/${id}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['UserDetails']
    }),
    deleteEmergencyContact: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/users/private/deleteEmergencyContact/${id}`,
        method: 'POST',
        body: data
      }),
      invalidatesTags: ['UserDetails'],
    }),
    importFileGetData: builder.mutation({
      query: (data) => ({
        url: `/import-users/getData`,
        method: 'POST',
        body: data
      })
    }),

    importFileGetColumns: builder.mutation({
      query: (data) => ({
        url: `/import-users/getColumns`,
        method: 'POST',
        body: data
      })
    }),
    schoolYearfindByDate: builder.mutation({
      query: (data) => ({
        url: `/school-year/findByDate`,
        method: 'POST',
        body: data
      })
    }),
    changeUserCurrentPassword: builder.mutation({
      query: (data) => ({
        url: `/users/private/changePassword`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['UserDetails'],
    }),

    getUserIDPhotoHistory: builder.query({
      query: (userId) => ({
        url: `/files/getUserIDPhotoHistory/${userId}`,
      }),
      providesTags: ['UserDetails']
    }),

  }),
});

export const {
  useGetInviteUserListQuery,
  useGetInviteUserDetailsQuery,
  useDeleteInviteUserMutation,
  useUpdateInviteUserMutation,
  useGetInviteUsersSearchResultMutation,
  useCreateInviteUserMutation,
  useResendInvitationMutation,
  useGetUserListQuery,
  useGetSearchResultMutation,
  useGetUserDetailsQuery,
  useLazyGetUserDetailsQuery,
  useGetInvitedUserDetailsQuery,
  useGetUserPhotoHistoryQuery,
  useDeleteUserIDPhotoMutation,
  useGetUserRolesQuery,
  useGetUsersByRoleQuery,
  useGetUserByAttendanceQuery,
  useGetSearchByRoleAndTermResultMutation,
  useUpdateSettingsMutation,
  useAddPhoneNumberMutation,
  useVerifyPhoneNumberOtpMutation,
  useGetStudentsQuery,
  useUpdateUserMutation,
  useGetGuardianListQuery,
  useChangePasswordMutation,
  useForgotPasswordMutation,
  useGetSearchAttendanceUsersMutation,
  useCheckEmailMutation,
  useGetStudentsOfGuardianQuery,
  useGetUserDetailsByUniqueIdQuery,
  useRevokeAccessMutation,
  useReturnAccessMutation,
  useAddEmergencyContactMutation,
  useUpdateEmergencyContactMutation,
  useDeleteEmergencyContactMutation,
  useImportFileGetDataMutation,
  useImportFileGetColumnsMutation,
  useGetUsersByUserTypesMutation,
  useSchoolYearfindByDateMutation,
  useChangeUserCurrentPasswordMutation,
  useGetUserIDPhotoHistoryQuery

} = userApi;
