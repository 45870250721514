import React, { useEffect, useState } from "react";
import TextField from '../../../components/Form/TextField/TextField';
import Grid from '@mui/material/Grid';




const RoleDetails = (props) => {
  const {
    rolesPermissionsObject,
    setRolesPermissionsObject,
    errors,
    setErrors
  } = props

  const onChangeHandler = (val, e, fieldName) => {
    let newObject = { ...rolesPermissionsObject, [fieldName]: e.target.value }
    setRolesPermissionsObject(newObject)
  }

  return (


    <div className="RoleDetails">
      <div className="RoleDetailsInner">
        <Grid container spacing={3}>

          <Grid item md={12} xs={12}>
            <TextField
              label="Role Name"
              CharAlignMid="CharAlignMid"
              Uppercase="Uppercase"
              name={'name'}
              required={true}
              value={rolesPermissionsObject.name}
              handleChange={(val, e) => onChangeHandler(val, e, 'name')}
              error={rolesPermissionsObject.name.length >= 1 ? "" : errors?.name}
              helperText={rolesPermissionsObject.name.length >= 1 ? "" : (errors?.name ? errors?.name : '')}
              count={50}
            />




          </Grid>

        </Grid>
      </div>
    </div>
  );
};

export default RoleDetails;


