import React, { useEffect, useState } from 'react';
import TextField from '../../Form/TextField/TextField';
import Buttons from '../../Form/Button/Button';
import { Grid } from '@mui/material';
import { useUpdateOrderDetailsMutation } from '../../../redux/features/order/orderApi';
import useProtected from '../../../hooks/useProtected';
import { validateFormData } from '../../../utils/form';
import { async } from 'q';
import { toast } from 'react-toastify';
import Loader from '../../Loader/Loader';
import OrderSummary from './OrderSummary';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import orderData from '../../../assets/data/orderData';

const OrderDetails = ({ orderDetails }) => {
  const [updateOrderDetails, updateOrderDetailsOptions] =
    useUpdateOrderDetailsMutation();
  useProtected(updateOrderDetails.error);

  const [orderName, setOrderName] = useState('');
  const [subtotal, setSubtotal] = useState(0);
  const [totalTax, setTotalTax] = useState(0);
  const [total, setTotal] = useState(0);

  // const [errors, setErrors] = useState({
  //   orderName: '',
  // });

  const handleOnChange = (event) => {
    // setErrors((prev) => {
    //   prev['orderName'] = '';
    //   return prev;
    // });
    const value = event.target.value;
    setOrderName(value);
  };

  const handleUpdateOrderDetails = async () => {
    const data = {
      orderName,
    };

    // const isValid = validateFormData(data, setErrors, {
    //   orderName: 'Enter Order Name',
    // });

    // if (!isValid) {
    //   return;
    // }

    const result = await updateOrderDetails({ ...data, id: orderDetails?._id });

    console.log({ result });

    if (!result?.data?.error) {
      setOrderName('');
      toast.success('Order details added successfully');
    } else {
      toast.success(result?.data?.message);
    }
  };

  useEffect(() => {
    setOrderName(orderDetails?.orderName);
    const tempSubtotal = orderDetails?.quantity * orderData.perHeadCost;
    const tempTotalTax = tempSubtotal * (orderData.tax / 100);
    const tempTotal = tempSubtotal + tempTotalTax + orderData.shippingCost;
    setSubtotal(tempSubtotal);
    setTotalTax(tempTotalTax);
    setTotal(tempTotal);
  }, [orderDetails]);

  return (
    <Grid container spacing={3.5}>
      <Grid item xs={12}>
        <TextField
          label="Order name"
          validation={false}
          CharAlignMid="CharAlignMid"
          Uppercase="Uppercase"
          helperText="This will be internal order name"
          count={40}
          value={orderName}
          handleChange={(val, e) => handleOnChange(e)}
        // error={errors?.orderName}
        // helperText={errors?.orderName}
        />
      </Grid>
      <Grid item xs={12}>
        <TableContainer component={Paper} sx={{ boxShadow: '0 0 1px #1B1B1F', borderRadius: '8px' }}>
          <Table sx={{ minWidth: 300, }} aria-label="spanning table">
            <TableHead>
              {/* <TableRow>
                        <TableCell align="center" colSpan={3}>
                            Details
                        </TableCell>
                        <TableCell align="right">Price</TableCell>
                    </TableRow> */}
              <TableRow sx={{ background: '#f2f0f4' }}>
                <TableCell>Items</TableCell>
                <TableCell align="right">QTY</TableCell>
                <TableCell align="right">Price</TableCell>
                {/* <TableCell align="right">Sum</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>

              <TableRow>
                <TableCell>ID Card</TableCell>
                <TableCell align="right">{orderDetails?.quantity}</TableCell>
                <TableCell align="right">${subtotal}</TableCell>
              </TableRow>

              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell colSpan={1}>Subtotal</TableCell>
                <TableCell align="right">${subtotal}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell>Shipping</TableCell>
                <TableCell align="right" sx={{ display: 'none' }}></TableCell>
                <TableCell align="right">${orderData.shippingCost}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={1}>Tax {orderData.tax}%</TableCell>
                <TableCell align="right">${totalTax.toFixed(2)}</TableCell>
              </TableRow>
              <TableRow>
                <TableCell rowSpan={3} />
                <TableCell colSpan={1}><b>Total</b></TableCell>
                <TableCell align="right"><b>${total.toFixed(2)}</b></TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
      <Grid item xs={12}>
        <Buttons
          label={updateOrderDetailsOptions.isLoading ? 'Loading' : 'Continue'}
          buttonColor="#FFB606"
          color="#000000"
          width="100%"
          height="48px"
          fontSize="14px"
          // link={item.link}
          disabled={!orderName || updateOrderDetailsOptions.isLoading}
          onClick={handleUpdateOrderDetails}
          icon={
            updateOrderDetailsOptions.isLoading ? (
              <Loader height="20px" width="20px" />
            ) : null
          }
        />
      </Grid>
      <Grid item xs={12}>
        <OrderSummary />
      </Grid>
    </Grid>
  );
};

export default OrderDetails;
