import { api } from '../../api/apiSlice';

const orderApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getOrderIdList: builder.query({
      query: () => ({
        url: '/order-id/find-all',
      }),
      providesTags: ['OrderId'],
    }),
    getOrderIdDetails: builder.query({
      query: (id) => ({
        url: `/order-id/getSingle/${id}`,
      }),
      providesTags: ['OrderId'],
    }),
    getOrderIdSearchResult: builder.mutation({
      query: (data) => ({
        url: '/order-id/search',
        method: 'POST',
        body: data,
      }),
    }),
    createOrderId: builder.mutation({
      query: (data) => ({
        url: '/order-id/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['OrderId'],
    }),
    updateOrderDetails: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/order-id/update-details/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['OrderId'],
    }),
    updateOrderDetailsUsers: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/order-id/update-users/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['OrderId'],
    }),
    updateOrderDetailsShipTo: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/order-id/update-ship-to/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['OrderId'],
    }),
    updateOrderDetailsPayment: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/order-id/update-payment/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['OrderId'],
    }),
  }),
});

export const {
  useGetOrderIdListQuery,
  useGetOrderIdDetailsQuery,
  useGetOrderIdSearchResultMutation,
  useCreateOrderIdMutation,
  useUpdateOrderDetailsMutation,
  useUpdateOrderDetailsUsersMutation,
  useUpdateOrderDetailsShipToMutation,
  useUpdateOrderDetailsPaymentMutation,
} = orderApi;
