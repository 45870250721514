import { Button } from '@material-ui/core';
import React from 'react';
import Loader from '../Loader/Loader';
import './SideDrawer.css';

const DrawerFooterActions = ({
  isLoading,
  handleClose,
  handleAction,
  closeBtnLabel,
  mobileCloseBtnLabel,
  actionButtonLabel,
}) => {
  return (
    <div className='DrawerFooterContainer'>
      <div className="DrawerFooter InnerDrawerFooter">
        <div className="DrawerFooterInner DrawerFooterInner">
          <div className="DrawerFooterButton">
            <Button className="DrawerCloseButton" onClick={handleClose}>
              {closeBtnLabel}
            </Button>
            <Button
              className="DrawerActionButton"
              onClick={handleAction}
              disabled={isLoading}
            >
              {isLoading && (
                <Loader height="20px" width="20px" margin="0px 10px 0px 0px" />
              )}{' '}
              {isLoading ? 'Loading' : actionButtonLabel}
            </Button>
            <Button className="DrawerCloseButtonMobile" onClick={handleClose}>
              {mobileCloseBtnLabel}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DrawerFooterActions;
