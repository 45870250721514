import React from 'react';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import './Button.css';

function Buttons(props) {
  return (
    <div className="ButtonContainer">



      <Button
        href={props.link}
        target={props.target}
        disabled={props.disabled}
        className={props.className}
        style={{
          background: props.buttonColor,
          color: props.color,
          height: props.height,
          fontSize: props.fontSize,
          border: props.border,
          width: props.width,
          borderRadius: props.borderRadius,
          padding: props.padding,
          textDecoration: props.textDecoration,
          textTransform: props.textTransform,
          float: props.align,
        }}
        onClick={() => props.onClick()}
        aria-controls={props.controls}
        aria-haspopup={props.haspopup}
        aria-expanded={props.expanded}
      >

        {props.ButtonLoader &&
          <div className='ButtonLoader' onClick={props.LoaderOnclick}>
            <CircularProgress
              style={{ color: props.LoderColor, width: "16px", height: "16px", }}
            />
          </div>
        }

        {props.icon}
        {props.label}
        <div className="ButtonIconAfter">{props.iconAfter}</div>
        {
          props.filterCountStatus ?
            <div className='filterCount'>{props.filterCountNumber}</div> : ''
        }



      </Button>


      {/* import AddRoundedIcon from '@mui/icons-material/AddRounded'; */}
      {/* <Buttons
        label="Join for Free"
        icon={}
        buttonColor="#004FE0"
        border="#E4E8EC 1px solid"
        color="#fff"
        width=""
        height="60px"
        fontSize="20px"
        link=""
        onClick={}
      /> */}
    </div>
  );
}

export default Buttons;
