function formatPhoneNumber(s) {
    // Remove all non-numeric characters
    const cleaned = ('' + s).replace(/\D/g, '');

    const length = s.length;

    console.log(length)

    if (length == 11) {

        // Check if the cleaned phone number has 11 digits (with country code)
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
    } else if (length == 13) {

        // Check if the cleaned phone number has 13 digits (with country code)
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
    } else if (length == 12) {

        // Check if the cleaned phone number has 12 digits (with country code)
        const match = cleaned.match(/^(\d{2})(\d{3})(\d{3})(\d{4})$/);

        if (match) {
            return `${match[1]} (${match[2]}) ${match[3]}-${match[4]}`;
        }
    }

    return s;
}

function PhoneComponent(phoneNumber) {

    let phone = formatPhoneNumber(phoneNumber.trim());

    if (!phone.startsWith('+')) {
        phone = '+' + phone;
    }


    return (phone);
}

export default PhoneComponent;