import react, { Fragment } from "react";

import EmptyIcon from "../../assets/imgs/Empty.svg";
import WarningIcon from "../../assets/imgs/Warning.svg";
import "./EmptyCard.css";
import Buttons from "../../components/Form/Button/Button";
import AddRoundedIcon from '@mui/icons-material/AddRounded';

function replaceNewlinesWithBr(str) {
  return str.split("/n")?.map((line, index) => (
    <Fragment key={index}>
      <span>{line}</span>
      <br />
    </Fragment>
  ));
}

function EmptyCard(props) {
  return (
    <div className="EmptyCardContainer">
      {props.TableTitleStatus && (
        <div class="TableHeader">
          <h1 class="TableTitle">{props.TableTitle}</h1>
          <div></div>
        </div>
      )}
      <div className="EmptyCard">
        <img
          alt="emptyCard"
          className="EmptyCardIcon"
          src={props.mainIcon == "warning" ? WarningIcon : EmptyIcon}
        />
        <h1>{props.title}</h1>
        <p>{props.description}</p>
        {props.showButton && (
          <div className="EmptyCardButton">
            <Buttons
              label={props.btnLabel}
              buttonColor="#0450E1"
              color="#FFFFFF"
              width="auto"
              height="32px"
              fontSize="14px"
              link={props.btnLink}
              // icon={<AddRoundedIcon />}
              onClick={props.onClick}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default EmptyCard;
