import React, { useState, useRef, useEffect } from 'react';
import SideDrawer from '../SideDrawer/SideDrawer';
import CustomizedSnackbar from '../../hooks/Snackbar';
import UploadFromDevice from './UploadFromDevice';
import UploadFromYoutube from './UploadFromYoutube';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import YouTubeIcon from '@material-ui/icons/YouTube';
import SimpleBackdrop from '../Form/Backdrop/SimpleBackdrop';
import axios from 'axios';
import { onFileUpload } from './Functions'

import {
  useAddMediaLibMutation,
  useCheckMediaLibNameMutation,
  useDeleteMediaLibMutation,
  useGetMediaLibDetailsQuery,
  useGetMediaLibListQuery,
  useGetMediaLibrarySearchResultMutation,
  useUpdateMediaLibMutation,
} from '../../redux/features/media-lib/mediaLibApi';

import useProtected from "../../hooks/useProtected";
import { validateFormData } from '../../utils/form';
import { toast } from "react-toastify";


import './MediaLibrary.css';

function Externaluse(props) {
  const [createMedia, createMediaOptions] = useAddMediaLibMutation();
  const [checkMediaLibName, checkMediaLibNameOptions] = useCheckMediaLibNameMutation()

  useProtected(createMediaOptions.error);
  useProtected(checkMediaLibNameOptions.error);

  const [errors, setErrors] = useState({
    videoName: '',
    videoUrl: '',
  });


  const childRef = useRef(null);


  //base url for api taken from .env file 
  const baseUrl = process.env.REACT_APP_API_URL;

  const userToken = localStorage.getItem('token');
  axios.defaults.headers.common['Authorization'] = `Bearer ${userToken}`;

  const [orgId, setOrgId] = useState(localStorage.getItem('orgId'));


  const [uploadButtonText, setUploadButtonText] = useState("Upload");
  const [openNewMedia, setOpenNewMedia] = useState(props.open);
  const [videoName, setVideoName] = useState("");
  const [videoUrl, setVideoUrl] = useState("");
  const [imageName, setImageName] = useState("");
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarType, setSnackbarType] = useState("success");
  const [checkboxClicked, setCheckboxClicked] = useState(false);
  const [videoUpload, setVideoUpload] = useState(false);
  const [addNewItem, setAddNewItem] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const [openBackdrop, setOpenBackdrop] = useState(false);

  const [imageNameError, setImageNameError] = useState(false);
  const [isfileSelected, setFileSelected] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);

  const [imaneNameErrorMessage, setImageNameErrorMessage] = useState("");

  const fileSelected = (status) => {

    setFileSelected(status);
  }

  const tabData = [
    {
      lebel: "Upload from device",
      icon: <InsertDriveFileIcon />,
    },

    {
      lebel: "YouTube video",
      icon: <YouTubeIcon />,
    },
  ]

  const handleTabChange = (event) => {
    if (event == 1) {
      setUploadButtonText("Import from YouTube");
      setVideoUpload(true);
    } else {
      setUploadButtonText("Upload Image");
      setVideoUpload(false);
    }
  };
  const handleCheckboxClicked = (event) => {
    console.log(event.target.checked);
    setAddNewItem(event.target.checked);
  };
  const handleSnackbarOpen = (message, type) => {
    setShowSnackbar(true);
    setSnackbarMessage(message);
    setSnackbarSeverity(type);
  };
  const handleSnackbarClose = () => {
    setShowSnackbar(false);
  };
  const handleDrawerOpenNewMedia = () => {
    setOpenNewMedia(true);
  };
  const handleDrawerCloseNewMedia = () => {
    setErrors({
      name: '',
      url: '',
      videoName: '',
      videoUrl: '',
    });
    setOpenNewMedia(false);
    setImageName("")
    setImageNameErrorMessage('')
    props.handleCloseButton();
    setFileUploadError(false)
  }
  // const clickSaveButton = async () => {
  //   // setOpenBackdrop(true);
  //   setErrors({
  //     name: '',
  //     url: '',
  //   });

  //   if (videoUpload) {
  //     const data = {
  //       name: videoName,
  //       url: videoUrl,
  //       mimeType: 'video/mp4',
  //     };
  //     const isValid = validateFormData(data, setErrors, {
  //       name: 'Video name is required',
  //       url: 'Video url is required',
  //     });

  //     if (!isValid) {
  //       return;
  //     }

  //     // save data
  //     //call to api via axios
  //     const result = await createMedia(data);

  //     // setOpenBackdrop(false)
  //     //if response status is 200
  //     if (!result?.data?.error) {
  //       toast.success(result.data.message);
  //       setVideoName('');
  //       setVideoUrl('');
  //     } else {
  //       toast.error(result.data.message);
  //     }
  //   } else {

  //     if (imageName === '') {
  //       setErrors((prev) => {
  //         const temp = prev;
  //         temp['name'] = 'Please enter media name';
  //         return temp;
  //       });
  //       return false;
  //     } else {
  //       setImageNameError(false);
  //       setImageNameErrorMessage('');
  //     }
  //     if (isfileSelected === false) {
  //       setFileUploadError(true);
  //       return false;
  //     }
  //     if (childRef.current) {
  //       childRef.current.uploadFile();
  //     }
  //   }
  //   // setOpenBackdrop(false);
  // };

  const validateYouTubeUrl = (url) => {
    const youtubePattern = /^(https?:\/\/)?(www\.)?(youtube\.com\/(embed\/|v\/|watch\?v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;

    return youtubePattern.test(url);
  }

  // const validateYouTubeUrl = (url) => {
  //   const youtubeUrlPattern = /^(https?:\/\/)?(www\.)?youtu\.be\/[A-Za-z0-9_-]+(\?si=[A-Za-z0-9_-]+)?$/;
  //   return youtubeUrlPattern.test(url);
  // };
  const clickSaveButton = async () => {
    // setOpenBackdrop(true);
    setErrors({
      name: '',
      videoNameError: '',
      url: '',
    });



    if (videoUpload) {
      const data = {
        name: videoName,
        url: videoUrl,
        mimeType: 'video/mp4',
      };
      const isValid = validateFormData(data, setErrors, {
        url: 'Video url is required',
      });

      if (!isValid) {
        setErrors((prev) => ({
          ...prev,
          videoNameError: 'Video name is required',
        }));
        return;
      }
      const isYouTubeUrlValid = validateYouTubeUrl(videoUrl);

      if (!isYouTubeUrlValid) {
        setErrors((prev) => ({
          ...prev,
          url: 'Please enter a valid YouTube URL.',
        }));
        return;
      }

      if (!isValid) {
        return;
      }

      // save data
      //call to api via axios
      const result = await createMedia(data);
      // setOpenBackdrop(false)
      //if response status is 200

      if (!result?.data?.error) {
        handleDrawerCloseNewMedia()
        toast.success(result.data.message);
        setVideoName('');
        setVideoUrl('');
        setImageName("")
        setOpenNewMedia(false);

      } else {
        toast.error(result.data.message);
      }
    } else {
      let error = 0
      if (imageName === '') {
        setErrors((prev) => {
          const temp = prev;
          temp['name'] = 'Please enter media name';
          return temp;
        });
        error++
      } else {
        setImageNameError(false);
        setImageNameErrorMessage('');
        // toast.success('Media file uploaded successfully');
      }

      if (isfileSelected === false) {
        setFileUploadError(true);
        error++
      } else {
        setFileUploadError(false);
      }

      if (error > 0) return false;

      if (childRef.current) {
        childRef.current.uploadFile();
      }
    }

    // setOpenBackdrop(false);
  };


  const handlePostUploadAction = () => {
    handleDrawerCloseNewMedia();

    //open the drawer
    props.handleOpenNewMedia(true);

    //set imageName empty 
    setImageName("");
  }

  //loadingIconStatusChange
  const loadingIconStatusChange = (status) => {
    setOpenBackdrop(status);
  }

  //function to process fileupload 
  const processFileUpload = async (filePath, fileName, fileSize, fileType, width, height) => {
    setOpenBackdrop(true);
    const response = await onFileUpload(imageName, filePath, fileName, fileSize, fileType, width, height);

    if (response) {
      //show snackbar success message 
      let message = "File uploaded successfully.";
      let type = "success";

      // setShowSnackbar(true);
      // setSnackbarMessage(message);
      // setSnackbarSeverity(type);

      toast.success(message);


      //set total items to empty
      //   setTotalItems([]);
      //set opennewmedia to false

      setImageName("");

      if (addNewItem) {

        if (childRef.current) {
          childRef.current.clickButton();
        }
      } else {

        // setOpen(false);
        setOpenNewMedia(false);
        handleDrawerCloseNewMedia();

        //open the drawer
        props.handleOpenNewMedia(true)
      }

    } else {
      // handleSnackbarOpen("Something went wrong.", "error");
      toast.error("Something went wrong.");
    }
    setOpenBackdrop(false);
  }

  const panelTab = [
    {
      id: 0,
      panleBody: <UploadFromDevice
        handleTabChange={handleTabChange}
        handleSnackbarOpen={handleSnackbarOpen}
        setVideoName={setVideoName}
        setVideoUrl={setVideoUrl}
        setImageName={setImageName}
        imageName={imageName}
        postUploadAction={handlePostUploadAction}
        loadingIconStatusChange={loadingIconStatusChange}
        fileSelected={fileSelected}
        showFileUploadError={fileUploadError}
        imageErrorMessage={imaneNameErrorMessage}
        showImageNameError={imageNameError}
        onFileUpload={processFileUpload}
        ref={childRef}
        errors={errors}
        isLoading={createMediaOptions?.isLoading}
      />,

      SaveButtonLabel: uploadButtonText,
      clickSaveButton: clickSaveButton,
      CancelButtonLabel: "Cancel",

      Checkbox: false,
      CheckboxLabel: "Add another after saving",
      //onClickCheckbox:onClickCheckbox,

      DeleteButton: false,
      DeleteLebel: "Delete Permanently",
      onClickCheckbox: handleCheckboxClicked,
      handleTabChange: handleTabChange,
      //onClickDelete:onClickDelete,
    },

    {
      id: 1,
      panleBody: <UploadFromYoutube
        setVideoName={setVideoName} setVideoUrl={setVideoUrl}
        handleSnackbarOpen={handleSnackbarOpen}
        onCheckboxClicked={handleCheckboxClicked}
        videoName={videoName} videoUrl={videoUrl}
        errors={errors}
        isLoading={createMediaOptions?.isLoading}
      />,
      SaveButtonLabel: "Import From YouTube",
      clickSaveButton: clickSaveButton,
      cancelButtonLabel: "Cancel",
      videoUrl: videoUrl,
      videoName: videoName,
      CancelButtonLabel: "Cancel",
      handleTabChange: handleTabChange,
      Checkbox: false,
      CheckboxLabel: "Add another after saving",
      onClickCheckbox: handleCheckboxClicked,
    },

  ];
  //closing the loading icon
  const handleCloseBackdrop = () => {
    setOpenBackdrop(false);
  };

  return (
    <>
      <CustomizedSnackbar open={showSnackbar} severity={snackbarSeverity} message={snackbarMessage} handleClose={handleSnackbarClose} />
      <SimpleBackdrop open={openBackdrop} handleClose={handleCloseBackdrop} />
      <SideDrawer
        title="Upload New Media"
        ButtonLabel={uploadButtonText}
        clickSaveButton={clickSaveButton}
        cancelButtonLabel="Cancel"
        sideOpen="right"
        open={props.open}
        handleDrawerClose={handleDrawerCloseNewMedia}
        // CheckboxLabel="Add another after saving"
        Checkbox={true}
        onCheckboxClicked={handleCheckboxClicked}
        FooterWithoutTab={false}
        handleTabChange={handleTabChange}
        //=== Tabs Codes ===//
        TabsStatus={true}
        TabsPannelStatus={true}
        panelTab={panelTab}
        tabData={tabData}
      />
    </>
  )
}


export default Externaluse;