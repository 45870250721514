import React, { useState, useRef } from 'react';
import ImageCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';
import Slider from 'react-slider';

const ImageCropWithZoom = () => {
  const [crop, setCrop] = useState({ unit: '%', width: 50, aspect: 16 / 9 });
  const [image, setImage] = useState(null);
  const imgRef = useRef(null);

  const handleZoomChange = (value) => {
    // Modify the crop object to change the zoom level
    setCrop((prevCrop) => ({ ...prevCrop, width: value }));
  };

  const handleImageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result);
      };
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  return (
    <div>
      <input type="file" onChange={handleImageChange} accept="image/*" />

      {image && (
        <>
          <ImageCrop
            src={image}
            crop={crop}
            onChange={(newCrop) => setCrop(newCrop)}
            onImageLoaded={(image) => {
              imgRef.current = image;
            }}
          />
          <div>
            <label>Zoom:</label>
            <Slider
              min={10}
              max={100}
              step={1}
              value={crop.width}
              onChange={handleZoomChange}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default ImageCropWithZoom;
