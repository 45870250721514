import { api } from "../../api/apiSlice";

const locationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getLocationList: builder.query({
            query: () => ({
                url: '/locations/all'
            })
        }),
    })
});

export const { useGetLocationListQuery } = locationApi;