import { api } from '../../api/apiSlice';

const groupApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getAllGroups: builder.query({
      query: () => ({
        url: '/groups/findAll',
      }),
      providesTags: ['Groups'],
    }),
    getGroupDetails: builder.query({
      query: (id) => ({
        url: `/groups/singleItem/${id}`,
      }),
      providesTags: ['Groups'],
    }),
    addGroups: builder.mutation({
      query: (data) => ({
        url: `/groups/create`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Groups'],
    }),
    addGroupUsers: builder.mutation({
      query: (data) => ({
        url: `/groups/addUsersToGroup`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Groups'],
    }),
    deleteGroupUsers: builder.mutation({
      query: (data) => ({
        url: `/groups/removeUsersFromGroup`,
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Groups'],
    }),
    updateGroups: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/groups/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Groups'],
    }),
    getGroupsSearchResult: builder.mutation({
      query: (data) => ({
        url: '/groups/search',
        method: 'POST',
        body: data,
      }),
    }),
    checkGroupName: builder.mutation({
      query: (data) => ({
        url: '/groups/checkGroupName',
        method: 'POST',
        body: data,
      }),
    }),
    deleteGroup: builder.mutation({
      query: (id) => ({
        url: `/groups/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Groups'],
    }),
    getUserSearchInGroupResult: builder.mutation({
      query: (data) => ({
        url: '/groups/searchUsersInGroup',
        method: 'POST',
        body: data,
      }),
    })
  }),
});

export const {
  useGetAllGroupsQuery,
  useGetGroupDetailsQuery,
  useLazyGetGroupDetailsQuery,
  useAddGroupsMutation,
  useAddGroupUsersMutation,
  useDeleteGroupUsersMutation,
  useUpdateGroupsMutation,
  useGetGroupsSearchResultMutation,
  useDeleteGroupMutation,
  useCheckGroupNameMutation,
  useGetUserSearchInGroupResultMutation
} = groupApi;
