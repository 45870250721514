import React from "react";
import { makeStyles, } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import './SelectFieldMulticolor.css';



{/* <SelectFieldMulticolor
  labelTop={false}
  labelinsideStatus={true}
  statustData={statustData}
  defaultValue={courseData.status ? courseData.status : ""}
  handleChange={handleChange}
  width="140px"
  height="40px"
  Status={true}
/> */}



function SelectFieldMulticolor(props) {
  const { statustData } = props;

  //const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  return (
    <div className="SelectFieldMulticolor">
      {props.labelTop && <label >{props.label}</label>}
      <div className="SelectFieldMulticolorBody" style={{ height: props.height, width: props.width }}>
        {props.labelinsideStatus && <label >{props.label}</label>}
        <FormControl >
          <Select
            id="demo-controlled-open-select"
            open={open}
            onClose={handleClose}
            onOpen={handleOpen}
            onChange={props.handleChange}
            defaultValue={props.defaultValue}
          >

            {statustData.map((select, i) => (
              <MenuItem
                key={i}


                value={select.value}>

                {props.Status &&
                  <span
                    style={{
                      width: "12px",
                      height: "12px",
                      display: "inline-block",
                      borderRadius: "100px",
                      marginRight: "10px",
                      background: select.statuscolor
                    }}
                  >

                  </span>}
                <span style={{ color: select.statuscolor }}>{select.select}</span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>

    </div>
  );
}


export default SelectFieldMulticolor;

