import React, { useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import TableHeader from '../../../components/Table/TableHeader';
import TableSubHeader from '../../../components/Table/TableSubHeader';

import InviteUsers from './InviteUsers';
import InviteNewUser from './InviteNewUser';
import { useGetRoleListQuery } from '../../../redux/features/roles/roleApi';
import useProtected from '../../../hooks/useProtected';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import { ImportUsers } from './ImportUsers';
import { useGetInviteUserListQuery, useGetInviteUsersSearchResultMutation } from '../../../redux/features/users/userApi';
import DraggableDialog from '../../../components/Dialog/Dialog';
import { useSearchParams } from 'react-router-dom';


const InviteUsersPage = () => {




  const [InviteDetailsDrawer, setCreateInviteDetails] = useState(false);
  const { data, isError, isLoading, error } = useGetInviteUserListQuery(undefined, { refetchOnMountOrArgChange: true });
  const [getSearchedUserList, options] = useGetInviteUsersSearchResultMutation();


  const {
    data: roleData,
    isLoading:
    roleIsLoading,
    isFetching: roleIsFetching,
    error: roleError
  } = useGetRoleListQuery(undefined, { refetchOnMountOrArgChange: true })


  useProtected(error);
  useProtected(roleError)
  useProtected(options.error)


  const [roles, setRoles] = useState([])
  const [rows, setRows] = useState([])
  const [users, setUsers] = useState([])
  const [value, setValue] = React.useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [inviteUserButtonEnableDisable, setInviteUserButtonEnableDisable] = useState(false)
  const [importedUsers, setImportedUsers] = useState([])
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const [selectedUserType, setSelectedUserType] = useState('');
  const [matchColumns, setMatchColumns] = useState([])
  const [emailIndex, setEmailIndex] = useState(-1);
  const [firstNameIndex, setFirstNameIndex] = useState(-1);
  const [middleNameIndex, setMiddleNameIndex] = useState(-1);
  const [LastNameIndex, setLastNameIndex] = useState(-1);
  useEffect(() => {
    if (!roleIsLoading && !roleIsFetching) {
      setRoles(roleData?.body?.data)
    }
  }, [roleIsLoading, roleIsFetching, roleData])

  useEffect(() => {
    if (!isLoading && !isError) {
      // setRows(convertDataToCellRows(data.body.data));
      setUsers(data.body.data)
    }
  }, [data]);

  const tabLabel = [
    {
      label: "Invited Users",
    },
    {
      label: "Invite New Users",
    },

  ]
  //==================================  ============================================//
  const [searcParams] = useSearchParams()
  const valueFromUrl = searcParams.get("value")
  console.log(valueFromUrl, 328)
  useEffect(() => {
    if (valueFromUrl) {
      setValue(1)
    }
  }, [searcParams])
  //==================================  ============================================//

  const tabPanel = [
    {
      id: 0,
      panel: <InviteUsers setInviteUserButtonEnableDisable={setInviteUserButtonEnableDisable} users={users} />,
    },
    {
      id: 1,
      panel: <InviteNewUser
        setInviteUserButtonEnableDisable={setInviteUserButtonEnableDisable}
        roles={roles}
        setActiveTab={setValue}
        importedUsers={importedUsers}
        setImportedUsers={setImportedUsers}
        selectedUserType={selectedUserType}
        matchColumns={matchColumns}
        setMatchColumns={setMatchColumns}
        emailIndex={emailIndex}
        firstNameIndex={firstNameIndex}
        middleNameIndex={middleNameIndex}
        LastNameIndex={LastNameIndex}
        setSelectedUserType={setSelectedUserType}
      />,
    },
  ]


  const addInviteUserHandler = () => {
    setValue(1)
  }

  // * =========== Import users =========== * //
  const [importUserDrawer, setImportUserDrawer] = useState(false);

  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setSearchTerm("")
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ term: searchTerm });
    if (result?.data) {
      setUsers(result.data.body.data);
      setSearchTerm("")
    }
    setPageNumberFromOutSide(false)
  };

  const addMenuBtnArr = [
    {
      label: "INVITE MANUALLY",
      btnFnc: addInviteUserHandler
    },
    {
      label: "IMPORT USERS",
      btnFnc: () => setImportUserDrawer(true)
    },
  ]

  return (
    <>
      <div className="TableSection">
        <TableHeader
          TableTitle="Invite Users"
          AddButton={false}
          AddButtonLabel="Invite new users"
          //AddIcon={<AddRoundedIcon />}
          AddButtonWidth=""
          inviteUserButtondisabled={inviteUserButtonEnableDisable}
          //AddButtonLink=""
          AddButtonOnClick={(e) => addInviteUserHandler()}

          AddOutlinedButton={false}
          AddOutlinedButtonLabel="import users"
          AddOutlinedButtonBgColor="transparent"
          AddOutlinedButtonOnClick={() => setImportUserDrawer(true)}


          AddMenuButton={true}
          AddMenuButtonLabel="Invite new users "
          addMenuBtnArr={addMenuBtnArr}
        />

        <TableSubHeader
          Tabs={true}
          tabLabel={tabLabel}
          tabPanel={tabPanel}
          TabValue={value}
          TabHandleChange={handleChange}
          Filter={false}
          //FilterOnClick={FilterOnClick}
          showFilter={false}
          //== Search Code == //
          Search={true}
          SearchPlaceholder="Search..."
          // SearchValue={searchTerm}
          SearchOnChange={handleSearchText}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          pageNumberFromOutSide={pageNumberFromOutSide}
          //SearchOnclick={SearchOnclick}
          //SearchOnMouseDown={SearchOnMouseDown}
          handleSearch={handleSearch}
          Border={true}
        />

      </div>

      <SideDrawer
        open={importUserDrawer}
        handleDrawerClose={() => { setImportUserDrawer(false); setSelectedUserType('') }}
        title="Import Users"
        sideOpen="right"
        FooterWithoutTab={false}
        body={
          <>
            <ImportUsers
              importedUsers={importedUsers}
              setImportedUsers={setImportedUsers}
              setActiveTab={setValue}
              setImportUserDrawer={setImportUserDrawer}
              selectedUserType={selectedUserType}
              setSelectedUserType={setSelectedUserType}
              setMatchColumns={setMatchColumns}
              matchColumns={matchColumns}
              setEmailIndex={setEmailIndex}
              setFirstNameIndex={setFirstNameIndex}
              setMiddleNameIndex={setMiddleNameIndex}
              setLastNameIndex={setLastNameIndex}
            />
          </>
        }
      />



    </>
  );
};

export default InviteUsersPage;
