import React, { useEffect, useState } from 'react';
import { styled } from '@mui/system';
import { Button } from '@mui/material';
import Table from '../../../Table/Table';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import person from '../../../../assets/imgs/User.png';
import Modals from '../../../Form/Modals/Modals';
import FileUploadPicID from '../../../CreateIdDrawer/PictureID/FileUploadPicID/FileUploadPicID';
import { useGetUserListQuery } from '../../../../redux/features/users/userApi';
import useProtected from '../../../../hooks/useProtected';
import { toTitleCase } from '../../../../utils/helpers';

const SearchUser = ({ selectedList, selectUsers }) => {
  const { data, isError, isLoading, error } = useGetUserListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  useProtected(error);

  const [rows, setRows] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(
    selectedList?.length > 0 ? selectedList : [],
  );

  useEffect(() => {
    selectUsers(selectedUsers);
  }, [selectedUsers])

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => ({
        cell: [
          {
            number: `${item.firstName} ${item.lastName}`,
            align: 'left',
            chip: 'Active',
            space: '8px',
            Status: true,
            background: item?.status === "active" ? '#edf7ea' : '#FCE7E9',
            color: item?.status === "active" ? '#66BD50' : '#E63946',
            label: item?.status || "N/A",
            user_id: item._id,
            Check: true
          },
          { textBold: "..." + item?.userUniqueID || "N/A" },
          { textBold: item?.role ? toTitleCase(item.role) : "N/A" },
          {
            textBold: (
              <CheckCircleRoundedIcon sx={{ color: '#66BD50 !important' }} />
            ),
          },
          {
            Component: (
              <>
                <StyledButton
                  variant="contained"
                  startIcon={<AddRoundedIcon />}
                  onClick={() => setFileUploader(true)}
                >
                  Upload Photo
                </StyledButton>
              </>
            ),
          },
          { textBold: item?.lastOrdered || "N/A" },
        ],
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
    }
  }, [data, isLoading, isError]);

  const [fileUploader, setFileUploader] = useState(false);

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '30%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'ID #',
      width: '10%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'User type',
      width: '10%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'QR',
      width: '7%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Photo',
      width: '25%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Last ordered',
      width: '18%',
      textAlign: 'left',
    },
  ];

  return (
    <>
      <div className="TableSection">
        <Table
          title="Course"
          tableHeader={false}
          AddButton={false}
          addLabel=""
          innerTitle=""
          link="add-courses"
          addLabelInner="Add Payment"
          icon={<AddRoundedIcon />}
          // CheckboxDelete={true}
          headCells={headCells}
          rows={rows}
          Checkbox={false}
          footer={true}
          innerHeader={true}
          AddButtonInner={false}
          InnerButtonColor="#004FE0"
          InnerButtonTextColor="#fff"
          InnderButtonIcon={<AddRoundedIcon />}
          showSearchBox={true}
          AddButtonOutLine={true}
          //onClickOutlineButton={handleOpenAttendanceEntry}
          OutLineButtonLabel="Add entry"
          isDataLoading={isLoading}
          handleCheckItem={setSelectedUsers}
          customCellObjectId="user_id"
        />
      </div>

      {/*  - file uploader -  */}
      <Modals
        ModalTitle="Upload from device"
        handleClose={() => setFileUploader(false)}
        // handleConfirm={() => handleConfirm()}

        open={fileUploader}
        ButtonClose="CLOSE"
        ButtonConfirm="CONFIRM"
        width="570px"
        Headerbackground="#fff"
        Headercolor="#676C7B"
        Footerbackground="#F7F9FA"
        ModalFooter={false}
        ModalBody={
          <div>
            <FileUploadPicID />
          </div>
        }
      />
    </>
  );
};

export default SearchUser;

const StyledButton = styled(Button)(({ theme }) => ({
  //   padding: '14px 46px !important',
  width: '100% !important',
  background: '#F2F0F4 !important',
  boxShadow: 'none !important',
  color: '#0450E1 !important',
  fontSize: '14px !important',
  fontWeight: '700 !important',
  textTransform: 'capitalize',
  borderRadius: '4px !important',
  '&:hover': {
    background: '#F2F0F4 !important',
    boxShadow: 'none !important',
  },
  [theme.breakpoints.down('sm')]: {
    // marginTop: '26px !important',
  },
}));

// position: 'relative !important',
// '& .MuiButton-startIcon ': {
//   position: 'absolute !important',
//   left: '16px !important',
// },
