import React, { useState } from 'react';
import { Button, Grid } from '@mui/material';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import SideDrawer from '../../SideDrawer/SideDrawer';
import PermIdentityOutlinedIcon from '@mui/icons-material/PermIdentityOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import SearchUser from './SelectIndividuals/SelectIndividuals';
import SelectGroups from "./SelectGroups/SelectGroups"
import { useUpdateOrderDetailsUsersMutation } from '../../../redux/features/order/orderApi';
import { toast } from 'react-toastify';

const Users = ({ userDetails }) => {
  const [updateOrderDetailsUsers, updateOrderDetailsUsersOptions] = useUpdateOrderDetailsUsersMutation();
  const [btnDisabled, setButtonDisabled] = useState(false);
  const [OpenSelectUsers, setSelectUsers] = useState(false);
  const handleOpenSelectUsers = () => setSelectUsers(true);
  const handleCloseSelectUsers = () => setSelectUsers(false);
  //=======  Drawer With Sub Drawer Start ===== //
  const [openDrawerSub, setOpenSub] = useState(false);
  const handleDrawerOpenSub = () => {
    setOpenSub(true);
  };
  const handleDrawerCloseSub = () => {
    setOpenSub(false);
  };

  const [selectedUsers, setSelectedUsers] = useState(userDetails?.selectedUsers ? userDetails?.selectedUsers : []);
  const [selectedGroups, setSelectedGroups] = useState(userDetails?.selectedGroups ? userDetails?.selectedGroups : []);
  //=======  Drawer With Sub Drawer End ===== //

  //=======  Drawer With Sub Menu ===== //
  const tabData = [
    {
      lebel: "Select individuals",
      icon: <PermIdentityOutlinedIcon />,
    },

    {
      lebel: "Select groups",
      icon: <PeopleAltOutlinedIcon />,
    },
  ]

  const handleGroupSelection = (items) => {
    setSelectedGroups(items);
  }

  const handleSelectUsers = async () => {
    const totalUserInGroup = selectedGroups.length ? selectedGroups?.reduce((acc, current) => acc + current.users.length, 0) : 0;
    const quantity = selectedUsers.length + totalUserInGroup;
    const groupIds = selectedGroups?.map(item => item._id)
    const result = await updateOrderDetailsUsers({ id: userDetails._id, selectedUsers, selectedGroups: groupIds, quantity });
    if (!result?.data?.error) {
      toast.success("Order user list updated successfully")
      handleDrawerCloseSub();
    } else {
      toast.error(result.data.message)
    }
  }

  const panelTab = [
    {
      id: 0,
      panleBody: <SearchUser selectedList={selectedUsers} selectUsers={setSelectedUsers} />,

      SaveButtonLabel: `Select ${selectedUsers?.length || 0} Users`,
      clickSaveButton: handleSelectUsers,
      CancelButtonLabel: "Cancel",

      Checkbox: false,
      CheckboxLabel: "Add another after saving",
      //onClickCheckbox:onClickCheckbox,

      DeleteButton: false,
      DeleteLebel: "Delete Permanently",
      //onClickDelete:onClickDelete,

      SubDrawerBodyWithTab: "Sub Drawer Body With Tab",

    },

    {
      id: 1,
      panleBody: <SelectGroups selectedList={selectedGroups} selectGroups={handleGroupSelection} />,
      SaveButtonLabel: "Select Groups",
      clickSaveButton: handleSelectUsers,
      cancelButtonLabel: "Cancel",
    },

  ]
  //=======  Drawer With Sub Menu ===== //



  return (
    <>
      <Grid container spacing={2}>
        <Grid item md={12} xs={12}>
          <div className="AddField" onClick={() => handleDrawerOpenSub()}>
            <Button
              href="#contained-buttons"
            >
              <div className="AddIcon">
                <AddRoundedIcon />
              </div>{' '}
              Select users
            </Button>
            <div className="UserCount">{userDetails?.quantity || 0}</div>
          </div>
        </Grid>
        {/* <Grid item md={6} xs={12}>
          <div className="AddFieldDisabled">
            <Button
              href="#contained-buttons"
              disabled
              onClick={() => handleOpenSelectUsers()}
            >
              <div className="AddIcon">
                <AddRoundedIcon />
              </div>{' '}
              Select groups
              <span
                className="buttonChip"
                style={{
                  marginRight: '5px',
                  background: '#EDEDEF',
                  color: '#676C7B',
                  borderRadius: '1000px',
                }}
              >
                Coming soon
              </span>
            </Button>
            <div className="UserCount">0</div>
          </div>
        </Grid> */}
      </Grid>

      {/* new user drawer */}
      <SideDrawer
        open={OpenSelectUsers}
        handleDrawerClose={handleCloseSelectUsers}
        title="Add Attendance"
        buttonLabel="Save"
        padding="0"
        // buttonLink={}
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={true}
        body={
          <>
            <SearchUser />
          </>
        }
      />
      {/* ----------------------- new drawer -------------------- */}
      <SideDrawer
        padding="0"
        title="Select Users"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        // onClickDelete={onClickDelete}
        DeleteButton={false}
        sideOpen="right"
        open={openDrawerSub}
        handleDrawerClose={handleDrawerCloseSub}
        CheckboxLabel="Add another after saving"
        Checkbox={false}
        LeftButton={false}
        ButtonLeft="Upload New"
        ButtonLeftIcon={<FileUploadOutlinedIcon />}
        FooterWithoutTab={false}
        panelTab={panelTab}
        tabData={tabData}
        arrowIcon={false}

        TabsStatus={true}

        CheckBoxTabsStatus={false}
        TabsPannelStatus={true}

        // SubDrawer="SubDrawer"
        SubDrawerButtonSatus={false}
        SubDrawerTabSatus={false}
        SubDrawerWithOutTab={false}
        SubDrawerWithOutTabBody={<p>Sub Drawer WithOut Tab Body</p>}

        TitleUnderHeaderStatus={false}
        TitleUnderHeader=""
        SubTitle="Payment Method*"

      />
    </>
  );
};

export default Users;
