import styled, { keyframes } from "styled-components";

const spinAnimation = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const StyledLoader = styled.div`
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-radius: 50%;
  border-top: ${props => props.borderWidth} solid #004FE0;
  width: ${props => props.width};
  height: ${props => props.height};
  animation: ${spinAnimation} 2s linear infinite;
  margin: auto;
`;

const LoaderContainer = styled.div`
  height: ${props => props?.isFullWidth ? '100%' : 'auto'};
  width: ${props => props?.isFullWidth ? '100%' : 'auto'};
  padding: ${props => props?.isFullWidth ? '100px 0px' : '0px'};
  position: ${props => props?.isOverlay ? 'absolute' : '0px'};
  z-index: ${props => props?.isOverlay ? '10' : ''};
  // background: ${props => props?.isOverlay ? '#00000050' : ''};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: ${props => props?.margin};
`;

const Loader = ({ width = "40px", height = "50px", borderWidth = "4px", margin="", isFullWidth=false, isOverlay=false }) => {
    return <LoaderContainer isFullWidth={isFullWidth} margin={margin} isOverlay={isOverlay}>
    <StyledLoader height={height} width={width} borderWidth={borderWidth} />
    </LoaderContainer>
};

export default Loader;
