import React from "react";
import FileUplaoder from "../../../Form/FileUplaoder/FileUplaoder";
import Buttons from "../../../Form/Button/Button";

const FileUploadPicID = () => {
  // === onChange event handler, get field data from parem === //
  // const handleChangeWithData = (fieldName, fieldData) => {
  //   console.log(fieldName, fieldData);
  //   setPersonalInfo({ ...personalInfo, [fieldName]: fieldData });
  //   setDisableSaveBtn(false);
  // };

  return (
    <div>
      <FileUplaoder
        // filePath={`students/${personalInfo.id}/profile-images/`}
        uploadButton="Upload Photo"
        // file={personalInfo.image}
        fileType="image"
        // onUpload={(filePath) => handleChangeWithData("image", filePath)}
        // setSelectedFile={fileSelectedHandler}
        helpertext="Minimum dimension of 750x750 pixels. 
        Maximum size of 10.0MB. .jpg, .jpeg, or .png."
        showUploadButton={false}
        showThumb={false}
      />
      <br />
       <Buttons
          label="UPLOAD"
          buttonColor="#FFB606"
          // border="#E4E8EC 1px solid"
          borderRadius="8px"
          color="#000000"
          width="100%"
          height="40px"
          fontSize="14px"
          // link=""
          // onClick={() => handleOpen()}
        />
         <Buttons
          label="Cancel"
          buttonColor="transparent"
          // border="#E4E8EC 1px solid"
          borderRadius="8px"
          color="#0450E1"
          width="100%"
          height="40px"
          fontSize="14px"
          // link=""
          // onClick={() => handleOpen()}
        />
    </div>
  );
};

export default FileUploadPicID;
