import React from 'react';
import { MenuItem } from 'react-pro-sidebar';
import { Link } from 'react-router-dom';

const CustomMenuItem = ({ children, className, href, ...rest }) => {
    return (
        <MenuItem
            className={className}
            component={<Link to={href} />}
        >
            {children}
        </MenuItem>
    );
};

export default CustomMenuItem;