import React from "react";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Fade from '@mui/material/Fade';
import Button from '@mui/material/Button';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import SelectField from '../Form/SelectField/SelectField';
import Buttons from '../Form/Button/Button';

import './Table.css';


function Filter(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };


  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "Active",
      value: "Active",
    },

    {
      label: "Revoked",
      value: "Revoked",
    },

  ]



  return (
    <>
      <Button
        id="fade-button"
        aria-controls={open ? 'fade-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        className="FilterButton"
      >
        <FilterAltIcon /> Filter
      </Button>


      <Menu
        id="fade-menu"
        MenuListProps={{
          'aria-labelledby': 'fade-button',
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        TransitionComponent={Fade}
        className="FilterContainer"
      >
        {/* <MenuItem onClick={handleClose}>Profile</MenuItem> */}
        <div className="filterBody">

          <div className="FilterTitle">
            Filter options
          </div>

          < SelectField
            label="Status"
            options={options}
            handleChange={handleChanges}
          />

          <div className="FilterCheckBox">
            <p>Stream</p>
            <FormGroup>
              <FormControlLabel control={<Checkbox defaultChecked />} label="Online" />
              <FormControlLabel control={<Checkbox defaultChecked />} label="Manual" />
            </FormGroup>
          </div>

          <div className="FilterButtonGroup">
            <Buttons
              label="Clear Filter"
              buttonColor="#fff"
              color="#004FE0"
              height="40px"
              fontSize="14px"
              link={props.linkInnerButton}
              border="#E4E8EC 1px solid"
              controls={open ? 'fade-menu' : undefined}
              haspopup="true"
              expanded={open ? 'true' : undefined}
              onClick={handleClick}
            />

            <Buttons
              label="Apply"
              buttonColor="#004FE0"
              color="#fff"
              height="40px"
              fontSize="14px"
              link={props.linkInnerButton}
              border="#E4E8EC 1px solid"
              controls={open ? 'fade-menu' : undefined}
              haspopup="true"
              expanded={open ? 'true' : undefined}
              onClick={handleClick}
            />
          </div>
        </div>
      </Menu>

    </>
  );
}


export default Filter;

