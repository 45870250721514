import React, { useEffect, useState } from 'react';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import SelectField from '../../../components/Form/SelectField/SelectField';
import TextArea from '../../../components/Form/TextArea/TextArea';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';

import '../../../assets/css/users.css';

import SelectGuardians from './SelectGuardians';
import { useAddAttendanceMutation, useGetAllAttendanceCodesQuery } from '../../../redux/features/attendence/attendanceApi';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import { validateFormData } from '../../../utils/form';
import Loader from '../../../components/Loader/Loader';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import { useAddGuardianMutation } from '../../../redux/features/users/guardianApi';

const AddGuardiansParents = ({ closeDrawer, selectedUserList, setSelectedUserList, isLoading, guardianListDatas }) => {
  const [age, setAge] = React.useState('');

  // const [userIdList, setUserIdList] = useState([]);
  const [status, setStatus] = useState('');
  const [reason, setReason] = useState('');
  const [comment, setComment] = useState('');
  const [errors, setErrors] = useState({
    userIdList: ''
  });
  const [selectedCode, setSelectedCode] = useState();
  const [attendanceCodeOptions, setAttendanceCodeOptions] = useState([]);


  const convertAttendanceCodeResponseToSelectItem = (locations) => {
    if (locations.length > 0) {
      return locations.map((item) => ({ label: item.code, value: item._id }));
    } else {
      return [];
    }
  };

  // const attendance = [
  //   {
  //     label: 'Present',
  //     value: 'Present',
  //   },

  //   {
  //     label: 'Absent',
  //     value: 'Absent',
  //   },
  // ];
  const [userIdListJust, setUserIdListJust] = useState([]);
  const [OpenSelectUsers, setSelectUsers] = useState(false);
  const handleOpenSelectUsers = () => setSelectUsers(true);
  const handleCloseSelectUsers = () => {
    // setSelectedUserList([])
    setSelectUsers(false)
  };

  const handleCloseDrawer = () => {
    closeDrawer();

  };

  const selectUsers = (userList) => {
    const newList = userList.map((item) => ({
      name: `${item?.firstName || ''} ${item?.lastName || ''}`,
      email: item?.email || '',
      userUniqueID: item?.userUniqueID || '',
      relationShip: item?.relationship || 'guardian',
      status: item?.status || '',
    }));
    setSelectedUserList(newList);
    setUserIdListJust(userList.map(item => item?._id || ''))
  };
  const backgroundInCount = {
    backgroundColor: selectedUserList?.length > 0 ? '#ffb606' : '#f2f0f4',
  };

  // useEffect(() => {
  //   console.log(selectedUserList, 400)
  // }, [selectedUserList])
  return (
    <>
      <>
        <div className="">
          {isLoading ? (
            <Loader
              height="80px"
              width="80px"
              borderWidth="8px"
              isFullWidth={true}
              isOverlay={true}
            />
          ) : (
            <div className="AddField" onClick={() => handleOpenSelectUsers()}>
              <Button
                href="#contained-buttons"

                sx={{ textTransform: 'initial !important' }}
              >
                <div className="AddIcon">
                  <AddRoundedIcon />
                </div>{' '}
                Select users
              </Button>
              <div className="UserCount" style={backgroundInCount}>
                {selectedUserList.length ? selectedUserList.length : 0}
              </div>
              {
                errors?.userIdList && (
                  <div>
                    <p className='error-msg'>{errors?.userIdList}</p>
                  </div>
                )
              }
            </div>
          )}
        </div>

        {/* <DrawerFooterActions
          isLoading={addGuardianOptions.isLoading}
          handleClose={handleCloseDrawer}
          handleAction={handleAddGuardian}
          closeBtnLabel={'Close'}
          mobileCloseBtnLabel={'Mobile Close'}
          actionButtonLabel={'Add Now'}
        /> */}

        {/* new user drawer */}
        <SideDrawer
          open={OpenSelectUsers}
          handleDrawerClose={handleCloseSelectUsers}
          title="Select Users"
          buttonLabel="Add now"
          // buttonLink={}
          sideOpen="right"
          ButtonLabel="Add now"
          cancelButtonLabel="Cancel"
          FooterWithoutTab={false}
          padding="0"
          body={
            <>
              <SelectGuardians
                selectUsers={selectUsers}
                handleCloseSelectUsers={handleCloseSelectUsers}
                selectedList={selectedUserList}
                renderFullItem={true}
                selectedListJust={userIdListJust}
                guardianListDatas={guardianListDatas}
              />
            </>
          }
        />
      </>
    </>
  );
};

export default AddGuardiansParents;
