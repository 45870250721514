import React from 'react'
import Modal from '@mui/material/Modal';
import Backdrop from '@mui/material/Backdrop';
import Fade from '@mui/material/Fade';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
// import styles from './ModalThree.module.css'
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';

// import formStyles from '../../pages/authentication/form.module.css'
import "./ModalThree.css";

function ModalThree(props) {

    // const [open, setOpen] = React.useState(false);
    // const handleOpen = () => setOpen(true);
    // const handleClose = () => setOpen(false);

    {/* <Button onClick={() => setOpen(true)}><AddRoundedIcon />Module</Button> */ }

    // <ModalOne
    //   open={open}
    //   handleClose={handleClose}
    //   width="390px"
    //   Modaltitle="Filter"
    //   titileColor="#33B651"
    //   padding="16px"

    //   body={
    //     <div>

    //     </div>
    //   }

    // />



    return (
        <div>


            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={props.open}
                onClose={props.handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={props.open}>

                    <div className="ModalThree">

                        <div className="ModalThreeContainer" style={{ width: props.width, padding: props.padding, }}>

                            <div className="ModalThreeIconCtn ModalTitle" >
                                <CloseRoundedIcon className="closeIcon" onClick={() => props.handleClose()} />
                                <h1>{props.Modaltitle}</h1>
                            </div>

                            <div className="ModalThreeMdlBody">
                                <h2 style={{ color: props.titileColor }}>{props.title}</h2>
                                <div className="Scroll" style={{ padding: '0 32px' }}>
                                    {props.body}
                                </div>


                            </div>
                            <div className="SignFormFooter">
                                {/* <LockOutlinedIcon />
                  <span>SECURE SIGN IN</span> */}
                                <SecurityOutlinedIcon />
                                <span>SECURE SSL ENCRYPTION</span>
                            </div>
                        </div>
                    </div>

                </Fade>
            </Modal>


        </div>


    )
}






export default ModalThree;