import React, { useEffect, useState } from 'react';
import '../../../assets/css/users.css';
import Grid from '@mui/material/Grid';
import SelectField from '../../../components/Form/SelectField/SelectField';
import AddFieldGroup from '../../../components/Form/AddTextField/AddFieldGroup';
import { useGetSchoolListQuery } from '../../../redux/features/schools/schoolApi';
import {
  useCheckEmailMutation,
  useCreateInviteUserMutation,
  useGetUserRolesQuery,
} from '../../../redux/features/users/userApi';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import AlrtMessage from '../../../components/Form/AlrtMessage/AlertMessage';
import useProtected from '../../../hooks/useProtected';
import { toTitleCase } from '../../../utils/helpers';
import { isValidEmail } from '../../../hooks/HelperFunctions';

const InviteNewUsers = () => {
  const {
    data: schoolResponse,
    isLoading: isSchoolResponseLoading,
    isError: isSchoolResponseError,
    error,
  } = useGetSchoolListQuery(undefined);


  const {
    data: rolesResponse,
    isLoading: isRolesLoading,
    isError: isRolesError,
    error: rolesError,
  } = useGetUserRolesQuery(undefined);
  useProtected(error);
  useProtected(rolesError);

  const [checkEmail, checkEmailOptions] = useCheckEmailMutation()
  const [createUser, createUserOptions] = useCreateInviteUserMutation();

  const navigate = useNavigate();

  useProtected(createUserOptions?.error);
  useProtected(checkEmailOptions?.error);

  const [locationList, setLocationList] = useState([]);
  const [usersData, setUsersData] = useState([
    { email: '', firstName: '', lastName: '', role: '', emailError: '' },
  ]);

  // == Select Field ===//
  const [selectedLocation, setSelectedLocation] = useState('');
  const [emailError, setEmailError] = useState('')
  const [roles, setRoles] = useState([]);

  const [alertOptions, setAlertOptions] = useState({
    msg: '',
    type: 'error',
  });
  const handleLocationChange = (event) => {
    setSelectedLocation(event.target.value);
  };

  const convertResponseToSelectItem = (locations) => {
    if (locations.length > 0) {
      return locations.map((item) => ({ label: item.dba, value: item._id }));
    } else {
      return [];
    }
  };

  const convertRolesResponseToSelectItem = (locations) => {
    if (locations.length > 0) {
      return locations.map((item) => ({ label: toTitleCase(item.name), value: item.name }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isSchoolResponseLoading && !isSchoolResponseError) {
      setLocationList(convertResponseToSelectItem(schoolResponse.body.data));
    }
  }, [schoolResponse, isSchoolResponseLoading, isSchoolResponseError]);

  useEffect(() => {
    if (!isRolesLoading && !isRolesError) {
      setRoles(convertRolesResponseToSelectItem(rolesResponse.body.data));
    }
  }, [rolesResponse, isRolesLoading, isRolesError]);

  const isDataValidated = (data) => {
    if (!data.schoolId) return false;
    for (const item of data.users) {
      for (const [key, val] of Object.entries(item)) {
        if ((key === 'email' || key === 'role') && !val) return false;
      }
    }
    return true;
  };


  const handleAddInviteUser = async () => {


    if (emailError != "") return false

    //=========================== filter out all the existing email ====================//
    const existingEmails = usersData.filter((existingEmail) => existingEmail.emailError === "Email already exists")
    if (existingEmails.length > 0) {
      return false;
    }
    if (existingEmails.length === 0) {
      try {
        const data = {
          schoolId: selectedLocation,
          users: [...usersData],
        };

        const isValid = isDataValidated(data);
        console.log(isValid)

        if (!isValid) {
          setAlertOptions({
            msg: 'Please fill all required fields',
            type: 'error',
          });

          setTimeout(() => {
            handleAlertClose();
          }, 5000);

          return;
        }

        const result = await createUser(data);
        if (!result?.data?.error) {
          //toast.success(result.data.message);
          toast.success("Invitation sent successfully");
          navigate('/invited-users');
        } else {
          toast.error(result.data.message);
        }
      } catch (err) {
        toast.error(err.message);
      }
    }
  };

  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOptions({
      msg: '',
      type: '',
    });
  };


  const checkEmailHandler = async (event, obj, index) => {
    let error = 0
    if (!isValidEmail(event.target.value) || event.target.value.trim() === '') {
      obj.emailError = "Enter a valid email address"
      error++
    }
    let result = await checkEmail({ email: (event.target.value === '') ? '***' : event.target.value })
    if (result?.data && result?.data?.error) {
      obj.emailError = "Email already exists"
      error++
    }
    if (error === 0) obj.emailError = ""

    let newUserData = usersData.map((userObj, i) => {
      if (i === index) {
        return obj
      } else {
        return userObj
      }
    })
    setUsersData(newUserData)
  }
  return (
    <>

      <AlrtMessage
        open={Boolean(alertOptions.msg)}
        message={alertOptions.msg}
        severity={alertOptions.type}
        closeHandler={handleAlertClose}
      />
      <div className="InvitedUsers">
        <div className="PageTitle">Invite New Users</div>

        <Grid container spacing={2}>

          <Grid item md={8} xs={12}>
            <div className="labelBold">
              <SelectField
                FieldLabel={true}
                label="Select school *"
                value={selectedLocation}
                options={locationList}
                handleChange={handleLocationChange}

              />
            </div>
          </Grid>

          <Grid item md={12} xs={12}>
            <AddFieldGroup
              placeholder="johndoe@gmail.com"
              minItem={1}
              maxItem={6}
              SelectFieldStatus={true}
              SelectFieldLabel={true}
              col={4}
              primaryField="objId"
              textField="title"
              addButtonLabel="Add more users"
              SubmitButton={true}
              submitButtonLabel="Send invitation"
              location={selectedLocation}
              FieldOneLabel="Email address *"
              FieldTwoLabel="Name (Optional)"
              FieldThreeLabel="User type *"
              setUsersData={setUsersData}
              usersData={usersData}
              options={roles}
              onclickSubmit={handleAddInviteUser}
              isButtonLoading={
                isSchoolResponseLoading || createUserOptions?.isLoading || checkEmailOptions?.isLoading
              }
              checkEmail={checkEmailHandler}
              emailError={emailError}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default InviteNewUsers;
