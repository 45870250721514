import React, { useEffect, useState } from 'react';
import { withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import img from '../../../assets/imgs/User.png';

//import title case from helper
// import { toTitleCase } from '../../../utils/helpers';

import SelectField from '../../../components/Form/SelectField/SelectField';
import Buttons from '../../../components/Form/Button/Button';

import Avatar from '@mui/material/Avatar';
import { deepPurple, } from '@mui/material/colors';

import '../../../assets/css/users.css';
import {
  useDeleteInviteUserMutation,
  useGetInviteUserDetailsQuery,
  useGetUserRolesQuery,
  useResendInvitationMutation,
  useUpdateInviteUserMutation,
} from '../../../redux/features/users/userApi';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import { useSelector } from 'react-redux';
import moment from 'moment-timezone';
import { getProfileAvatarFromName } from '../../../utils/helpers';
//import title case from helper 
import { toTitleCase } from '../../../utils/helpers';
import DraggableDialog from '../../../components/Dialog/Dialog';
import { useGetUserTypesQuery } from '../../../redux/features/user_types/userTypesApi';
import { capitalizeFirstLetter } from '../../../components/hooks/HelperFunctions';
import { capitalizeWords } from '../../../hooks/HelperFunctions';

const InvitationDetails = ({ closeDrawer, userId }) => {

  const {
    data: userTypeData,
    isLoading: userTypeIsLoading,
    isFetching: userTypeIsFetching,
    error: userTypeError
  } = useGetUserTypesQuery()


  useProtected(userTypeError)

  // ================= Table OD and EVEN Code ========//
  const StyledTableCell = withStyles((theme) => ({}))(TableCell);

  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: '#F2F0F4',
      },
    },
  }))(TableRow);
  // ================= Table OD and EVEN Code ========//

  function createData(name, calories, fat, carbs, protein) {
    return { name, calories, fat, carbs, protein };
  }

  const { data, isLoading, error } = useGetInviteUserDetailsQuery(userId, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });
  const {
    data: rolesResponse,
    isLoading: isRolesLoading,
    isError: isRolesError,
    error: rolesError,
  } = useGetUserRolesQuery(undefined);

  const [deleteUser, deleteUserOptions] = useDeleteInviteUserMutation();
  const [updateUser, updateUserOptions] = useUpdateInviteUserMutation();
  const [resendInvitation, resendInvitationOptions] =
    useResendInvitationMutation();

  useProtected(error);
  useProtected(rolesError);
  useProtected(deleteUserOptions.error);
  useProtected(updateUserOptions.error);
  useProtected(resendInvitationOptions.error);
  const user = useSelector((state) => state.auth.user);

  // == Select Field ===//
  const [role, setRole] = useState('');
  const [userDetails, setUserDetails] = useState(null);
  const [roles, setRoles] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false)
  const [isThereChange, setIsThereChange] = useState(false)
  const [userTypeOptions, setUserTypeOptions] = useState([])
  const [userTypes, setUserTypes] = useState([])
  const [disabledTheButton, setDisabledTheButton] = useState(true)
  const handleRoleChange = (event) => {
    setIsThereChange(true)
    setRole(event.target.value);
  };

  useEffect(() => {
    if (isThereChange) {
      setDisabledTheButton(false)
    }
  }, [isThereChange])



  const convertRolesResponseToSelectItem = (locations) => {
    if (locations.length > 0) {
      return locations.map((item) => ({ label: toTitleCase(item?.name), value: item.name }));
    } else {
      return [];
    }
  };

  const handleDeleteUser = async () => {
    setShowDeleteModal(true)
  };

  useEffect(() => {
    if (!isLoading && !error) {
      setUserDetails(data.body.data);
      setRole(data.body.data?.userType);
    }
  }, [data, isLoading, error]);

  useEffect(() => {
    if (!isRolesLoading && !isRolesError) {
      setRoles(convertRolesResponseToSelectItem(rolesResponse.body.data));
    }
  }, [rolesResponse, isRolesLoading, isRolesError]);

  useEffect(() => {
    if (!userTypeIsLoading && !userTypeIsFetching) {
      setUserTypes(userTypeData?.body?.data)
    }
  }, [userTypeData, userTypeIsLoading, userTypeIsFetching])

  useEffect(() => {
    setUserTypeOptions(getPreparedArray(userTypes || [], 'name', 'name')) //label, value
  }, [userTypes])

  const getPreparedArray = (data, lab, val) => {
    return data?.map((item) => ({
      value: item[val],
      label: capitalizeFirstLetter(item[lab]),
    }));
  };


  const handleUpdateUser = async () => {
    const data = {
      userType: role,
    };
    const result = await updateUser({ ...data, id: userId });

    if (result?.data) {
      toast.success('User details updated successfully');
      setRole('');
      setUserDetails(null);
      closeDrawer();
    } else {
      toast.error(result?.data?.message);
    }
  };

  const handleResendInvitation = async () => {
    const data = {
      invitationId: userDetails?._id,
      invitedBy: user?._id,
    };
    const result = await resendInvitation(data);

    if (!result?.data?.error) {
      toast.success("User invitation resent successfully");
    } else {
      toast.error(result?.data?.message);
    }
  };

  const deleteUserHandler = async () => {
    const result = await deleteUser(userId);
    if (!result.data.error) {
      toast.success("User deleted successfully");
      setRole('');
      setUserDetails(null);
      closeDrawer();
    } else {
      toast.error(result.data.message);
    }
    console.log({ result });
  }
  return (
    <>
      <div className="InvitedUsers">
        {isLoading ||
          isRolesLoading ||
          deleteUserOptions.isLoading ||
          updateUserOptions.isLoading ? (
          <Loader
            height="80px"
            width="80px"
            borderWidth="8px"
            isFullWidth={true}
            isOverlay={true}
          />
        ) : (
          <>
            <div className="ProfilePhotoSection">
              <div className="ProfilePhoto">
                <Avatar sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }} src={userDetails?.userImage || ""} > {userDetails?.userImage ? "" : getProfileAvatarFromName(userDetails)} </Avatar>
              </div>
              <h1>
                {userDetails?.firstName} {userDetails?.lastName}
              </h1>
              <div className="UserStatus">{toTitleCase(userDetails?.status?.replace(/-/g, ' '))}</div>
            </div>

            <div className="ProfileTable">
              <div className="TableContainer oddEvenTable borderGray">
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableBody>
                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          User type
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          <div className="UserTypeSelect">
                            {/* <SelectField
                              selectedOption={role}
                              options={userTypeOptions}
                              handleChange={handleRoleChange}
                            /> */}
                            <p>{capitalizeWords(role)}</p>
                          </div>
                        </StyledTableCell>
                      </StyledTableRow>

                      <StyledTableRow>
                        <StyledTableCell align="left" className="TableLabel">
                          Email
                        </StyledTableCell>
                        <StyledTableCell align="left" className="TableInfo">
                          {userDetails?.email || 'N/A'}
                        </StyledTableCell>
                      </StyledTableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              {/* <div className="SaveButton">
                <Buttons
                  label="Save changes"
                  buttonColor="#FFB606"
                  border="#E4E8EC 1px solid"
                  color="#000"
                  width=""
                  height="48px"
                  fontSize="14px"
                  disabled={disabledTheButton}
                  link=""
                  onClick={(e) => { isThereChange && handleUpdateUser() }}
                />
              </div> */}

              <div className="DividerLine"></div>

              <Buttons
                label={
                  resendInvitationOptions.isLoading
                    ? 'Loading'
                    : 'Resend invitation'
                }
                buttonColor="#FFB606"
                border="#E4E8EC 1px solid"
                color="#000"
                width=""
                height="48px"
                fontSize="14px"
                link=""
                disabled={resendInvitationOptions.isLoading}
                isLoading
                icon={
                  resendInvitationOptions.isLoading ? (
                    <Loader height="20px" width="20px" />
                  ) : null
                }
                onClick={handleResendInvitation}
              />

              <div className="DeleteUserSection">
                <h2>Delete user</h2>
                <p>
                  Once a user is deleted they won't be able to access their
                  account and you won't be able to restore them.
                </p>

                <Buttons
                  label="Delete user"
                  buttonColor="#fff"
                  border="#FFB606 1px solid"
                  color="#000"
                  width=""
                  height="48px"
                  fontSize="14px"
                  link=""
                  onClick={handleDeleteUser}
                />
              </div>

              <div className="DividerLine"></div>

              <div className="TableTitle">Timeline</div>
              <div className="TableContainer oddEvenTable borderGray">
                <TableContainer component={Paper}>
                  <Table aria-label="customized table">
                    <TableBody>
                      {userDetails?.timeLine?.map((item) => (
                        <StyledTableRow>
                          <StyledTableCell align="left" className="TableLabel">
                            Invite sent
                          </StyledTableCell>
                          <StyledTableCell align="left" className="TableInfo">
                            {/* 7/31/2023, 10:00 AM */}
                            {moment(item).format('MM/DD/YYYY, h:mm A')}
                          </StyledTableCell>
                        </StyledTableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          </>
        )}
      </div>

      <DraggableDialog
        openDialog={showDeleteModal}
        handleCloseDialog={(e) => { setShowDeleteModal(false) }}
        handleConfirmCloseDialog={(e) => { deleteUserHandler() }}
        title="Delete user?"
        body={<div>Are you sure you want to delete this user?</div>}
        ModalFooter={true}
        actionButton="Remove"
      />
    </>
  );
};

export default InvitationDetails;
