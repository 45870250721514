export const monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
export const daysArray = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', '11', '12', '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23', '24', '25', '26', '27', '28', '29', '30', '31']
export const toTitleCase = (str) => {
    //only replace if str is defined
    if (str) {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    } else {
        return '';
    }
};

export const toLowerCase = (str) => {
    return str.toLowerCase();
};

export const toUpperCase = (str) => {
    return str.toUpperCase();
};


export const getProfileAvatarFromName = (user) => {
    return `${user?.firstName?.charAt(0)}${user?.lastName?.charAt(0)}`.toUpperCase();
}

export const filterTruthyValues = (obj) => {
    return Object.fromEntries(
        Object.entries(obj).filter(([_, value]) => value)
    );
}

export const mapMonth = (month) => {
    return monthArray.findIndex(item => item === month);
}