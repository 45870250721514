import { api } from '../../api/apiSlice';

const permissionApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getPermissionList: builder.query({
            query: () => ({
                url: '/permission-operations/all',
            }),
            providesTags: ['Permissions']
        })
    })
});

export const {
    useGetPermissionListQuery,
} = permissionApi;
