import React from 'react';

const LogoHeader = () => {
    return (
        <>
            <div style={{ width: '100%', marginBottom: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <img src="https://web-assets.deenschool.io/assets/logos/deen-school-logos-2x/deenschool-islamic-school-management-logo-cl.png" alt="DeenSchool Logo" width="200px" height="56px" />
            </div>
        </>
    );
};

export default LogoHeader;