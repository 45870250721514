import React, { useState } from 'react';
import Table from '../../../components/Table/Table';
import { Button } from '@mui/material';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import tableImg from '../../../assets/imgs/tableImg.png';
import CustomAccordion from '../../../components/CustomAccordion/CustomAccordion';
import AddGuardiansParents from './AddGuardiansParents';
import AddNewGuardiansParents from './AddNewGuardiansParents';
import GuardiansDetails from './GuardiansDetails';
import { useEffect } from 'react';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { toTitleCase } from '../../../utils/helpers';
import { validateFormData } from '../../../utils/form';
import { toast } from 'react-toastify';
import useProtected from '../../../hooks/useProtected';
import {
  useAddGuardianMutation,
  useFindGurdianMutation,
  useUpdateGuardianMutation,
} from '../../../redux/features/users/guardianApi';
import { useCreateInviteUserMutation, useGetGuardianListQuery, useGetInviteUserListQuery, useLazyGetUserDetailsQuery } from '../../../redux/features/users/userApi';
import { useSelector } from 'react-redux';

const Applications = ({ userDetails, guardianList, isLoading, userId, userUniqueID }) => {
  const { schoolId } = useSelector(state => state.auth.user);

  const [addGuardian, addGuardianOptions] = useAddGuardianMutation();
  const [getUsersData, { data }] = useLazyGetUserDetailsQuery();
  const [inviteUsers, inviteUsersOptions] = useCreateInviteUserMutation();
  const [updateGuardian, updateGuardianOptions] = useUpdateGuardianMutation();
  // ========== find gurdian =============================== //
  const [findGurdianData, findGurdianDataOptions] = useFindGurdianMutation()
  // ========== find gurdian =============================== //

  useProtected(inviteUsersOptions?.error);
  useProtected(addGuardianOptions.error);
  useProtected(updateGuardianOptions?.error);
  useProtected(findGurdianDataOptions?.error);

  const [innerList, setInnerList] = useState([]);
  const [AddDrawer, setAddDrawer] = useState(false);
  const [errors, setErrors] = useState({ userIdList: '' });
  const [DedtailsDrawer, setDedtailsDrawer] = useState(false);
  const [selectedUserList, setSelectedUserList] = useState([]);
  const [userDetailsStatus, setUserDetailsStatus] = useState('');
  const [userDetailsRelationship, setUserDetailsRelationship] = useState('');
  const [selectedGuardianForDetails, setSelectedGuardianForDetails] = useState('');
  const [guardianListDatas, setGuardianListDatas] = useState([])
  const [users, setUsers] = useState([])

  const handleOpenAdd = () => setAddDrawer(true);
  const handleCloseDrawer = () => {
    setSelectedGuardianForDetails('');
    setAddDrawer(false);
  };
  const handleOpenDedtails = (id) => {
    setSelectedGuardianForDetails(id);
    setDedtailsDrawer(true);
  };
  const handleCloseDrawerDedtails = () => {
    setSelectedGuardianForDetails('');
    setDedtailsDrawer(false);
  };



  //=============== invitation-pending parent =============//
  const [guardianListData, setGuardianListData] = useState([])
  const [invitationPendingParatentsData, setInvitationPendingParatentsData] = useState([])
  useEffect(() => {
    getGurdianByUserId(userUniqueID)
  }, [userUniqueID]);

  const getGurdianByUserId = async (userUniqueID) => {
    let findGurdianDatas = {
      "studentId": userUniqueID
    }
    const resultFindGurdianData = await findGurdianData(findGurdianDatas);
    setInvitationPendingParatentsData(resultFindGurdianData?.data?.body?.data);
  }

  useEffect(() => {
    let newGuardians = [...guardianListDatas]
    if (invitationPendingParatentsData) {
      setInnerList(convertDataToCellRows([...newGuardians, ...invitationPendingParatentsData]))
      // setGuardianListData([...newGuardians, ...invitationPendingParatentsData])
    }
  }, [invitationPendingParatentsData]);

  //=============== invitation-pending parent =============//

  //================== set data on the  table ===================================//
  useEffect(() => {
    if (userDetails) {
      setGuardianListDatas(userDetails?.gurdians)
      setInnerList(convertDataToCellRows(userDetails?.gurdians))
    }
  }, [userDetails])
  //================== set data on the  table ===================================//



  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '20%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      width: '18%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Relationship',
      width: '16%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'PREFERRED CONTACT',
      width: '18%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Status',
      width: '20%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '20%',
      textAlign: 'left',
    },
  ];

  const renderStatusColor = (status) => {
    switch (status) {

      case 'inactive':
        return { bg: '#FCE7E9', color: '#E63946' };
      case 'active':
        return { bg: '#EDF7EA', color: '#66BD50' };
      default:
        return { bg: '', color: '' };
      case 'invitation-pending':
        return { bg: '#EDEDEF', color: '#676C7B' };
    }
  };

  const convertDataToCellRows = (arr) => {

    if (arr.length > 0) {
      return arr.map((item) => ({
        cell: [
          { textBold: item.name || `${item.firstName} ${item.lastName}` },
          { text: (item?.userUniqueID || parseInt(item?.schoolId)) || 'N/A' },
          { text: item?.relationShip ? toTitleCase(item?.relationShip) : 'N/A' },
          { text: item?.email || 'N/A' },
          {
            Status: true,
            background: renderStatusColor(item.status).bg,
            color: renderStatusColor(item.status).color,
            label: item?.status ? toTitleCase(item?.status?.replace(/-/g, ' ')) : 'N/A',
          },
          {
            Button: true,
            buttonLabel: 'Details',
            buttonTextcolor: '#004FE0',
            onClick: (e) => handleOpenDedtails(item?.userUniqueID),
          },
        ],
      }));
    } else {
      return [];
    }
  };






  const tabData = [
    {
      title: 'Existing user',
    },

    {
      title: 'New',
    },
  ];

  const [usersData, setUsersData] = useState([
    { email: '', firstName: '', lastName: '', emailError: '', userType: "parent" },
  ]);

  const isDataValidated = (data) => {
    for (const item of data.users) {
      for (const [key, val] of Object.entries(item)) {
        if (key === 'email' && !val) return false;
      }
    }
    return true;
  };

  const handleInviteUsers = async () => {
    try {
      const data = {
        schoolId,
        gurdianOf: userUniqueID,
        users: [...usersData],
      };
      let foundError = false;
      let foundErrorMessage = ""
      const isValid = isDataValidated(data);
      usersData?.map((user) => {
        if (user.emailError != "" && !foundError) {
          foundError = true
          foundErrorMessage = user.emailError
        }
      })

      if (!isValid || foundError) {
        (!isValid) && toast.error('Please fill the required field')
        foundError && toast.error(foundErrorMessage)

        return;
      }

      const result = await inviteUsers(data);

      if (!result?.data?.error) {
        toast.success(result.data.message);
        handleCloseDrawer();
        setUsersData([{ email: '', firstName: '', lastName: '', role: '' }]);
      } else {
        toast.error(result.data.message);
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const handleAddGuardian = async () => {
    let data = {
      selectedUserList,
    };

    const isValid = validateFormData(data, setErrors, {
      userIdList: 'At least one user required',
    });

    if (!isValid) {
      return;
    }

    const result = await addGuardian({
      id: userId,
      users: [...selectedUserList],
    });

    if (result?.data) {
      toast.success('Guardians/Parents added successfully');
      setSelectedUserList([]);
      handleCloseDrawer();
      // getGurdianByUserId(userUniqueID)
      // getUsersDatas()
    } else {
      toast.error(result?.data?.message);
    }
  };

  const panelTab = [
    {
      id: 0,
      panleBody: (
        <AddGuardiansParents
          isLoading={addGuardianOptions?.isLoading}
          setSelectedUserList={setSelectedUserList}
          selectedUserList={selectedUserList}
          closeDrawer={handleCloseDrawer}
          guardianListDatas={guardianListDatas}
        />
      ),

      SaveButtonLabel: 'Add now',
      clickSaveButton: handleAddGuardian,
      CancelButtonLabel: 'Cancel',
    },

    {
      id: 1,
      panleBody: (
        <AddNewGuardiansParents
          setUsersData={setUsersData}
          usersData={usersData}
          handleAddInviteUser={handleInviteUsers}
          isLoading={inviteUsersOptions?.isLoading}
        />
      ),
      SaveButtonLabel: 'Send Invitation',
      clickSaveButton: handleInviteUsers,
      CancelButtonLabel: 'Cancel',
    },
  ];

  const handleUpdateGuardian = async () => {
    const data = {
      status: userDetailsStatus,
      relationShip: userDetailsRelationship,
      userUniqueID: selectedGuardianForDetails
    };
    const result = await updateGuardian({ ...data, id: userId });

    if (result?.data) {
      toast.success('Guardians/Parents details updated successfully');
      setUserDetailsStatus('');
      setUserDetailsRelationship('');
      handleCloseDrawerDedtails();
      // getUsersDatas()
    } else {
      toast.error(result?.data?.message);
    }
  };

  useEffect(() => {
    console.log(userDetailsStatus)
  }, [userDetailsStatus])

  return (
    <>
      <div className="TableSection">
        <Table
          innerTitle="Guardians/Parents"
          headCells={headCells}
          rows={innerList}
          footer={true}
          innerHeader={true}
          isDataLoading={isLoading}
          AddButtonInner={true}
          addLabelInner="Add guardian/Parent"
          InnerButtonColor="#0450E1"
          InnerButtonTextColor="#fff"
          InnderButtonIcon={<AddRoundedIcon />}
          onClickInnerButton={handleOpenAdd}
          emptyTableTitle="No guardians/parents added yet"
          emptyTableNote="Add guardians/parents will be visible here"
          btnLabel="Add guardian/parent"
          showButton={true}
          onClickEmptyCard={handleOpenAdd}

        />
      </div>

      {/* Add Guardians/Parents */}
      <SideDrawer
        open={AddDrawer}
        handleDrawerClose={handleCloseDrawer}
        title="Add Guardians/Parents"
        buttonLabel="Add now"
        // buttonLink={}
        tabData={tabData}
        panelTab={panelTab}
        CheckBoxTabsStatus={true}
        TabsPannelStatus={true}
        sideOpen="right"
        cancelButtonLabel="Cancel"
        Checkbox={false}
        CheckboxLabel="Add another after saving"

      />

      {/* Details Guardians/Parents */}
      <SideDrawer
        open={DedtailsDrawer}
        handleDrawerClose={handleCloseDrawerDedtails}
        title="Guardians/Parents Details"
        buttonLabel="Update"
        // buttonLink={}
        sideOpen="right"
        ButtonLabel="Save"
        cancelButtonLabel="CANCEL"
        FooterWithoutTab={true}
        clickSaveButton={handleUpdateGuardian}

        body={
          <GuardiansDetails
            // getUsersDatas={getUsersDatas}
            updateGuardianOptions={updateGuardianOptions}
            userDetailsStatus={userDetailsStatus}
            setUserDetailsStatus={setUserDetailsStatus}
            userDetailsRelationship={userDetailsRelationship}
            setUserDetailsRelationship={setUserDetailsRelationship}
            closeDrawer={handleCloseDrawerDedtails}
            guardianUniqueId={selectedGuardianForDetails}
            userId={userId}
          />
        }
      />
    </>
  );
};

export default Applications;
