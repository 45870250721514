import React, { useState, useEffect } from "react";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import EditAbleTableCell from './EditAbleTableCell';
import Button from '@mui/material/Button';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Buttons from '../Form/Button/Button';

import './EditAbleTable.css';

function EditAbleTable(props) {

  const { rows, headCells, SubmitButtonLabel, SubmitButtonWidth, SubmitOnClick, handleAddNew, handleremove, usersData } = props;



  const [inputList, setinputList] = useState([{ firstName: '', lastName: '' }]);

  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    const list = [...inputList];
    list[index][name] = value;
    setinputList(list);

  }

  // const handleremove = index => {
  //   const list = [...inputList];
  //   list.splice(index, 1);
  //   setinputList(list);
  // }

  // const handleaddclick = () => {
  //   setinputList([...inputList, { firstName: '', lastName: '' }]);
  // }



  const [isTouchedAtBottom, setIsTouchedAtBottom] = useState(false);

  useEffect(() => {
    const handleTouchEnd = (e) => {
      const windowHeight = window.innerHeight;
      const touchY = e.touches[0].clientY;

      if (touchY >= windowHeight - 50) {
        setIsTouchedAtBottom(true);
      } else {
        setIsTouchedAtBottom(false);
      }
    };

    window.addEventListener('touchend', handleTouchEnd);

    return () => {
      window.removeEventListener('touchend', handleTouchEnd);
    };
  }, []);

  const divClassName = isTouchedAtBottom ? 'touched' : '';
  // remove button
  const emailData = usersData?.find((item) => item?.email)

  return (
    <div className="EditAbleTable">
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              {headCells.map((item, i) => (
                <TableCell style={{ minWidth: item.width, width: item.width, maxWidth: item.width, }}>{item.label}</TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>

            {
              inputList?.map((x, i) => {
                return (
                  <>
                    {
                      rows?.map((row, index) => (
                        <TableRow
                          key={row.name}
                          sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                          {/* <EditAbleTableCell
                            cell={row.cell}
                            RemoveButton={
                              index !== 0 &&
                              < div className="EditAbleTableRemoveCell">
                                <Button onClick={() => handleremove(index)} className="EditAbleTableRemove">Remove</Button>
                              </div>
                            }
                          /> */}

                          <EditAbleTableCell
                            cell={row.cell}
                            RemoveButton={
                              (usersData.length > 1 && emailData != '') &&
                              < div className="EditAbleTableRemoveCell">
                                <Button onClick={() => handleremove(index)} className="EditAbleTableRemove">Remove</Button>
                              </div>
                            }
                          />
                        </TableRow>
                      ))
                    }
                    {rows.length > 0 &&
                      <div className={divClassName}>
                        <div className="EditableTableFooter">

                          <Button onClick={handleAddNew} className="EditAbleTableAddMore"><AddRoundedIcon /> ADD MORE </Button>
                          <Buttons
                            label={SubmitButtonLabel}
                            buttonColor="#0450E1"
                            border="#0450E1 1px solid"
                            color="#fff"
                            width={SubmitButtonWidth}
                            height="32px"
                            fontSize="14px"
                            onClick={SubmitOnClick}
                          />
                        </div>
                      </div>

                    }
                  </>

                );
              })}
          </TableBody>
        </Table>
      </TableContainer>



    </div>
  );
}

export default EditAbleTable;
