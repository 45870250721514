import React, { useState } from 'react'
import DeleteModal from '../DeleteModal/DeleteModal'
import "./Test.css"
import { Button } from '@mui/material'
import { toast } from 'react-toastify'

const SimpleOddTable = () => {

    let DeletedConfirmWord = "CONFIRM"
    const [openModal, setOpenModal] = useState(false)
    const [textValue, setTextValue] = React.useState("")
    const [error, setError] = React.useState(false);
    const handleOpen = () => setOpenModal(true)
    const handleClose = () => setOpenModal(false);

    const handleConfirm = (textValue) => {
        if (textValue === DeletedConfirmWord) {
            setError(false)
            setOpenModal(false)
            setTextValue("")
            toast.success("Deleted successfully")
        } else {
            setError(true)
        }
    }

    return (
        <div>
            <h1 className='flexCentered'>Test page</h1>
            <div className='flexCentered'>
                <Button onClick={handleOpen}>Open modal</Button>
                <DeleteModal
                    DeletedConfirmWord={DeletedConfirmWord}
                    openModal={openModal}
                    setOpenModal={setOpenModal}
                    handleOpen={handleOpen}
                    handleClose={handleClose}
                    textValue={textValue}
                    error={error}
                    setTextValue={setTextValue}
                    handleConfirm={handleConfirm}
                />
            </div>
        </div>
    )
}

export default SimpleOddTable