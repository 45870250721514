import './CheckPayment.css';

const CheckPayment = () => {
  return (
    <div>
      <b>Payment Info</b>
      <div className="tableContainer">
        <li>
          <div className="listItemOne">
            <p>Check payable to</p>
          </div>
          <div className="listItemTwo">
            <h4>
              <b>DEENSCHOOL</b>
            </h4>
          </div>
        </li>
        <li>
          <div className="listItemOne">
            <p>Mail the check to</p>
          </div>
          <div className="listItemTwo">
            <h4>
              <b>DEENSCHOOL</b>
            </h4>
            <p>1033 Glenmore Ave, 4th Floor</p>
            <p>Brooklyn, NY 11208</p>
            <p>United States</p>
          </div>
        </li>
      </div>
    </div>
  );
};

export default CheckPayment;
