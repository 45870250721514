import React, { useState, useEffect } from "react";
import Webcam from "react-webcam";
import { Button } from "@material-ui/core";

const WebcamComponent = ({ width, height, setImageSrc, onCaptured }) => {
    const [webcamRef, setWebcamRef] = useState(null);
    const [cameraPermissionGranted, setCameraPermissionGranted] = useState(false);
    const [devices, setDevices] = useState([]);
    const [selectedDeviceId, setSelectedDeviceId] = useState(null);

    useEffect(() => {
        // Request camera permission
        navigator.mediaDevices
            .getUserMedia({ video: true })
            .then((stream) => {
                setCameraPermissionGranted(true);

                // Enumerate and set available devices
                navigator.mediaDevices.enumerateDevices().then((mediaDevices) => {
                    const videoDevices = mediaDevices.filter(
                        (device) => device.kind === "videoinput"
                    );
                    setDevices(videoDevices);

                    // Select the first available device by default
                    if (videoDevices.length > 0) {
                        setSelectedDeviceId(videoDevices[0].deviceId);
                    }
                });
            })
            .catch((error) => {
                console.error("Error accessing camera:", error);
                setCameraPermissionGranted(false);
            });
    }, []);

    const handleDeviceChange = (event) => {
        setSelectedDeviceId(event.target.value);
    };

    return (
        <div>
            {!cameraPermissionGranted && (
                <p>Please grant camera permission to use this feature.</p>
            )}

            {cameraPermissionGranted && (
                <div>
                    <select onChange={handleDeviceChange} value={selectedDeviceId}>
                        {devices.map((device) => (
                            <option key={device.deviceId} value={device.deviceId}>
                                {device.label || `Device ${device.deviceId}`}
                            </option>
                        ))}
                    </select>

                    <Webcam
                        audio={false}
                        height={height}
                        width={width}
                        videoConstraints={{
                            deviceId: selectedDeviceId,
                        }}
                        ref={(webcam) => setWebcamRef(webcam)}
                    >
                        {({ getScreenshot }) => (
                            <Button
                                variant="outlined"
                                color="inherit"
                                onClick={() => {
                                    const imageSrc = getScreenshot();
                                    setImageSrc(imageSrc);
                                    if (imageSrc) {
                                        onCaptured();
                                    }
                                }}
                            >
                                Take photo
                            </Button>
                        )}
                    </Webcam>
                </div>
            )}
        </div>
    );
};

export default WebcamComponent;
