import { Button, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import TextField from '../../../components/Form/TextField/TextField'
import Buttons from '../../../components/Form/Button/Button'
import { useSelector } from 'react-redux'
import axiosApi from '../../../utils/axios'
import { useState } from 'react'
import { toast } from 'react-toastify'

const EnteryourPassword = (props) => {
    const user = useSelector(state => state.auth.user);
    const [password, setPassword] = useState('')

    const checkUser = async () => {
        ///===================== recapcha ==============================///
        window.grecaptcha.ready(async () => {
            try {
                const token = await window.grecaptcha.execute(`${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`, { action: 'submit' });
                ///===================== recapcha ==============================///
                let data = {
                    email: user.email,
                    password: password,
                    token
                }
                try {
                    const result = await axiosApi.post('/users/public/login', data);
                    if (result?.data?.error) {
                        toast.error('Password is not correct')
                        props.cancleButtonOperation()
                        return;
                    }
                    toast.success('Information was correct')
                    props.cancleButtonOperation()
                    props.setShowFullBirthDay(true)

                } catch (err) {
                    console.log(err);

                }

            } catch (error) {
                console.error('Error:', error);
            }
        });
    }
    return (
        <>
            <Grid container justifyContent="center" spacing={3}>
                <Grid item xs={12}>
                    <Typography variant='h4' sx={{
                        textAlign: "center !important",
                        fontSize: "24px !important",
                        fontWeight: "600 !important"
                    }}>Enter your password</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant='h4' sx={{
                        textAlign: "center !important",
                        fontSize: "14px !important",
                        fontWeight: "400 !important"
                    }}>To access certain information, please confirm your password.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label=""
                        placeholder="Confirm password"
                        validation={false}
                        CharAlignMid="CharAlignMid"
                        Uppercase="Uppercase"
                        // count={6}
                        value={password}
                        handleChange={(val) => setPassword(val)}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Buttons
                        label="Continue"
                        buttonColor="#0450E1"
                        // border="#E4E8EC 1px solid"
                        color="#fff"
                        width="100%"
                        height="60px"
                        fontSize="14px"
                        // link=""
                        onClick={(e) => checkUser()}
                    />
                    <Buttons
                        label="cancel"
                        buttonColor="transparent"
                        // border="#E4E8EC 1px solid"
                        color="#0450E1"
                        width="100%"
                        height="60px"
                        fontSize="14px"
                        // link=""
                        onClick={() => props.cancleButtonOperation()}
                    />
                </Grid>
            </Grid >
        </>
    )
}

export default EnteryourPassword