import React, { useEffect, useState } from 'react';
import CustomAccordion from '../CustomAccordion/CustomAccordion';
import OrderDetails from './OrderDetails/OrderDetails';
import Users from './Users/Users';
import ShippingDetails from './ShippingDetails/ShippingDetails';
import Payment from './Payment/Payment';
import { useGetOrderIdDetailsQuery } from '../../redux/features/order/orderApi';
import useProtected from '../../hooks/useProtected';
import Loader from '../Loader/Loader';

const OrderIdDrawer = ({ closeDrawer, orderId }) => {
  const { data, isLoading, error } = useGetOrderIdDetailsQuery(orderId, {
    refetchOnMountOrArgChange: true,
  });
  useProtected(error);

  const [orderDetails, setOrderDetails] = useState(null);
  const [users, setUsers] = useState(null);
  const [shippingDetails, setShippingDetails] = useState(null);
  const [paymentDetails, setPaymentDetails] = useState(null);
  const [billingDetails, setBillingDetails] = useState(null);

  useEffect(() => {
    if (!isLoading && !error) {
      const details = data.body.data;
      setOrderDetails({
        orderName: details?.orderName || '',
        quantity: details?.quantity,
        _id: details?._id || '',
        isDetailsUpdated: details?.isDetailsUpdated
      });
      setUsers({
        _id: details?._id,
        quantity: details?.quantity,
        selectedUsers: details?.selectedUsers,
        selectedGroups: details?.selectedGroups,
        isUserUpdated: details?.isUserUpdated
      });
      setShippingDetails(
        details?.shipTo
          ? { ...details.shipTo, _id: details?._id, isShipToUpdated: details?.isShipToUpdated }
          : { _id: details?._id },
      );
      setPaymentDetails(
        details?.payment
          ? { ...details.payment, _id: details?._id, }
          : { _id: details?._id },
      );
      setBillingDetails(
        details?.billTo
          ? { ...details.billTo, _id: details?._id }
          : { _id: details?._id },
      );
    }
  }, [data, isLoading, error]);

  return (
    <>
      {
        isLoading ? (
          <Loader
            height="80px"
            width="80px"
            borderWidth="8px"
            isFullWidth={true}
            isOverlay={true}
          />
        ) : (
          <>
            <CustomAccordion
              id={3}
              ModalLabel="Users"
              ModalBody={<Users userDetails={users} />}
              isCompleted={users?.isUserUpdated}
            />
            <CustomAccordion
              id={3}
              ModalLabel="Shipping details"
              ModalBody={<ShippingDetails shippingDetails={shippingDetails} />}
              disabled={!users?.isUserUpdated}
              isCompleted={shippingDetails?.isShipToUpdated}
            />
            <CustomAccordion
              id={3}
              ModalLabel="Order details"
              ModalBody={<OrderDetails orderDetails={orderDetails} />}
              disabled={!shippingDetails?.isShipToUpdated}
              isCompleted={orderDetails?.isDetailsUpdated}
            />
            <CustomAccordion
              id={3}
              ModalLabel="Payment"
              disabled={!orderDetails?.isDetailsUpdated}
              ModalBody={
                <Payment paymentDetails={paymentDetails} closeDrawer={closeDrawer} />
              }
            />

          </>
        )
      }
    </>
  );
};

export default OrderIdDrawer;
