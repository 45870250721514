import React, { useEffect, useState } from "react";
import Table from '../../../components/Table/Table';
import TableSubHeader from '../../../components/Table/TableSubHeader';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import tableImg from '../../../assets/imgs/User.png';
import InfoIcon from '@material-ui/icons/Info';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import IdCard from './IdCard/IdCard';
import QrCode from "./IdCard/qr-deenfund.png";
import UserPhoto from './IdCard/user-photo.jpeg';
import Button from '@material-ui/core/Button';
import ErrorIcon from '@material-ui/icons/Error';
import Grid from '@material-ui/core/Grid';
import SelectField from "../../../components/Form/SelectField/SelectField";
import Buttons from "../../../components/Form/Button/Button";

import Modals from "../../../components/hooks/Dialog";

const Item = () => {


  //=======  Drawer Start ===== //
  const [openDrawer, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  //=======  Drawer End ===== //

  //=======  Change Order Modal ===== //
  const [openChangeOrderModal, setOpenChangeOrderModal] = useState(false);
  const handleOpenChangeOrderModal = () => {
    setOpenChangeOrderModal(true);
  };
  const handleCloseChangeOrderModal = () => {
    setOpenChangeOrderModal(false)
  };
  //=======  Change Order Modal  ===== //

  const onClick = () => {
    alert("onclick working fine!");
  }

  const headCells = [

    { id: 'name', numeric: true, disablePadding: false, label: 'Name', width: "20%" },
    { id: 'id', numeric: true, disablePadding: false, label: 'ID', width: "12%" },
    { id: 'price', numeric: true, disablePadding: false, label: 'User type', width: "12%", sort: "sort", },
    { id: 'price', numeric: true, disablePadding: false, label: 'QR', width: "10%" },
    { id: 'price', numeric: true, disablePadding: false, label: 'ID Photo', width: "20%" },
    { id: 'status', numeric: true, disablePadding: false, label: 'Status', width: "15%", align: "right", sort: "sort", },
    { id: '', numeric: true, disablePadding: false, label: '', width: "10%", },

  ];

  const rows = [
    {
      cell: [
        { Check: true, width: "48px", padding: "0", checked: true, },
        { linkText: 'Qadhi  Ashraful', linkTextStatus: true, link: '#', },
        { text: "0111051", },
        { text: "Student", },
        { StatusIcon: <CheckCircleIcon />, Icon: true, IconColor: "#41AB37" },
        { img: tableImg, imgStatus: true, text: 'File name as admin uploa', align: 'left' },
        { Status: true, background: '#FFF5E6', color: '#EEB000', linebackground: "#EEB000", statusLine: true, label: 'Pending', },
        { ActionButtonDotIcon: true, },

      ],
      //======= Action Date Start===== //
      action: [
        { label: "Details", onClick: handleDrawerOpen, },
        { label: "Change Status", onClick: handleOpenChangeOrderModal },
      ]
      //======= Action Date End===== //
    },

    {
      cell: [
        { Check: true, width: "48px", padding: "0", checked: true, },
        { linkText: 'Shayan Mahmud', linkTextStatus: true, link: '#', },
        { text: "0111051", },
        { text: "Faculty", },
        { StatusIcon: <span style={{ display: "flex", marginRight: "4px", }}><ErrorIcon /></span>, Icon: true, IconColor: "#FFB606", date: 'Missing', },
        { StatusIcon: <span style={{ display: "flex", marginRight: "4px", }}><ErrorIcon /></span>, Icon: true, IconColor: "#FFB606", date: 'Missing', },
        { Status: true, background: '#FCE7E9', color: '#E63946', linebackground: "#BA1A1A", statusLine: true, label: 'Canceled', },
        { ActionButtonDotIcon: true, },

      ],
      //======= Action Date Start===== //
      action: [
        { label: "Details", onClick: handleDrawerOpen, },
        { label: "Change Status", onClick: handleOpenChangeOrderModal },
      ]
      //======= Action Date End===== //
    },


    {
      cell: [
        { Check: true, width: "48px", padding: "0", checked: true, },
        { linkText: 'Shayan Mahmud', linkTextStatus: true, link: '#', },
        { text: "0111051", },
        { text: "Staff", },
        { StatusIcon: <CheckCircleIcon />, Icon: true, IconColor: "#41AB37" },
        { img: tableImg, imgStatus: true, text: 'File name as admin uploa', align: 'left' },
        { Status: true, background: '#EDF7EA', color: '#41AB37', linebackground: "#41AB37", statusLine: true, label: 'Completed', },
        { ActionButtonDotIcon: true, },

      ],
      //======= Action Date Start===== //
      action: [
        { label: "Details", onClick: handleDrawerOpen, },
        { label: "Change Status", onClick: handleOpenChangeOrderModal },
      ]
      //======= Action Date End===== //
    },




  ];







  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "Pending",
      value: "Pending",
    },

    {
      label: "Completed",
      value: "Completed",
    },
    {
      label: "Canceled",
      value: "Canceled",
    },

  ]


  return (
    <>

      <div className="TableSection">

        <TableSubHeader
          TableTitle="Items"
          //== Search Code == //
          Search={true}
          SearchPlaceholder="Search..."
        //SearchValue={searchTerm}
        // SearchOnChange={handleSearchText}
        //SearchOnclick={SearchOnclick}
        //SearchOnMouseDown={SearchOnMouseDown}
        // handleSearch={handleSearch}
        />


        <Table
          headCells={headCells}
          rows={rows}
          AddButton={true}
          footer={true}
          CheckboxDelete={true}
        />
      </div>

      {/* Modal Change Order Status Start*/}
      <Modals
        openDialog={openChangeOrderModal}
        handleCloseDialog={handleCloseChangeOrderModal}
        title="Change Order Status"
        textAlign="center"
        body={
          <div>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                < SelectField
                  label="Select Status"
                  FieldLabel={true}
                  options={options}
                  handleChange={handleChanges}
                />
              </Grid>

              <Grid item xs={12}>
                <Buttons
                  label="Save"
                  buttonColor="#0450E1"
                  border="#0450E1 1px solid"
                  color="#fff"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  //onClick={ }
                />
              </Grid>

              <Grid item xs={12}>
                <Buttons
                  label="Cancel"
                  buttonColor="#fff"
                  border="#fff 1px solid"
                  color="#0450E1"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  onClick={handleCloseChangeOrderModal}
                />
              </Grid>



            </Grid>
          </div>
        }
      />
      {/* Modal Change Order Status End*/}



      {/* Drawer Start*/}
      <SideDrawer
        title="ID Details"
        ButtonLabel="Download"
        cancelButtonLabel="Cancel"
        DeleteButton={true}
        sideOpen="right"
        open={openDrawer}
        handleDrawerClose={handleDrawerClose}
        FooterWithoutTab={true}
        body={
          <>
            <div>
              <div className="IdCard">
                <IdCard
                  UserPhoto={UserPhoto}
                  FirstName="Mohammad"
                  LastName="Arif"
                  Designation="Teacher"
                  OrgNameStatus={true}
                  OrgName="MIDDLE SCHOOL CLASS OF ‘26"
                  IdNumber="10151008"
                  QrCode={QrCode}
                  StatusColor="#64BC08"

                />
              </div>
            </div>
            {/* <div className="DownloadButtonContainer">
              <Button className="CardDownloadButton">Download</Button>
            </div> */}
          </>
        }
      />
      {/* Drawer End*/}

    </>

  );
};


export default Item;



