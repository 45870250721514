import React, { useEffect, useState } from 'react';
import Table from '../../../components/Table/Table';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import InvitationDetails from './InvitationDetails';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
  useGetInviteUserListQuery,
  useGetInviteUsersSearchResultMutation,
} from '../../../redux/features/users/userApi';
import useProtected from '../../../hooks/useProtected';
import { toTitleCase } from '../../../utils/helpers';
import SelectUsers from './SelectUsers';
import { useNavigate } from 'react-router-dom';
import { ImportUsers } from './ImportUsers';


const InvitedUsers = () => {
  const [InviteDetailsDrawer, setCreateInviteDetails] = useState(false);
  const { data, isError, isLoading, error } = useGetInviteUserListQuery(
    undefined,
    { refetchOnMountOrArgChange: true },
  );
  useProtected(error);
  const [getSearchedUserList, options] =
    useGetInviteUsersSearchResultMutation();
  const handleOpenDrawerInviteDetails = () => setCreateInviteDetails(true);
  const handleCloseInviteDetails = () => setCreateInviteDetails(false);
  const [rows, setRows] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedUser, setSelectedUser] = useState('');
  const [users, setUsers] = useState([])
  const [asc, setAsc] = useState(true)
  const navigate = useNavigate()


  // function createData(name, type, email, status, protein) {
  //   return {
  //     name,
  //     type,
  //     email,
  //     status,
  //   };
  // }

  const ascendingOrDescendingSorting = (field) => {
    let sortedUsers = [...users]
    if (asc) {
      sortedUsers.sort((a, b) => a[field].localeCompare(b[field]));
    } else {
      sortedUsers.sort((a, b) => b[field].localeCompare(a[field]));
    }
    setRows(convertDataToCellRows(sortedUsers));
    setAsc(!asc)
  }


  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '30%',
      textAlign: 'left',
    },
    {
      id: 'type',
      numeric: false,
      disablePadding: true,
      label: 'User Type',
      width: '18%',
      textAlign: 'left',
      // headerOnClick: () => ascendingOrDescendingSorting('role'),
      // sort: "sort",
    },
    {
      id: 'email',
      numeric: false,
      disablePadding: true,
      label: 'Email',
      width: '25%',
      textAlign: 'left',
    },
    {
      id: 'status',
      numeric: false,
      disablePadding: true,
      label: 'Status',
      width: '20%',
      textAlign: 'left',
      headerOnClick: () => ascendingOrDescendingSorting('status'),
      sort: "sort",
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '20%',
      textAlign: 'left',
    },
  ];

  const handleDetailsClick = (id) => {
    setSelectedUser(id);
    setCreateInviteDetails(true);
  };

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => ({
        cell: [
          {
            text: `${item.firstName} ${item.lastName}`
          },
          { text: item?.role ? toTitleCase(item.role) : 'N/A' },
          { text: item?.email || 'N/A' },
          {
            Status: true,
            background: item?.status === 'accepted' ? '#EDF7EA' : '#EDEDEF',
            color: item?.status === 'accepted' ? '#66BD50' : '#676C7B',
            label: item?.status ? toTitleCase(item?.status?.replace(/-/g, ' ')) : 'N/A',
          },
          {
            Button: item.status == 'accepted' ? false : true,
            buttonLabel: 'Details',
            textTransform: 'initial',
            buttonTextcolor: '#004FE0',
            onClick: () => handleDetailsClick(item._id),
          },
        ],
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
      setUsers(data.body.data)
    }
  }, [data]);

  // const tabData = [
  //   {
  //     title: "Users",
  //   },
  //   {
  //     title: "Visitors (comin soon)",
  //   },
  // ];

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    const result = await getSearchedUserList({ term: searchTerm });
    console.log(result);
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
  };

  const handleNavigateToTheNewUserPage = () => {
    navigate("/invite-new-users")
  }

  // * =========== Import users =========== * //
  const [importUserDrawer, setImportUserDrawer] = useState(false);

  return (
    <>
      <div className="TableSection">
        <Table
          title="Invite Users"
          tableHeader={true}
          AddButton={true}
          addLabel="New User"
          AddButtonOutLine={true}
          OutLineButtonLabel="Import users"
          onClickOutlineButton={() => setImportUserDrawer(true)}
          headCells={headCells}
          rows={rows}
          onClick={handleNavigateToTheNewUserPage}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          footer={true}
          innerHeader={true}
          showSearchBox={true}
          FilterButton={false}
          icon={<AddRoundedIcon />}
          searchBoxPlaceholder="Search"
          // link="/invite-new-users"
          isDataLoading={isLoading || options?.isLoading}
          emptyTableTitle="No users added yet"
          emptyTableNote="No items available"
          btnLabel="New User"
          showButton={true}
          // btnLink="/invite-new-users"
          RowsPerPage={15}
        />
      </div>

      {/* new user drawer */}
      <SideDrawer
        open={InviteDetailsDrawer}
        handleDrawerClose={handleCloseInviteDetails}
        title="Invitation details"
        sideOpen="right"
        FooterWithoutTab={false}
        body={
          <>
            <InvitationDetails
              closeDrawer={handleCloseInviteDetails}
              userId={selectedUser}
            />
          </>
        }
      />
      {/* import user drawer */}
      <SideDrawer
        open={importUserDrawer}
        handleDrawerClose={() => setImportUserDrawer(false)}
        title="Import Users"
        sideOpen="right"
        FooterWithoutTab={false}
        body={
          <>
            <ImportUsers />
          </>
        }
      />
    </>
  );
};

export default InvitedUsers;
