import React from "react";
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Checkbox from '@mui/material/Checkbox';
import './MultipleSelet.css';
import { FormHelperText } from "@mui/material";


function MultipleSelet(props) {

  const { option, handleChange, value, setvalue } = props;

  // const [value, setvalue] = React.useState([]);

  // const handleChange = (event) => {
  //   const {
  //     target: { value },
  //   } = event;
  //   setvalue(
  //     // On autofill we get a stringified value.
  //     typeof value === 'string' ? value.split(',') : value,
  //   );
  // };


  return (
    <div className="MultipleSelet">
      {props.FieldLabel &&
        <div className="FieldLabel">{props.label} {props.validation && <span>*</span>}</div>
      }
      <Select
        multiple
        displayEmpty
        value={value}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>{(!props.Placeholder || props.Placeholder === "") ? "Select" : props.Placeholder}</em>;
          }

          return selected.join(', ');
        }}
        inputProps={{ 'aria-label': 'Without label' }}
        error={props.error}
      >
        <MenuItem disabled value="">
        </MenuItem>
        {option.map((name) => (
          <MenuItem
            key={name}
            value={name}
          >
            <Checkbox checked={value.indexOf(name) > -1} />
            {name}
          </MenuItem>
        ))}
      </Select>
      {(props.errorHelperText) && (
        <FormHelperText sx={{ color: '#ba1b1a' }} >Required</FormHelperText>
      )}
    </div>
  );
}


export default MultipleSelet;

