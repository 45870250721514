import React, { useEffect, useState } from 'react';
import '../../../assets/css/profile.css';
import Grid from '@material-ui/core/Grid';
import Avatar from '@mui/material/Avatar';
import img from '../../../assets/imgs/User.png';
import { deepOrange, deepPurple, } from '@mui/material/colors';
import FileUplaoder from '../../../components/Form/FileUplaoder/FileUplaoder'
import GooglePlaceField from '../../../components/Form/GooglePlaceField/GooglePlaceField';
import TextField from '../../../components/Form/TextField/TextField';
import CountrySelectField from '../../../components/Form/CountrySelectField/CountrySelectField';
import PhoneInputField from '../../../components/Form/PhoneInputField/PhoneInputField';
import SelectField from '../../../components/Form/SelectField/SelectField';
import Buttons from '../../../components/Form/Button/Button';


import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';

const ParentProfile = (props) => {
  const { personalInfo, setPersonalInfo, gradeLevels, ageGroups, timeZones, countries, highestEducation } = props;

  const [phone, setPhone] = React.useState({
    country: "",
    number: "",
  });



  const [age, setAge] = React.useState('');
  const handleChanges = (event) => {
    setAge(event.target.value);
  };


  const options = [
    {
      label: "Twenty",
      value: "Twenty",
    },
  ]

  const month = [
    {
      label: "Month",
      value: "Month",
    },
  ]

  const day = [
    {
      label: "Day",
      value: "Day",
    },
  ]

  const years = [
    {
      label: "Year",
      value: "Year",
    },
  ]




  return (
    <>
      <div className="ProfileFormContainer">

        <div className="ProfileFormHeader">
          <h3>Complete guardian/parent profile</h3>
          <p>This information will be shared with the school</p>
        </div>

        <div className="ProfileForm">
          <div className="ModalForm">
            <Grid container spacing={3}>
              <Grid item xs={12} sm={9} md={12}>

                <div className="UpdateUseravatar">

                  <div className="UserDetailsrIcon EditPhoto">
                    {/* <div className="FieldLabel">Profile Photo</div> */}
                    <div className="EditPhotoAvatar">
                      <Avatar
                        sx={{ bgcolor: deepPurple[300], width: 96, height: 96 }}
                        src={img} ></Avatar>
                    </div>
                  </div>
                  <div className="UplaodPhotoModal">
                    <FileUplaoder
                      //filePath={`students/${personalInfo.id}/profile-images/`}
                      uploadButton="Upload Photo"
                      // file={personalInfo.image}
                      fileType="image"
                      //onUpload={(filePath) => handleChangeWithData("image", filePath)}
                      // setSelectedFile={fileSelectedHandler}
                      helpertext="Maximum size of 10 MB. JPG, JPEG, or PNG."
                      showUploadButton={false}
                      showThumb={false}
                    />

                  </div>
                </div>
              </Grid>

              <Grid item xs={12} md={12}>
                <div className="Devider"></div>
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  label="First Name"
                  required={true}
                // value={personalInfo.firstName}
                //handleChange={handleChanges("firstName")}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  label="Middle Name"
                  required={false}
                //value={personalInfo.middleName}
                // handleChange={handleChanges("middleName")}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                <TextField
                  label="Last Name"
                  required={true}
                // value={personalInfo.lastName}
                //handleChange={handleChanges("lastName")}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6}>
                <TextField
                  label="Email address"
                  required={true}
                  placeholder=""
                // value={personalInfo.lastName}
                //handleChange={handleChanges("lastName")}
                />
              </Grid>



              <Grid item xs={12} sm={12} md={12}>
                <div className="RedioButton">
                  <FormControl>
                    <FormLabel id="demo-radio-buttons-group-label" className="FieldLabel">Gender</FormLabel>
                    <RadioGroup
                      aria-labelledby="demo-radio-buttons-group-label"
                      defaultValue="female"
                      name="radio-buttons-group"
                    // value={personalInfo.gender}
                    //onChange={handleChanges("gender")}
                    >
                      <FormControlLabel value="Male" control={<Radio />} label="Male" />
                      <FormControlLabel value="Female" control={<Radio />} label="Female" />

                    </RadioGroup>
                  </FormControl>
                </div>
              </Grid>


              <Grid item xs={12} sm={12} md={12}>
                <CountrySelectField
                  label="Select country"
                  validation={true}
                //options={getDataMap(countries, "countryId", "name")}
                //selectedOption={personalInfo.countryId}
                //handleChange={(fieldData) => handleChangeWithData("countryId", fieldData)}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Address"
                  required={true}
                  placeholder="Your current resident address"
                // value={personalInfo.lastName}
                //handleChange={handleChanges("lastName")}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                < SelectField
                  FieldLabel={true}
                  label="Birthday"
                  options={month}
                  handleChange={handleChanges}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                < SelectField
                  FieldLabel={true}
                  label=""
                  options={day}
                  handleChange={handleChanges}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4}>
                < SelectField
                  FieldLabel={true}
                  label=""
                  options={years}
                  handleChange={handleChanges}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                < SelectField
                  FieldLabel={true}
                  label="Highest education (optional)"
                  options={options}
                  handleChange={handleChanges}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <TextField
                  label="Occupation"
                  required={false}
                //value={personalInfo.middleName}
                // handleChange={handleChanges("middleName")}
                />
              </Grid>

              <Grid item xs={12} sm={12} md={12}>
                <div style={{ display: "flex", justifyContent: "space-between", }}>
                  <span></span>
                  <Buttons
                    label="Save Profile"
                    buttonColor="#FFB606"
                    border="#FFB606 1px solid"
                    color="#000"
                    width=""
                    height="48px"
                    fontSize="14px"
                  // onClick={ }
                  />
                </div>
              </Grid>

            </Grid>
          </div >


        </div>

      </div>
    </>
  );
};

export default ParentProfile;
