import axios from 'axios';
import moment from 'moment-timezone';
import { monthArray } from '../utils/helpers';

const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;

export function sendDataToAPI(method, data) {
    axios.post(`${baseUrl}/${method}}`, data)
        .then(response => function () {
            return response.data;
        });
}

export const retriveDataFromAPI = async (method, queryString) => {

    const result = await axios.get(`${baseUrl}/${method}?${queryString}`).then((result) => {
        console.log(result.data.body);
        return result.data.body;
    }).catch((err) => {
        console.log(err);
    });

    return result;

}
//captiatl the first word
export const capitalizeWords = (str) => {
    const words = str?.split(/\s+/); // Split at spaces
    return words
        ?.map((word, index) => {
            return word.length > 0
                ? index === 0
                    ? word.charAt(0).toUpperCase() + word.slice(1)
                    : word
                : "";
        })
        .join(" ");
};


// === get months list for select field === //
export const getMonths = () => {
    const months = [];

    // var monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    monthArray.forEach((monthName, index) => {
        months.push({ value: (index), label: monthName });
    });

    return months;
}

export const makeSafeString = (str) => {
    // Replace spaces with dashes
    let safeStr = str.replace(/ /g, '-');

    // Remove special characters except for dashes
    safeStr = safeStr.replace(/[^a-zA-Z0-9-]/g, '');

    // Convert to lowercase
    safeStr = safeStr.toLowerCase();

    return safeStr;
}

export const getDates = () => {
    const dates = [];
    for (let i = 1; i <= 31; i++) {
        dates.push({ value: i, label: i });
    }
    return dates;
}

// export const isValidTimeHHMM = (inputTime) => {
//     // Use dayjs to parse the time
//     const parsedTime = dayjs(inputTime, { format: 'HH:mm', strict: true });

//     // Check if the parsed time is valid and the hour is between 0 and 24
//     return parsedTime.isValid() && parsedTime.hour() >= 0 && parsedTime.hour() <= 24;
// }
export const isValidTimeHHMM = (inputTime) => {
    return /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(inputTime);
}

export const isValidEmail = (email) => {
    // Regular expression for a valid email address
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;

    // Test the email against the regex
    return emailRegex.test(email);
}

//generate the years 
export const getYears = () => {
    const years = [];
    const d = new Date();
    const year = d.getFullYear() - 15;
    for (let i = year; i > (year - 60); i--) {
        years.push({ value: i, label: i });
    }
    return years;
}

// === get data map for select/multiselect fields courses === //
export const getDataMap = (dataArray, primaryField, labelField, additionalField = "", separator = " ", conditionalField = "", conditionalValue = "", skipData = false) => {
    const result = [];
    if (typeof dataArray !== 'undefined') {
        dataArray.forEach(element => {

            if (typeof element[labelField] !== 'undefined' && element[labelField] !== null) {
                const label = additionalField === "" ? `${element[labelField]}` : `${element[labelField]}${separator}${element[additionalField]}`

                if (conditionalField?.length > 0) {
                    if (skipData) {
                        // console.log(conditionalField, element[conditionalField], conditionalValue)
                        if (element[conditionalField] != conditionalValue) {
                            result.push({ value: `${element[primaryField]}`, label: label });
                        }
                    } else {
                        if (element[conditionalField] == conditionalValue) {
                            result.push({ value: `${element[primaryField]}`, label: label });
                        }
                    }

                } else {
                    result.push({ value: `${element[primaryField]}`, label: label });
                }

            }
        });
    }
    // console.log(result);
    return result;
}

/**
 * retrive logged in user data
 */
export const getUserData = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
        return userData;
    } else {
        return {};
    }
}

/**
 * retrive user auth data
 */
export const getUserAuthData = () => {
    const userAuthenticated = localStorage.getItem('userAuthenticated')
    if (userAuthenticated) {
        return true;
    } else {
        return false;
    }
}

/**
 * retrive user token data
 */
export const getUserTokenData = () => {
    const userToken = localStorage.getItem('userToken')
    if (userToken) {
        return userToken;
    } else {
        return "";
    }
}

export default function emailValidation(email) {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
        return false;
    }
    return true;
}

// timezone based date formatter
export const dateFormatter = (time, timezone = "America/New_York", dateFormat = "ddd, MMM Do, YYYY z") => {
    const momentTime = typeof time == "string" ? moment(time) : moment(time.toISOString());
    return momentTime.tz(timezone).format(dateFormat);
}
