import React, { useState, useEffect } from "react";
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import { Grid } from '@material-ui/core/';

import DraggableDialog from "../../../components/hooks/Dialog";
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import Buttons from "../../../components/Form/Button/Button";
import Table from '../../../components/Table/Table';
import tableImg from '../../../assets/imgs/tableImg.png';

import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { logout } from "../../../redux/features/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

function ListingPage(props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const user = useSelector(state => state.auth.user)
  const onClick = () => {
    alert("onclick working fine!");
  }

  const headCells = [

    { id: 'name', numeric: true, disablePadding: false, label: 'Name', width: "30%" },
    { id: 'id', numeric: true, disablePadding: false, label: 'Text Bold', width: "18%" },
    { id: 'price', numeric: true, disablePadding: false, label: 'Text Light', width: "18%" },
    { id: 'status', numeric: true, disablePadding: false, label: 'Status', width: "25%", align: "right" },
    { id: '', numeric: true, disablePadding: false, label: '', width: "15%", },

  ];



  const rows = [
    {
      cell: [
        { img: tableImg, imgStatus: true, imgText: "Name", align: "left", },
        { textBold: "Text Bold", },
        { text: "Text Bold", },
        { Status: true, background: "#E1F2E8", color: "#0B9444", label: "Completed", },
        { ActionButton: true, ActionButtonLabel: "Action" },

      ],
      //======= Action Date Start===== //
      action: [
        { label: "View", onClick: onClick, },
        { label: "Edit", onClick: onClick },
        { label: "Delete", onClick: onClick },
      ]
      //======= Action Date End===== //
    },

    {
      cell: [
        { img: tableImg, imgStatus: true, imgText: "Name", align: "left", },
        { textBold: "Text Bold", },
        { text: "Text Bold", },
        { Status: true, background: "#FFF5E6", color: "#FFAE35", label: "In Progress", },
        { Button: true, buttonLabel: "Details", buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget: "_blank", },
      ],
    },

    {
      cell: [
        { img: tableImg, imgStatus: true, imgText: "Name", align: "left", },
        { textBold: "Text Bold", },
        { text: "Text Bold", },
        { Status: true, background: "#EEECFF", color: "#7266FB", label: "Enrolled", },
        {
          Button: true, buttonLabel: "Button Disabled", buttonColor: "#FFFFFF", buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget: "_blank", disabled: "disabled",
        },
      ],
    },

    {
      cell: [
        { img: tableImg, imgStatus: true, imgText: "Name", align: "left", },
        { textBold: "Text Bold", },
        { text: "Text Bold", },
        { Status: true, background: "#FCE7E9", color: "#E63946", label: "Not Enrolled", },
        {
          Button: true, buttonLabel: "Download", buttonColor: "#FFFFFF", buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget: "_blank", iconStatus: true, buttonIcon: <DownloadForOfflineOutlinedIcon />,
        },
      ],
    },

    {
      cell: [
        { img: tableImg, imgStatus: true, imgText: "Name", align: "left", },
        { textBold: "Text Bold", },
        { text: "Text Bold", },
        { Status: true, background: "#EDEDEF", color: "#676C7B", label: "Online", },
        {
          Button: true, buttonLabel: "Button with Border", buttonColor: "#FFFFFF", buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0", buttonLink: "#",
        },
      ],
    },

    {
      cell: [
        { img: tableImg, imgStatus: true, imgText: "Name", align: "left", },
        { textBold: "Text Bold", },
        { text: "Text Bold", },
        { linkText: "Islamic Studies", chip: "PRIMARY", chipStatus: true, linkTextStatus: true, link: "#", },
        {
          Button: true, buttonLabel: "Button with Border", buttonColor: "#FFFFFF", buttonBorder: "#E4E8EC 1px solid",
          buttonTextcolor: "#004FE0", buttonLink: "#",
        },
      ],
    },


  ];



  //=======  Dialog ===== //
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpen = () => {
    setOpenDialog(true);
  };
  const handleCloseDeleteDialog = () => {
    setOpenDialog(false)
  };
  //=======  Dialog ===== //


  //=======  Drawer Start ===== //
  const [openDrawer, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  //=======  Drawer End ===== //


  //=======  Drawer With Sub Drawer Start ===== //
  const [openDrawerSub, setOpenSub] = useState(false);
  const handleDrawerOpenSub = () => {
    setOpenSub(true);
  };
  const handleDrawerCloseSub = () => {
    setOpenSub(false);
  };
  //=======  Drawer With Sub Drawer End ===== //




  //=======  Drawer With Sub Menu ===== //
  const tabData = [
    {
      lebel: "Upload from device",
      icon: <DownloadForOfflineOutlinedIcon />,
    },

    {
      lebel: "YouTube video",
      icon: <DownloadForOfflineOutlinedIcon />,
    },
  ]

  const panelTab = [
    {
      id: 0,
      panleBody: <p>Body</p>,

      SaveButtonLabel: "Upload File",
      //clickSaveButton:clickSaveButton,
      CancelButtonLabel: "Cancel",

      Checkbox: true,
      CheckboxLabel: "Add another after saving",
      //onClickCheckbox:onClickCheckbox,

      DeleteButton: true,
      DeleteLebel: "Delete Permanently",
      //onClickDelete:onClickDelete,

      SubDrawerBodyWithTab: "Sub Drawer Body With Tab",

    },

    {
      id: 1,
      panleBody: <p>scsdsfvjsdvlds</p>,
      SaveButtonLabel: "Import From YouTube",
      //clickSaveButton:clickSaveButton,
      cancelButtonLabel: "Cancel",
    },

  ]
  //=======  Drawer With Sub Menu ===== //

  const handleLogout = () => {
    dispatch(logout());
    navigate("/signin")
  }

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12} md={12}>

          <div style={{ display: 'flex', margin: 20, justifyContent: 'end', alignItems: 'center' }}>
            {
              user ? (
                <Buttons
                  label="Logout"
                  buttonColor="red"
                  border="#E4E8EC 1px solid"
                  color="#fff"
                  width=""
                  height="48px"
                  fontSize="14px"
                  onClick={handleLogout}
                />
              ) : (
                <Buttons
                  label="Sign In"
                  buttonColor="green"
                  border="#E4E8EC 1px solid"
                  color="#fff"
                  width=""
                  height="48px"
                  fontSize="14px"
                  link="/signin"
                  onClick={undefined}
                />
              )
            }

          </div>
          <div className="TableSection">
            <Table
              title="Listing Table Example"
              tableHeader={true}
              addLabel="More Details"
              link="/table"
              headCells={headCells}
              rows={rows}
              AddButton={true}
              footer={true}
              //handleOpenDialog={handleOpenDialog}

              innerHeader={true}
              innerTitle="Table Name"
              AddButtonInner={true}
              addLabelInner="Add Payment"
              InnerButtonColor="#E0EAFB"
              InnerButtonTextColor="#004FE0"
              InnderButtonIcon={<AddRoundedIcon />}

              showSearchBox={true}
              FilterButton={true}

              CheckboxDelete={true}
            />
          </div>
        </Grid>

        <Grid item xs={12} md={12}>
          <div style={{ display: "flex" }}>
            <Buttons
              label="Modal"
              buttonColor="#004FE0"
              border="#E4E8EC 1px solid"
              color="#fff"
              width=""
              height="48px"
              fontSize="14px"
              link=""
              onClick={handleOpen}
            />

            <Buttons
              label="Drawer"
              buttonColor="#FFB606"
              border="#FFB606 1px solid"
              color="#000000"
              width=""
              height="48px"
              fontSize="14px"
              link=""
              onClick={handleDrawerOpen}
            />

            <Buttons
              label="Drawer witn sub drawer"
              buttonColor="#004FE0"
              border="#E4E8EC 1px solid"
              color="#fff"
              width=""
              height="48px"
              fontSize="14px"
              link=""
              onClick={handleDrawerOpenSub}
            />

            <Buttons
              label="Forms"
              buttonColor="#004FE0"
              border="#E4E8EC 1px solid"
              color="#fff"
              width=""
              height="48px"
              fontSize="14px"
              link="/forms"
            />


          </div>
        </Grid>



      </Grid>

      {/* Modal Start*/}
      <DraggableDialog
        openDialog={openDialog}
        handleCloseDialog={handleCloseDeleteDialog}
        title="Modal Title"
        body="Are you sure you want to delete this? Once you delete it, you can't get it back."
        ModalFooter={true}
      />
      {/* Modal End*/}



      {/* Drawer Start*/}
      <SideDrawer
        title="Edit Media"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        // onClickDelete={onClickDelete}
        DeleteButton={true}
        sideOpen="right"
        open={openDrawer}
        handleDrawerClose={handleDrawerClose}
        CheckboxLabel="Add another after saving"
        Checkbox={false}
        LeftButton={false}
        ButtonLeft="Upload New"
        FooterWithoutTab={true}
        arrowIcon={true}
        body={
          <p>Drawer Body</p>
        }
      />
      {/* Drawer End*/}



      {/* Drawer with Sub Drawer Start*/}
      <SideDrawer
        title="Edit Media"
        ButtonLabel="Save"
        cancelButtonLabel="Cancel"
        deleteText="Delete Permanently"
        // onClickDelete={onClickDelete}
        DeleteButton={true}
        sideOpen="right"
        open={openDrawerSub}
        handleDrawerClose={handleDrawerCloseSub}
        CheckboxLabel="Add another after saving"
        Checkbox={true}
        LeftButton={true}
        ButtonLeft="Upload New"
        ButtonLeftIcon={<FileUploadOutlinedIcon />}
        FooterWithoutTab={false}
        panelTab={panelTab}
        tabData={tabData}
        arrowIcon={true}

        TabsStatus={true}

        CheckBoxTabsStatus={true}
        TabsPannelStatus={true}

        SubDrawer="SubDrawer"
        SubDrawerButtonSatus={true}
        SubDrawerTabSatus={true}
        SubDrawerWithOutTab={false}
        SubDrawerWithOutTabBody={<p>Sub Drawer WithOut Tab Body</p>}

        TitleUnderHeaderStatus={true}
        TitleUnderHeader="Edit Payment"
        SubTitle="Payment Method*"


      />

      {/* Drawer with Sub Drawer Start*/}


    </>
  );
}


export default ListingPage;

