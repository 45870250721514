import React, { useEffect, useState } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import './App.css';

/** public pages start */
import SignIn from './pages/authentication/SignIn';
import SignUp from './pages/authentication/SignUp';
import CreateNewPassword from './pages/authentication/CreateNewPassword';
import ForgotPassword from './pages/authentication/ForgotPassword';
import Otp from './pages/authentication/Otp';
import ResetPassword from './pages/authentication/ResetPassword';
import ForgotPasswordEmailSent from './pages/authentication/ForgotPasswordEmailSent';
import SignInAddPhoneNumber from './pages/authentication/SignInAddPhoneNumber';
/** public pages end */

/** Example Pages */
import ListingPage from '../src/pages/example-pages/listing-page/ListingPage';
import FormsExample from '../src/pages/example-pages/forms/Forms';
// import CourseDetails from '../src/pages/example-pages/courses/details/DetailsPage';

/** Components links */
import TableData from '../src/components/Table/TableData';
import SchoolRoute from './routing/SchoolRoute';
import SchoolRouteWithoutLayout from './routing/SchoolRouteWithoutLayout';
/** Components links */

// ** ------------------------- Parent Portal start --------------------------------- **//

// ** ------------------------- Parent Portal end   --------------------------------- **//

// ** ------------------------- School Portal start --------------------------------- **//
import Orders from './pages/OwnerPortal/orders/orders';
import OrderDetails from './pages/OwnerPortal/orders/orderDetails';


import UsersListing from './pages/OwnerPortal/users/UsersListing';
import InvitedUsers from './pages/OwnerPortal/users/InvitedUsers';
import InviteNewUsers from './pages/OwnerPortal/users/InviteNewUsers';
import UsersDetails from './pages/OwnerPortal/users/UsersDetails';
import UsersDetailsUnique from './pages/OwnerPortal/users/UsersDetailsUnique';

import RolesPermissions from './pages/OwnerPortal/users/RolesPermissions';


import Groups from './pages/OwnerPortal/users/Groups';
import GroupDetails from './pages/OwnerPortal/users/GroupDetails';
import MediaLibraryTableView from './components/MediaLibrary/MediaLibraryTableView';
import Settings from './pages/OwnerPortal/settings/SettingsPage';
import AccountVrify from "./pages/authentication/AccountVrify";
// import MediaLibraryTableView from './components/MediaLibrary/MediaLibraryTableView-Backup';
// ** ------------------------- School Portal end   --------------------------------- **
// import MediaLibraryTableView from './components/MediaLibrary/MediaLibraryTableView';

// Classes
import VerifyOtp from './pages/authentication/VerifyOtp';
import ParentProfile from './pages/OwnerPortal/users/ParentProfile';
import ProfileSuccess from './pages/OwnerPortal/users/ProfileSuccess';
import Test from './components/Test/Test';
import InviteUsersPage from './pages/OwnerPortal/users/InviteUsersPage';
import { isTheActionPermitted, isTheSectionPermitted } from './hooks/HelperFunctionsPermission';
import NotPermitted from './pages/authentication/NotPermitted';
// ===== Components Links ========//

// ** ------------------------- School Portal end   --------------------------------- **//

function App() {
  //================== google recapcha v3 part start ====================//
  useEffect(() => {
    const script = document.createElement("script");
    script.src = `https://www.google.com/recaptcha/api.js?render=${process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}`;
    script.async = true;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    };
  }, []);
  //================== google recapcha v3 part end ====================//

  return (
    <Routes>
      {/* ExmaplePages start */}

      {/* <Route path="/" element={<ListingPage />} />     */}
      <Route path="/listing-example" element={<ListingPage />} />
      <Route path="/forms" element={<FormsExample />} />
      <Route path="/table" element={<TableData />} />
      <Route path="/test" element={<Test />} />
      {/* ExmaplePages end */}

      {/* public routes start */}
      <Route path="/signin" element={<SignIn />} />
      <Route path="/authentication/signin" element={<SignIn />} />
      <Route path="/sign-up" element={<SignUp />} />
      <Route path="/verify-email" element={<Otp />} />
      <Route path="/verify-otp" element={<VerifyOtp />} />
      <Route path="/reset-password" element={<ResetPassword />} />
      <Route path="/create-new-password" element={<CreateNewPassword />} />
      <Route path="/forgot-password" element={<ForgotPassword />} />
      <Route path="/add-phone-number" element={<SignInAddPhoneNumber />} />
      <Route path="/email-sent" element={<ForgotPasswordEmailSent />} />
      <Route path="/verified-screen" element={<AccountVrify />} />
      {/* public routes end */}


      <Route path="/" element={<SchoolRoute><Orders /></SchoolRoute>} />
      <Route path="/order-details" element={<OrderDetails />} />

      


      <Route path="/users" element={(isTheSectionPermitted('users') && isTheActionPermitted('manageUsers')) ? <SchoolRoute><UsersListing /></SchoolRoute> : <SchoolRoute><NotPermitted /></SchoolRoute>} />
      <Route path="/groups" element={(isTheSectionPermitted('users') && isTheActionPermitted('manageUserGroups')) ? <SchoolRoute> <Groups /> </SchoolRoute> : <SchoolRoute><NotPermitted /></SchoolRoute>} />
      <Route path="/invite-users" element={(isTheSectionPermitted('users') && isTheActionPermitted('inviteUsers')) ? <SchoolRoute> <InviteUsersPage /> </SchoolRoute> : <SchoolRoute><NotPermitted /></SchoolRoute>} />
      <Route path="/roles-permissions" element={(isTheSectionPermitted('users') && isTheActionPermitted('manageRolesPermissions')) ? <SchoolRoute> <RolesPermissions /> </SchoolRoute> : <SchoolRoute><NotPermitted /></SchoolRoute>} />
      <Route path="/users/:id" element={(isTheSectionPermitted('users') && isTheActionPermitted('manageUsers')) ? <SchoolRoute><UsersDetails /></SchoolRoute> : <SchoolRoute><NotPermitted /></SchoolRoute>} />
      <Route path="/invited-users" element={isTheSectionPermitted('users') ? <SchoolRoute> <InvitedUsers /> </SchoolRoute> : <SchoolRoute><NotPermitted /></SchoolRoute>} />
      <Route path="/group-details/:group_id" element={isTheSectionPermitted('users') ? <SchoolRoute> <GroupDetails /> </SchoolRoute> : <SchoolRoute><NotPermitted /></SchoolRoute>} />
      <Route path="/invite-new-users" element={isTheSectionPermitted('users') ? <SchoolRoute> <InviteNewUsers /> </SchoolRoute> : <SchoolRoute><NotPermitted /></SchoolRoute>} />
      <Route path="/users_u/:unique_id" element={<SchoolRoute><UsersDetailsUnique /></SchoolRoute>} />


      <Route path="/profile" element={<SchoolRoute> <Settings /> </SchoolRoute>} />
      <Route path="/parent-profile" element={<SchoolRoute> <ParentProfile /> </SchoolRoute>} />
      <Route path="/profile-saved-successfully" element={<SchoolRoute> <ProfileSuccess /> </SchoolRoute>} />
      <Route path="/media-library-table-view" element={<SchoolRoute> <MediaLibraryTableView /> </SchoolRoute>} />


    </Routes>
  );
}

export default App;
