import * as React from 'react';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { colors } from '@mui/material';

const CashPayment = ({ details, handleAppointmentDateChange }) => {
  return (
    <>
      <div>
        <b style={{ margin: '0', fontSize: '18px' }}>Schedule an appointment</b>
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DemoContainer components={['DateTimePicker']}>
            <DemoItem
              label={<b style={{ fontSize: '14px' }}>Select date and time*</b>}
            >
              <DateTimePicker
                inputFormat="MM/DD/YYYY"
                label=""
                value={
                  details?.appointmentDate ? details?.appointmentDate : null
                }
                onChange={handleAppointmentDateChange}
                disablePast={true}
              />
            </DemoItem>
          </DemoContainer>
        </LocalizationProvider>
        <span style={{ margin: '8px 0', color: '#5E5E62', fontSize: '14px' }}>
          Please call us to reconfirm your appointment at{' '}
          <b>+1 (123) 123-1234</b>
        </span>
      </div>
    </>
  );
};

export default CashPayment;
