import React, { useState, useEffect } from 'react';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import "./GooglePlaceField.css"

const GooglePlaceField = (props) => {

  return (
    <div>
      {
        (props.label === "") ? <></> : <div className="FieldLabel">{props.label} {props.validation && <span>*</span>}</div>
      }
      <GooglePlacesAutocomplete
        apiKey='AIzaSyCpinHOnBAzeTGbCBuFSU_-HrC3WUgIUTc'
        selectProps={{
          value: props.value,
          onChange: props.handleChange,
          placeholder: props.placeholder,

        }}
        className="dscdscvs"
      />
    </div>
  );
}

export default GooglePlaceField;