import React from 'react';
import './TextArea.css';

function TextArea(props) {
  const {
    label,
    validation,
    count,
    handleChange,
    onKeyUpHandler = false,
    onClickHandler = false,
    value,
    helperText,
    placeholder,
    errorMsg,
    disabled,
    error,
    name,
    allowExceedMaxLength = false
  } = props;

  const [textAreaCount, ChangeTextAreaCount] = React.useState(0);
  const recalculate = (e) => {
    ChangeTextAreaCount(e.target.value.length);
  };

  return (
    <div className="TextArea">
      <div className='TextAreaHeader'>
        {props.FieldLabel && (<div className="FieldLabel"> {label} {validation && <span>*</span>} </div>)}
        <div className='HeaderRightBody'>{props.HeaderRightBody}</div>
      </div>
      {props.ButtonHeaderStatus &&
        <div className='TextAreaButtonHeader'>{props.ButtonHeader}</div>
      }
      <textarea
        type="text"
        rows={5}
        maxlength={!allowExceedMaxLength && count}
        placeholder={placeholder}
        onChange={handleChange}
        onKeyUp={(e) => {
          if (onKeyUpHandler) {
            onKeyUpHandler(e)
          }
          console.log(e, 'keypressed')
        }}
        onClick={(e) => {
          if (onClickHandler) {
            onClickHandler(e)
          }
        }}
        value={value}
        disabled={disabled ? disabled : false}
        name={name}
        style={{ resize: "none", border: `${error ? '1px solid red' : ''}` }}
      />
      <div className="HelperTextCounter">
        <div>
          <p className="error-msg">{errorMsg}</p>
          <p className="HelperText">{helperText}</p>
        </div>
        {count && (
          <div className={props.errorText}>
            <div className='CharCountContainer'>
              <span className="CharCount">
                CHAR {!value ? '0' : value.length}/{count}
              </span>
              {props.PartStatus &&
                <>
                  <div className="Sline"></div>
                  <span className="CharCount">
                    PARTS {!value ? '0' : `${Math.ceil(value.length / props.PartCharaterLimit)}/${props.Partlimit}`}
                  </span>
                </>
              }
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default TextArea;
