import { api } from '../../api/apiSlice';

const userApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getMessageCategories: builder.query({
            query: () => ({
                url: '/categories/all',
            }),
            providesTags: ['Categories'],
        }),
        addMessageCategories: builder.mutation({
            query: (data) => ({
                url: '/categories/create',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Categories'],
        }),
        checkCategoriesName: builder.mutation({
            query: (data) => ({
                url: `/categories/checkExist`,
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Categories'],
        }),
        getMessageCategoriesById: builder.query({
            query: (id) => ({
                url: `/categories/getSingle/${id}`,
            }),
            providesTags: ['Category'],
        }),
        updateMessageCategories: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/categories/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Categories'],
        }),
        deleteMessageCategories: builder.mutation({
            query: (id) => ({
                url: `/categories/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Categories'],
        }),
        getMessageSearchResult: builder.mutation({
            query: (data) => ({
                url: '/messages/search',
                method: 'POST',
                body: data,
            })
        }),
        getCategoriesSearchResult: builder.mutation({
            query: (data) => ({
                url: '/categories/search',
                method: 'POST',
                body: data,
            })
        }),
        addMessage: builder.mutation({
            query: (data) => ({
                url: '/messages/create',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['Messages'],
        }),
        updateMessage: builder.mutation({
            query: ({ id, ...data }) => ({
                url: `/messages/${id}`,
                method: 'PATCH',
                body: data,
            }),
            invalidatesTags: ['Messages'],
        }),
        deleteMessage: builder.mutation({
            query: (id) => ({
                url: `/messages/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['Messages']
        }),
        getMessages: builder.query({
            query: () => ({
                url: '/messages/all',
            }),
            providesTags: ['Messages'],
        }),
        getTextToVoice: builder.mutation({
            query: (data) => ({
                url: '/public-api/textToSpeech',
                method: 'POST',
                body: data,
            })
        }),
        getCallToRecord: builder.mutation({
            query: (data) => ({
                url: 'messages/makeCall',
                method: 'POST',
                body: data,
            })
        }),
        getMessageById: builder.query({
            query: (id) => ({
                url: `/messages/getSingle/${id}`,
            }),
            // providesTags: ['Message'],
            providesTags: ['Messages'],
        }),
        getCallToRecordData: builder.query({
            query: (id) => ({
                url: `/messages/getRecordingDetails/${id}`,
            }),
            providesTags: ['Message'],
        })



    }),
});

export const {
    useGetMessageCategoriesQuery,
    useGetMessageCategoriesByIdQuery,
    useDeleteMessageCategoriesMutation,
    useUpdateMessageCategoriesMutation,
    useAddMessageCategoriesMutation,
    useGetCategoriesSearchResultMutation,
    useCheckCategoriesNameMutation,
    useAddMessageMutation,
    useGetTextToVoiceMutation,
    useGetMessagesQuery,
    useDeleteMessageMutation,
    useGetMessageByIdQuery,
    useUpdateMessageMutation,
    useGetCallToRecordMutation,
    useGetCallToRecordDataQuery,
    useLazyGetCallToRecordDataQuery,
    useGetMessageSearchResultMutation
} = userApi;
