/**
 * authors: Rizwan Shah (Design), Ashrafuzzaman Sujan (Development)
 */

import React, { useState, useEffect } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import DeleteForeverRoundedIcon from '@mui/icons-material/DeleteForeverRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Grid from '@mui/material/Grid';
import SelectField from '../SelectField/SelectField';

import './AddTextField.css';
import ButtonLoader from '../../Common/ButtonWithLoader/ButtonLoader';

function AddFieldGroup(props) {
  const {
    FieldOneLabel,
    required,
    placeholder,
    helperText,
    submitButtonLabel,
    textField,
    maxText,
    options,
    onclickSubmit,
    addButtonLabel,
    addButtonTextTransform,
    minItem,
    maxItem,
    selectValue,
    FieldTwoLabel,
    FieldThreeLabel,
    usersData,
    setUsersData,
    isButtonLoading,
  } = props;

  const handleinputchange = (e, index) => {
    const { name, value } = e.target;
    let list = [];
    list = [...usersData];
    if (name === 'name') {
      let nameArr = value.replace(/[^a-zA-Z0-9\s]/g, '');
      e.target.value = nameArr
      nameArr = nameArr.split(' ');
      list[index]['firstName'] = nameArr.shift();
      list[index]['lastName'] = nameArr.join(' ');
    } else {
      list[index][name] = value;
    }
    // setinputList(list);
    setUsersData(list);
  };

  const handleRemove = (index) => {
    const list = [...usersData];
    list.splice(index, 1);
    setUsersData(list);
  };

  const handleaddclick = () => {
    setUsersData((prev) => [
      ...prev,
      { email: '', firstName: '', lastName: '', role: '', emailError: '' },
    ]);
  };
  // const onChangeHandler = (index) => (event) => {

  //   const fieldData = event.target.value;
  //   const list = [...inputList];

  //   const currentListItem = list.findIndex((element, objIndex, array) => {
  //     return (objIndex == index);
  //   });

  //   list[currentListItem][textField] = fieldData;
  //   // update state variable

  // }

  return (
    <div className="AddFieldGroup">
      <Grid container spacing={2}>
        <Grid item md={props.col} xs={12}>
          <div className="FieldLabel labelBold">
            {FieldOneLabel} {required && <span>*</span>}
          </div>
        </Grid>
        <Grid item md={props.col} xs={12}>
          <div className="FieldLabel labelBold">
            {FieldTwoLabel} {required && <span>*</span>}
          </div>
        </Grid>
        {props.SelectFieldLabel &&
          <Grid item md={props.col} xs={12}>
            <div className="FieldLabel labelBold">
              {FieldThreeLabel} {required && <span>*</span>}
            </div>
          </Grid>
        }
      </Grid>

      {usersData.map((obj, index) => {
        return (
          <div key={index} className="AddFieldGroupContainer">
            <Grid container spacing={2}>
              <Grid item md={props.col} xs={12}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  error={(obj?.emailError != "")}
                  name="email"
                  placeholder={placeholder}
                  //value={obj[textField]}
                  // onChange={onChangeHandler(index)}
                  onChange={(e) => handleinputchange(e, index)}
                  onBlur={(e) => props.checkEmail(e, obj, index)}
                  helperText={(obj?.emailError != "") ? obj?.emailError : ''}
                  inputProps={{
                    maxLength: maxText,
                  }}
                />
              </Grid>

              <Grid item md={props.col} xs={12}>
                <TextField
                  id="outlined-basic"
                  variant="outlined"
                  // error={error}
                  name="name"
                  placeholder={'e.g: John Doe'}
                  value={obj[textField]}
                  onChange={(e) => handleinputchange(e, index)}
                  helperText={helperText}
                  inputProps={{
                    maxLength: maxText,
                  }}
                />
              </Grid>

              {props.SelectFieldStatus &&
                <Grid item md={props.col} xs={12}>
                  <SelectField
                    value={selectValue}
                    options={options}
                    name="role"
                    handleChange={(e) => handleinputchange(e, index)}
                  />
                </Grid>
              }
            </Grid>

            {usersData.length > minItem && (
              <Button
                className="RemoveField"
                variant="text"
                onClick={(event) => handleRemove(index, event)}
              >
                <DeleteForeverRoundedIcon />{' '}
              </Button>
            )}

            <div className="AddGroupFooter">
              {usersData.length - 1 === index && usersData.length < maxItem && (
                <Button
                  variant="text"
                  onClick={handleaddclick}
                  className="AddMoreButton"
                  sx={{ textTransform: `${addButtonTextTransform} !important` }}
                >
                  <AddRoundedIcon />
                  {addButtonLabel}
                </Button>
              )}

              {props.SubmitButton &&
                <>
                  {usersData.length - 1 === index &&
                    usersData.length >= minItem && (
                      <ButtonLoader
                        variant="text"
                        onClick={onclickSubmit}
                        className="SubmitButton"
                        isLoading={isButtonLoading}
                        submitButtonLabel={submitButtonLabel}
                      />
                    )}
                </>

              }
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default AddFieldGroup;
