import React from "react";
import Accordion from '@mui/material/Accordion';
import AccordionDetails from '@mui/material/AccordionDetails';
import AccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import SwitchButtonIOS from '../../components/Form/SwitchButtonIOS/SwitchButtonIOS';
import './SwitchAccordion.css';
import { Description } from "@mui/icons-material";


// const [expanded, setExpanded] = React.useState(false);
// const handleChange = (panel) => (event, isExpanded) => {
//   setExpanded(isExpanded ? panel : false);
// };

{/* <SwitchAccordion
  title="Full price"
  expanded={expanded === 'panel'}
  onChange={handleChange('panel')}
  //handleChangeSwitch={handleChange}
  body={
    <></>
  }
/> */}



function SwitchAccordion(props) {

  const { expanded, onChange, title, body, handleChangeSwitch, description, Switchexpanded, selectedOption } = props;

  return (


    <div className="SwitchAccordion">
      <Accordion expanded={expanded ? expanded : undefined} onChange={onChange ? onChange : () => { }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="AccordionTitle">
            <div className="AccordionTitleLrft">
              <h1>{title}</h1>
              <p>{description}</p>
            </div>
            <div className="AccordionTitleRight">
              {selectedOption > 0 ? <p>{selectedOption} permissions</p> : <span></span>}
              <SwitchButtonIOS
                handleChange={handleChangeSwitch}
                checked={Switchexpanded}
              />
            </div>
          </div>
        </AccordionSummary>

        <AccordionDetails className="SwitchAccordionBody">
          {body}
        </AccordionDetails>
      </Accordion>
    </div>



  );
}


export default SwitchAccordion;

