import { api } from '../../api/apiSlice';

const signApi = api.injectEndpoints({
    endpoints: (builder) => ({
        signApiData: builder.mutation({
            query: (data) => ({
                url: '/public-api/verifyGoogleRecaptcha',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['SignApiData'],
        }),

    }),
});

export const {
    useSignApiDataMutation,
} = signApi;
