import React, { useEffect, useState } from 'react';
import { IoMdSearch } from 'react-icons/io';
import Button from '@mui/material/Button';
import ArrowForwardRoundedIcon from '@mui/icons-material/ArrowForwardRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import './Search.css';



export default function SearchBox(props) {
  const { SearchPlaceholder, SearchValue, SearchOnChange, SearchOnMouseDown, setResetSearchTerm, resetsearchTerm } = props;


  const [show, setShow] = React.useState();
  const [showArrow, setShowArrow] = React.useState();

  //reset search item
  const handeleResetSerachitem = () => {
    setShow(false)
    setResetSearchTerm(true)
  }

  return (

    <>


      <div className={`SearchField ${show ? "SearchFieldActive" : "SearchFieldDefault"}`}>

        <div className='TableSearch'>
          <div onClick={() => setShowArrow(false)}>
            <Button
              className='TableSearchButton ButtonShow'
              onClick={() => setShow(true)}
              onMouseDown={SearchOnMouseDown}
            >
              <IoMdSearch /> Search...
            </Button>
          </div>

          <div className='TableSearchButton ButtonHide'><IoMdSearch /></div>

          <input
            className='TableSearchField'
            type='text'
            placeholder={SearchPlaceholder}
            value={resetsearchTerm ? "" : SearchValue}
            onChange={SearchOnChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                props.handleSearch()
                setShowArrow(true)
              }
            }}
            onClick={() => setShowArrow(false)}
          />

          <div className={`SearchArrow ${showArrow ? "iconActive" : "iconHide"}`}>

            <div
              className='SearchButton'
              onClick={props.handleSearch}
              onMouseDown={props.handleSearch}
              edge="end"
            >
              <ArrowForwardRoundedIcon className='iconArrow' onClick={() => setShowArrow(true)} />
              <CloseRoundedIcon className='iconClose' onClick={() => handeleResetSerachitem()} />
            </div>
          </div>


        </div>
      </div>
    </>
  );
}