import React from "react";
import Tooltips from '@mui/material/Tooltip';
import './Tooltip.css';



{/* <Tooltip
  TooltipText="Text"
  Tooltip={<InfoRoundedIcon />}
/> */}


function Tooltip(props) {



  return (
    <div className="Tooltip">

      <Tooltips
        title={props.TooltipText}
        placement="top"
        arrow
      >
        <div>{props.Tooltip}</div>
      </Tooltips>

    </div>
  );
}


export default Tooltip;

