import { api } from '../../api/apiSlice';

const roleApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getRoleList: builder.query({
      query: () => ({
        url: '/roles/all',
      }),
      providesTags: ['Roles']
    }),

    deleteRole: builder.mutation({
      query: (id) => ({
        url: `/roles/${id}`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Roles'],
    }),
    createRole: builder.mutation({
      query: (data) => ({
        url: '/roles/create',
        method: 'POST',
        body: data,
      }),
      invalidatesTags: ['Roles'],
    }),
    getRolesSearchResult: builder.mutation({
      query: (data) => ({
        url: '/roles/search',
        method: 'POST',
        body: data,
      }),
    }),
    getRolesFromIds: builder.mutation({
      query: (data) => ({
        url: '/roles/getRolesFromIds',
        method: 'POST',
        body: data,
      }),
    }),
    getRoleDetails: builder.query({
      query: (id) => ({
        url: `/roles/getSingleRole/${id}`,
      }),
      providesTags: ['RoleDetails'],
    }),
    updateRole: builder.mutation({
      query: ({ id, ...data }) => ({
        url: `/roles/${id}`,
        method: 'PATCH',
        body: data,
      }),
      invalidatesTags: ['Roles', 'RoleDetails'],
    }),
    checkRoleNameExisted: builder.mutation({
      query: (data) => ({
        url: `roles/checkIfRoleExists`,
        method: 'POST',
        body: data,
      })
    })
  })
});

export const {
  useGetRoleListQuery,
  useDeleteRoleMutation,
  useCreateRoleMutation,
  useGetRolesSearchResultMutation,
  useGetRoleDetailsQuery,
  useUpdateRoleMutation,
  useGetRolesFromIdsMutation,
  useCheckRoleNameExistedMutation

} = roleApi;
