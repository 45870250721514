import React, { useEffect, useState } from 'react';
import { Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { deepPurple } from '@mui/material/colors';
import Table from '../../../components/Table/Table';
import Grid from '@mui/material/Grid';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import Breadcrumbs from '../../../components/Breadcrumbs/Breadcrumbs';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import {
  useGetSearchResultMutation,
  useGetUserListQuery
} from '../../../redux/features/users/userApi';
import { toTitleCase } from '../../../utils/helpers';
const AddUsers = ({ setGroupUsers, groupUsers, addGroupUsersOptions, setGroupUsersToUpdate, groupUsersToUpdate }) => {
  const { data, isLoading, isError, error } = useGetUserListQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });

  const [getSearchedUserList, options] = useGetSearchResultMutation();
  const [rows, setRows] = useState([]);
  const [uersAvailable, setUersAvailable] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
      setUersAvailable(data.body.data)
    }
  }, [data, isLoading, isError]);
  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setSearchTerm("")
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ term: searchTerm });
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
      setSearchTerm("")
    }
    setPageNumberFromOutSide(false)
  }
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  }
  // =========== search part end ====================== //
  const headCells = [
    {
      id: 'fullName',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '50%',
      textAlign: 'left',
    },
    {
      id: 'id',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      width: '25%',
      textAlign: 'left',
    },
    {
      id: 'userType',
      numeric: false,
      disablePadding: true,
      label: 'User Type',
      width: '25%',
      textAlign: 'left',
    },
    // {
    //   id: 'grade',
    //   numeric: false,
    //   disablePadding: true,
    //   label: 'Grade',
    //   width: '20%',
    //   textAlign: 'left',
    // },
  ];

  const convertDataToCellRows = (arr) => {
    console.log("arr ==>", arr)
    if (arr.length > 0) {
      return arr.map((item) => ({
        cell: [
          { Check: true, checkBoxColor: "success", width: "48px", padding: "0", user_id: item._id, checked: true },
          {
            textBold: `${item.firstName} ${item.lastName}`, user_id: item?._id,
            user: {
              name: `${item?.firstName} ${item?.lastName}`,
              id: item?._id,
              userUniqueID: item?.userUniqueID,
              // role: item?.role,
              userType: item?.userType,
              email: item?.email,
              firstName: item?.firstName || "",
              lastName: item?.lastName || "",
              middleName: item?.middleName || "",
              phone: item?.phone || "",
              createdAt: item?.createdAt || "",
              status: item?.status || "",
            }
          },
          { text: item?.userUniqueID || "N/A" },
          { text: item?.userType ? toTitleCase(item?.userType) : "N/A" },
          // { text: item?.grade?.join(',') || "N/A" },
        ]
      }));
    } else {
      return [];
    }
  }

  useEffect(() => {
    let addUsers = []
    groupUsers?.map((users) => {
      let foundUser = uersAvailable.find((user) => user?._id === users)
      if (foundUser) {
        addUsers.push({
          id: foundUser?._id,
          name: `${foundUser?.firstName} ${foundUser?.lastName}`,
          userUniqueID: foundUser?.userUniqueID,
          userType: foundUser?.userType,
          // role: foundUser?.userType,
          email: foundUser?.email,
          firstName: foundUser?.firstName || "",
          lastName: foundUser?.lastName || "",
          middleName: foundUser?.middleName || "",
          phone: foundUser?.phone || "",
          status: foundUser?.status || "",
          createdAt: foundUser?.createdAt || "",
        })
      }
    })
    setGroupUsersToUpdate(addUsers)
  }, [groupUsers])


  const handleRowClick = (e, item) => {
    const isChecked = e.target.checked;

    if (isChecked) {
      // Add the user to the array
      setGroupUsers(prevUsers => [...prevUsers, item[1].user?._id]);
    } else {
      // Remove the user from the array
      setGroupUsers(prevUsers => prevUsers.filter(user => user._id !== item[1].user?._id));

    }
  }

  return (
    <>
      <div className="TableSection">
        <Table
          YearLabel="May 2023"
          headCells={headCells}
          rows={rows}
          innerHeader={true}
          FilterButtonDrawer={false}
          footer={true}
          RowsPerPage={10}
          //onClickFilterButton={onClickFilterButton}
          showSearchBox={true}
          handleRowClick={handleRowClick}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          pageNumberFromOutSide={pageNumberFromOutSide}
          isDataLoading={isLoading || addGroupUsersOptions.isLoading}
          handleCheckItem={setGroupUsers}
          selectedCheckList={groupUsers}
          customCellObjectId="user_id"
          CheckboxDelete={true}
        />
      </div>
    </>
  );
};

export default AddUsers;
