import axios from 'axios';
import moment from 'moment-timezone';

const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;

export function sendDataToAPI(method, data) {
    axios.post(`${baseUrl}/${method}}`, data)
        .then(response => function () {
            return response.data;
        });
}

export const retriveDataFromAPI = async (method, queryString) => {

    const result = await axios.get(`${baseUrl}/${method}?${queryString}`).then((result) => {
        console.log(result.data.body);
        return result.data.body;
    }).catch((err) => {
        console.log(err);
    });

    return result;

}

export const capitalizeFirstLetter = (string) => {
    return string[0]?.toUpperCase() + string?.slice(1);
}

// === get months list for select field === //
export const getMonths = () => {
    const months = [];

    var monthArray = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
    monthArray.forEach(monthName => {
        months.push({ value: monthName, label: monthName });
    });

    return months;
}

//generate the years 
export const getYears = () => {
    const years = [];
    const d = new Date();
    const year = d.getFullYear() - 15;
    for (let i = year; i > (year - 60); i--) {
        years.push({ value: i, label: i });
    }
    return years;
}

// === get data map for select/multiselect fields courses === //
export const getDataMap = (dataArray, primaryField, labelField, additionalField = "", separator = " ", conditionalField = "", conditionalValue = "", skipData = false) => {
    const result = [];
    if (typeof dataArray !== 'undefined') {
        dataArray.forEach(element => {

            if (typeof element[labelField] !== 'undefined' && element[labelField] !== null) {
                const label = additionalField === "" ? `${element[labelField]}` : `${element[labelField]}${separator}${element[additionalField]}`

                if (conditionalField?.length > 0) {
                    if (skipData) {
                        // console.log(conditionalField, element[conditionalField], conditionalValue)
                        if (element[conditionalField] != conditionalValue) {
                            result.push({ value: `${element[primaryField]}`, label: label });
                        }
                    } else {
                        if (element[conditionalField] == conditionalValue) {
                            result.push({ value: `${element[primaryField]}`, label: label });
                        }
                    }

                } else {
                    result.push({ value: `${element[primaryField]}`, label: label });
                }

            }
        });
    }
    // console.log(result);
    return result;
}

/**
 * retrive logged in user data
 */
export const getUserData = () => {
    const userData = JSON.parse(localStorage.getItem('userData'));
    if (userData) {
        return userData;
    } else {
        return {};
    }
}

/**
 * retrive user auth data
 */
export const getUserAuthData = () => {
    const userAuthenticated = localStorage.getItem('userAuthenticated')
    if (userAuthenticated) {
        return true;
    } else {
        return false;
    }
}

/**
 * retrive user token data
 */
export const getUserTokenData = () => {
    const userToken = localStorage.getItem('userToken')
    if (userToken) {
        return userToken;
    } else {
        return "";
    }
}

export default function emailValidation(email) {
    const regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    if (!email || regex.test(email) === false) {
        return false;
    }
    return true;
}

// timezone based date formatter
export const dateFormatter = (time, timezone = "America/New_York", dateFormat = "ddd, MMM Do, YYYY z") => {
    const momentTime = typeof time == "string" ? moment(time) : moment(time.toISOString());
    return momentTime.tz(timezone).format(dateFormat);
}
