import { store } from "../redux/store";

let rolesWithPermissions = []
let user = {}
const setRolesWithPermissions = () => {
    if (store.getState().auth) {
        rolesWithPermissions = store.getState()?.auth?.rolesWithPermissions
        user = store.getState()?.auth?.user
    }
    return (rolesWithPermissions && rolesWithPermissions?.length > 0) ? rolesWithPermissions : []
}

export const isTheActionPermitted = (actionName) => {
    let found = false
    let setRolesWithPermissionsValue = setRolesWithPermissions()
    if (user?.userType === 'owner') {
        return true
    }
    setRolesWithPermissionsValue?.map(roles => {
        roles?.permissions?.map(singlePermission => {
            singlePermission?.operations?.map(operation => {
                if (operation.name == actionName) {
                    found = true
                }
            })
        })
    })
    return found
}

export const isTheSectionPermitted = (sectionName) => {
    let found = false
    let setRolesWithPermissionsValue = setRolesWithPermissions()
    if (user?.userType === 'owner') {
        return true
    }
    setRolesWithPermissionsValue?.map(roles => {
        roles?.permissions?.map(singlePermission => {
            if (singlePermission?.name == sectionName) {
                found = true
            }
        })
    })
    return found
}
// export const isTheSectionPermitted = (sectionName) => {
//     let found = false
//     let setRolesWithPermissionsValue = setRolesWithPermissions()
//     if (user?.userType === 'owner') {
//         return true
//     }
//     setRolesWithPermissionsValue?.map(roles => {
//         roles?.permissions?.map(singlePermission => {
//             if (singlePermission?.operations?.length > 0 && singlePermission?.name == sectionName) {
//                 found = true
//             }
//         })
//     })
//     return found
// }


