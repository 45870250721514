import { api } from '../../api/apiSlice';

const qrCodeApi = api.injectEndpoints({
    endpoints: (builder) => ({
        generateQrCode: builder.mutation({
            query: (data) => ({
                url: 'users/private/generate-qr',
                method: 'POST',
                body: data,
            }),
            invalidatesTags: ['InviteUser', 'UserDetails']
        }),
    }),
});

export const {
    useGenerateQrCodeMutation
} = qrCodeApi;
