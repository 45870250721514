import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Buttons from '../../components/Form/Button/Button';
import TextField from '../../components/Form/TextField/TextField';
import { useNavigate } from 'react-router-dom';

function NotPermitted(props) {
    const navigate = useNavigate();

    return (
        <>
            <div className="SignContainer">
                <p style={{ color: 'red', textAlign: 'center', fontSize: '22px' }}>You are not allowed to access this page.</p>
            </div>
        </>
    );
}

export default NotPermitted;
