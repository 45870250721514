import React, { useState } from 'react';
import Table from '../../../components/Table/Table';
import {
  useGetSearchByRoleAndTermResultMutation,
  useGetUsersByRoleQuery,
} from '../../../redux/features/users/userApi';
import useProtected from '../../../hooks/useProtected';
import { useEffect } from 'react';
import { Button } from '@material-ui/core';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import { toTitleCase } from '../../../utils/helpers';

const SelectUsers = ({ selectUsers, selectedListJust, handleCloseSelectUsers, guardianListDatas, selectedList, renderFullItem = false }) => {
  const { data, isError, isLoading, error } = useGetUsersByRoleQuery('parent', {
    refetchOnMountOrArgChange: true,
  });
  useProtected(error);

  const [getSearchedUserList, options] = useGetSearchByRoleAndTermResultMutation();
  const [rows, setRows] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(
    selectedList?.length > 0 ? selectedList : [],
  );

  // useEffect(() => {
  //   console.log(selectedUsers, 400)
  // }, [selectedUsers])

  const [searchTerm, setSearchTerm] = useState('');

  const handleSelectUser = (item) => {
    if (renderFullItem) {
      selectUsers(rows.filter(innerItem => selectedUsers.includes(innerItem.item._id)).map(rowItem => rowItem.item))
    } else {
      selectUsers(selectedUsers);
    }
    handleCloseSelectUsers();
  };

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => ({
        item: item,
        cell: [
          {
            Check: true,
            checked: true,
            textBold: `${item.firstName} ${item.lastName}`,
            user_id: item._id,

            // handleSelect: () => handleSelectUser(item),
          },

          { text: item?.userUniqueID ? item.userUniqueID : 'N/A' },
          { text: item?.role ? toTitleCase(item.role) : 'N/A' },
          { text: '' },
        ],
      }));
    } else {
      return [];
    }
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      let userToshow = data.body.data?.filter((users) => {
        let userFound = guardianListDatas?.find((exiting) => exiting?.userUniqueID === users?.userUniqueID)
        return userFound ? false : true
      })
      setRows(convertDataToCellRows(userToshow));
      // console.log(data.body.data, 45)
    }
  }, [data, isLoading, isError]);


  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Full Name',
      width: '45%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'ID',
      width: '20%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'User Type',
      width: '20%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '20%',
      textAlign: 'left',
    },
  ];

  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    const result = await getSearchedUserList({ term: searchTerm, role: 'parent' });
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
    }
  };



  return (
    <>
      <div className="TableSection">
        <Table
          headCells={headCells}
          rows={rows}
          footer={true}
          innerHeader={true}
          FilterButton={false}
          searchBoxPlaceholder="Search users"
          link="/invited-users"
          isDataLoading={isLoading || options?.isLoading}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          showSearchBox={true}
          handleCheckItem={setSelectedUsers}
          selectedCheckList={selectedListJust}
          // handleRowClick={handleRowClick}
          customCellObjectId="user_id"

        />
      </div>
      <DrawerFooterActions
        isLoading={false}
        handleClose={handleCloseSelectUsers}
        handleAction={handleSelectUser}
        closeBtnLabel={'Cancel'}
        mobileCloseBtnLabel={'Mobile Close'}
        actionButtonLabel={`Select ${selectedUsers.length > 0 ? selectedUsers.length : 0
          } user${selectedUsers?.length > 1 ? "s" : ""}`}
      />
    </>
  );
};

export default SelectUsers;
