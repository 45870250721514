import React, { useState } from 'react';
import Table from '../../../components/Table/Table';
import { Button } from '@mui/material';
import SideDrawer from '../../../components/SideDrawer/SideDrawer';
import tableImg from '../../../assets/imgs/tableImg.png';
import CustomAccordion from '../../../components/CustomAccordion/CustomAccordion';
import {
  useGetSearchResultMutation,
  useGetUserListQuery,
} from '../../../redux/features/users/userApi';
import { useEffect } from 'react';
import useProtected from '../../../hooks/useProtected';
import AddRoundedIcon from '@mui/icons-material/AddRounded';

import AddNewGroup from './AddNewGroup';
import {
  useGetAllGroupsQuery,
  useGetGroupsSearchResultMutation,
} from '../../../redux/features/groups/groupsApi';
import moment from 'moment-timezone';
import { isTheActionPermitted } from '../../../hooks/HelperFunctionsPermission';
import { toast } from 'react-toastify';

const Groups = () => {
  const { data, isLoading, isError, error } = useGetAllGroupsQuery(undefined, {
    refetchOnMountOrArgChange: true,
  });
  const [createIdDrawer, setCreateIdDrawer] = useState(false);
  useProtected(error);
  const [getSearchedUserList, options] = useGetGroupsSearchResultMutation();
  const [rows, setRows] = useState([]);

  const handleOpenDrawer = () => {
    if (!isTheActionPermitted('manageUserGroups')) {
      toast.error("You don't have access to this feature")
      return false
    }
    setCreateIdDrawer(true);
  }
  const handleCloseDrawer = () => setCreateIdDrawer(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectedGroup, setSelectedGroup] = useState('');

  const headCells = [
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Name',
      width: '40%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Users',
      width: '25%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: 'Date Created',
      width: '25%',
      textAlign: 'left',
    },
    {
      id: 'name',
      numeric: false,
      disablePadding: true,
      label: '',
      width: '20%',
      textAlign: 'left',
    },
  ];

  const convertDataToCellRows = (arr) => {
    if (arr.length > 0) {
      return arr.map((item) => ({
        cell: [
          {
            linkText: item?.name || 'N/A',
            linkTextStatus: true,
            link: `/group-details/${item._id}`,
          },
          {
            Status: true,
            background: '#EDEDEF',
            color: '#676C7B',
            label: `${item?.users.length || 0} users`,
          },
          {
            text: item?.createdAt
              ? moment(item.createdAt).format('DD-MMM-YYYY')
              : 'N/A',
          },
          {
            Button: true,
            buttonLabel: 'Details',
            align: 'right',
            buttonTextcolor: '#004FE0',
            buttonLink: `/group-details/${item._id}`,
            // buttonLink: `/users/${item._id}`,
            // onClick: () => handleOpenAddGroup(item._id),
          },
        ],
      }));
    } else {
      return [];
    }
  };

  const handleOpenAddGroup = (id) => {
    setSelectedGroup(id);
    setCreateIdDrawer(true);
  };

  useEffect(() => {
    if (!isLoading && !isError) {
      setRows(convertDataToCellRows(data.body.data));
    }
  }, [data, isLoading, isError]);

  // const rows = [

  //   {
  //     cell: [
  //       { linkText: "Shayan Mahmud ", linkTextStatus:true, link:"#" ,},
  //       { text: "000022",},
  //       { text: "Student",},
  //       { text: "shayanmahmud@gmail.com",},
  //       { Status: true,background: "#E1F2E8", color: "#0B9444",label: "Active",},
  //       {
  //         Button: true, buttonLabel: "Details",
  //         buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget:"_blank",
  //       },
  //     ],
  //   },

  //   {
  //     cell: [
  //       { linkText: "Shayan Mahmud ", linkTextStatus:true, link:"#" ,},
  //       { text: "000022",},
  //       { text: "Student",},
  //       { text: "shayanmahmud@gmail.com",},
  //       { Status: true, background: "#FCE7E9", color: "#E63946", label: "Inactive", },
  //       { Button: true, buttonLabel: "Details",buttonTextcolor: "#004FE0", buttonLink: "#", buttonTarget:"_blank",},
  //     ],
  //   },

  // ];

  const tabData = [
    {
      title: 'Users',
    },
    {
      title: 'Visitors (comin soon)',
    },
  ];

  // =========== search part start ====================== //
  const [pageNumberFromOutSide, setPageNumberFromOutSide] = useState(false);
  const [resetsearchTerm, setResetSearchTerm] = useState(false);
  useEffect(() => {
    if (resetsearchTerm) {
      setSearchTerm("")
      setResetSearchTerm(false)
    }
  }, [resetsearchTerm])
  const handleSearchText = (e) => {
    e.preventDefault();
    setSearchTerm(e.target.value);
  };

  const handleSearch = async () => {
    setPageNumberFromOutSide(true)
    const result = await getSearchedUserList({ term: searchTerm });
    if (result?.data) {
      setRows(convertDataToCellRows(result.data.body.data));
      setSearchTerm("")
    }
    setPageNumberFromOutSide(false)
  };
  // =========== search part start ====================== //

  return (
    <>
      <div className="TableSection">
        <Table
          title="Groups"
          tableHeader={true}
          addLabel="New Group"
          AddButton={true}
          icon={<AddRoundedIcon />}
          headCells={headCells}
          rows={rows}
          onClick={handleOpenDrawer}
          footer={true}
          handleSearchText={handleSearchText}
          handleSearch={handleSearch}
          setResetSearchTerm={setResetSearchTerm}
          setSearchTerm={setSearchTerm}
          resetsearchTerm={resetsearchTerm}
          pageNumberFromOutSide={pageNumberFromOutSide}
          innerHeader={true}
          SearchBoxTop={true}
          FilterButton={false}
          searchBoxPlaceholder="Search users"
          isDataLoading={isLoading || options?.isLoading}
          RowsPerPage={20}

          emptyTableTitle="No group added yet"
          emptyTableNote="No items available"
          btnLabel="New Group"
          showButton={true}
          onClickEmptyCard={handleOpenDrawer}
        />
      </div>

      {/* new user drawer */}
      <SideDrawer
        open={createIdDrawer}
        handleDrawerClose={() => {
          setSelectedGroup('');
          handleCloseDrawer();
        }}
        title={`${selectedGroup ? 'Update' : 'Create New '} Group`}
        ButtonLabel="CREATE GROUP"
        sideOpen="right"
        cancelButtonLabel="Cancel"
        FooterWithoutTab={false}
        body={
          <>
            <AddNewGroup
              closeDrawer={() => {
                setSelectedGroup('');
                handleCloseDrawer();
              }}
              id={selectedGroup}
            />
          </>
        }
      />
    </>
  );
};

export default Groups;
