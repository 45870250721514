export const calculatePasswordStrength = (pass) => {
  let strength = 0;
  // Add conditions to check for password strength
  if (pass.match(/[A-Z]+/)) {
    strength += 1;
  }
  if (pass.match(/[a-zA-Z0-9]+/)) {
    strength += 1;
  }
  if (pass.match(/[0-9]+/)) {
    strength += 1;
  }
  if (pass.length >= 8) {
    strength += 1;
  }
  return strength;
};

const emailValidation = (email) => {
  const regex =
    /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
  if (!email || regex.test(email) === false) {
    return false;
  }
  return true;
};

export const validateFormData = (formObj, setErrors, errorMsgs) => {
  const newErrors = {};
  let isValid = true;

  Object.entries(formObj).map(([key, value]) => {
    if (key === 'email') {
      let isValidEmail = emailValidation(value);
      if (!isValidEmail) {
        newErrors['email'] = 'Email address is not valid';
        isValid = false;
      }
    }

    if (!value || value?.length < 1) {
      // newErrors[key] = errorMsgs[key] ? errorMsgs[key] : `${key} is required`;
      newErrors[key] = errorMsgs[key] ? errorMsgs[key] : ``;
    }

    if (newErrors[key]) {
      isValid = false;
    }
  });

  setErrors(newErrors);
  return isValid;
};
