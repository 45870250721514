import { Box, Button } from '@mui/material';
import React from 'react';
import Loader from '../../Loader/Loader';

const ButtonLoader = ({ isLoading, ...rest }) => {
    return (
        <Button {...rest}>
            {
                isLoading ? <Box component={'span'} sx={{ display: 'flex', alignItems: 'center' }}><Loader height="20px" width="20px" /> Loading...</Box> : <span>{rest?.submitButtonLabel}</span>
            }
        </Button>
    );
};

export default ButtonLoader;