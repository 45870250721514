import * as React from 'react';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import IconButton from '@mui/material/IconButton';
import Collapse from '@mui/material/Collapse';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';

import './AlertMessage.css';


// import AlertMessage from '../../components/Form/AlrtMessage/AlertMessage';
// const [open, setOpen] = React.useState(true);
{/* <AlertMessage
  open={open}
  background="#0B9444"
  color="#fff"
  message="Enrolled successfully, redirecting you shortly"
  width="500px"
/> */}





export default function AlrtMessage(props) {

  return (

    <div className='alertMessage'>
      <Box sx={{ width: '100%' }}>
        <Collapse in={props.open}>
          <Alert
            // icon={<CheckIcon fontSize="inherit" style={{ color: props.color, }} />}
            severity={props.severity}
            action={
              <IconButton
                aria-label="close"
                color="inherit"
                size="small"
                onClick={props.closeHandler}
                

              >
                <CloseIcon fontSize="inherit" style={{ color: props.color }} />
              </IconButton>
              
            }
            style={{ background: props.background, color: props.color, maxWidth: props.width, }}
          >
            {props.message}
          </Alert>
        </Collapse>
      </Box>
    </div>
  );
}