import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getUserData } from '../hooks/HelperFunctions';

import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import Avatar from '@mui/material/Avatar';
import RadioButtonUncheckedRoundedIcon from '@mui/icons-material/RadioButtonUncheckedRounded';
import RadioButtonCheckedRoundedIcon from '@mui/icons-material/RadioButtonCheckedRounded';
import Badge from '@mui/material/Badge';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import './PortalSwitcher.css';

function PortalSwitcher(props) {
  const [activePortal, setActivePortal] = useState(() => {
    const activePortal = localStorage.getItem('activePortal');
    if (activePortal) {
      return activePortal;
    } else {
      return 0;
    }
  });
  const [teachingAccounts, setTeachingAccounts] = useState(null);

  let userData = getUserData();
  const userId = userData.id;

  const navigate = useNavigate();

  useEffect(() => {
    getTeachingAccountsList();
    // setActivePortal(localStorage.getItem('activePortal'));
  }, []);

  // useEffect(() => {

  // }, [activePortal]);

  // fetching teaching accounts data
  const getTeachingAccountsList = () => {
    // const baseUrl = process.env.REACT_APP_PROJECT_ENV === 'prod' ? process.env.REACT_APP_API_URL_PROD : process.env.REACT_APP_API_URL_DEV;
    // axios.get(`${baseUrl}/orgPortal/getUserOrgsData?userId=${userId}`).then((result) => {
    //   console.log(result.data.body);
    //   setTeachingAccounts(result.data.body);
    // }).catch((err) => {
    //   console.log(err);
    // });
  };

  const handleChange = (event) => {
    const activePortalId = event.target.value;
    console.log(activePortalId);
    setActivePortal(activePortalId);
    const activePortal = activePortalId;
    localStorage.setItem('activePortal', activePortal);

    if (activePortal == 0) {
      localStorage.removeItem('orgId');
      localStorage.removeItem('subDomain');
      userData.userRole = 'student';
      localStorage.setItem('userData', JSON.stringify(userData));
      navigate('/my-courses', { replace: true });
    } else if (activePortal > 0) {
      localStorage.setItem('orgId', activePortal);
      let orgInfoObject = {};
      if (teachingAccounts) {
        orgInfoObject = teachingAccounts.find(
          (item) => item.orgId === activePortal,
        );
      }
      console.log(orgInfoObject);
      if (orgInfoObject?.subDomain) {
        localStorage.setItem('subDomain', orgInfoObject.subDomain);
      }
      userData.userRole = 'org';
      localStorage.setItem('userData', JSON.stringify(userData));
      navigate('/courses-listing', { replace: true });
    } else {
      // do nothing
    }
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    } else {
      return text.substring(0, maxLength) + '...';
    }
  };

  return (
    <>
      {teachingAccounts && teachingAccounts.length > 0 ? (
        <div className="MenueDropdownWithImgCont">
          <FormControl fullWidth>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={activePortal}
              label="Active Portal"
              onChange={handleChange}
              className="MenueDropdownWithImg"
            >
              <div className="SlImLstingTitle">TEACHING ACCOUNTS</div>
              {teachingAccounts.map((item) => (
                <MenuItem value={item.orgId} className="SlImLsting">
                  <div className="SlImLst">
                    <RadioButtonUncheckedRoundedIcon className="listUnCheck" />
                    <RadioButtonCheckedRoundedIcon className="listCheck" />
                    <Avatar alt={item.accountName} src={item.officialLogo} />
                    <div className="SlImLstlabel">
                      {truncateText(item.accountName, 10)}{' '}
                      <label>{item.designation}</label>
                    </div>
                  </div>
                </MenuItem>
              ))}

              <MenuItem value={3} className="SlImLsting">
                <div className="CreateNewButton">
                  <div className="CreateNewIcon">
                    <AddRoundedIcon />
                  </div>
                  <div className="CreateNewLabel">Create new school</div>
                </div>
              </MenuItem>

              <div className="SlImLstingTitle">LEARNER ACCOUNT</div>

              <MenuItem value={0} className="SlImLsting">
                <div className="SlImLst">
                  <RadioButtonUncheckedRoundedIcon className="listUnCheck" />
                  <RadioButtonCheckedRoundedIcon className="listCheck" />
                  <Avatar alt={userData.firstName} src={userData.image} />
                  <div className="SlImLstlabel">
                    {truncateText(
                      userData.firstName + ' ' + userData.lastName,
                      10,
                    )}{' '}
                  </div>
                </div>
              </MenuItem>
            </Select>
          </FormControl>
        </div>
      ) : (
        ''
      )}
    </>
  );
}

export default PortalSwitcher;
