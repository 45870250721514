import React from 'react'
import Table from '../../../Table/Table';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { useGetAllGroupsQuery } from '../../../../redux/features/groups/groupsApi';
import useProtected from '../../../../hooks/useProtected';
import { useEffect } from 'react';
import { useState } from 'react';

const SelectGroups = ({ selectedList, selectGroups }) => {
    const { data, isLoading, isError, error } = useGetAllGroupsQuery(undefined, {
        refetchOnMountOrArgChange: true,
    });
    useProtected(error);

    const [rows, setRows] = useState([]);
    const [selectedGroups, setSelectedGroups] = useState(
        selectedList?.length > 0 ? selectedList : [],
    );

    useEffect(() => {
        selectGroups(rows.filter(innerItem => selectedGroups.includes(innerItem.item._id)).map(rowItem => rowItem.item));
    }, [selectedGroups])

    const convertDataToCellRows = (arr) => {
        if (arr.length > 0) {
            return arr.map((item) => ({
                item: item,
                cell: [
                    {
                        linkText: item?.name || 'N/A',
                        linkTextStatus: true,
                        link: `/group-details/${item._id}`,
                        Check: true,
                        group_id: item._id,
                    },
                    {
                        Status: true,
                        background: '#EDEDEF',
                        color: '#676C7B',
                        label: `${item?.users.length || 0} users`,
                    },
                ],
            }));
        } else {
            return [];
        }
    };

    useEffect(() => {
        if (!isLoading && !isError) {
            setRows(convertDataToCellRows(data.body.data));
        }
    }, [data, isLoading, isError]);

    const headCells = [
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Name',
            width: '50%',
            textAlign: 'left',
        },
        {
            id: 'name',
            numeric: false,
            disablePadding: true,
            label: 'Users',
            width: '50%',
            textAlign: 'left',
        },
    ];

    // const rows = [
    //     {
    //         cell: [

    //             { textBold: '1st Grade' },
    //             {
    //                 Status: true,
    //                 background: '#EDEDEF',
    //                 color: '#676C7B',
    //                 label: '200 users',
    //             },
    //         ],
    //     },
    //     {
    //         cell: [

    //             { textBold: 'Staff' },
    //             {
    //                 Status: true,
    //                 background: '#EDEDEF',
    //                 color: '#676C7B',
    //                 label: '150 users',
    //             },
    //         ],
    //     },
    // ];

    return (
        <>
            <div className="TableSection">
                <Table
                    title="Course"
                    tableHeader={false}
                    AddButton={false}
                    addLabel=""
                    innerTitle=""
                    link="add-courses"
                    addLabelInner="Add Payment"
                    icon={<AddRoundedIcon />}
                    // CheckboxDelete={true}
                    headCells={headCells}
                    rows={rows}
                    Checkbox={true}
                    footer={true}
                    innerHeader={true}
                    AddButtonInner={false}
                    InnerButtonColor="#004FE0"
                    InnerButtonTextColor="#fff"
                    InnderButtonIcon={<AddRoundedIcon />}
                    showSearchBox={true}
                    AddButtonOutLine={true}
                    handleCheckItem={setSelectedGroups}
                    //onClickOutlineButton={handleOpenAttendanceEntry}
                    OutLineButtonLabel="Add entry"
                    isDataLoading={isLoading}
                    customCellObjectId="group_id"
                />
            </div>
        </>
    )
}

export default SelectGroups