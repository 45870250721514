import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import TextField from '../../../components/Form/TextField/TextField';
import { validateFormData } from '../../../utils/form';
import {
  useAddAttendanceCodeMutation,
  useGetAttendanceCodeByIdQuery,
  useUpdateAttendanceCodeMutation,
} from '../../../redux/features/attendence/attendanceApi';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import TextArea from '../../../components/Form/TextArea/TextArea';
import '../../../assets/css/users.css';
import { Button } from '@material-ui/core';
import Loader from '../../../components/Loader/Loader';
import {
  useAddGroupsMutation,
  useCheckGroupNameMutation,
  useGetGroupDetailsQuery,
  useUpdateGroupsMutation,
} from '../../../redux/features/groups/groupsApi';
import DrawerFooterActions from '../../../components/SideDrawer/DrawerFooterActions';
import { useNavigate } from 'react-router-dom';

const AddNewGroup = ({ closeDrawer, id }) => {
  const [addGroup, addGroupOptions] = useAddGroupsMutation();
  const [updateGroup, updateGroupOptions] = useUpdateGroupsMutation();
  const [checkGroupName, checkGroupNameOptions] = useCheckGroupNameMutation();

  const {
    data: groupDetails,
    isLoading: isGroupDetailsLoading,
    error: groupDetailsError,
  } = useGetGroupDetailsQuery(id, {
    skip: !id,
  });

  useProtected(groupDetailsError);
  useProtected(addGroupOptions.error);
  useProtected(updateGroupOptions.error);
  useProtected(checkGroupNameOptions.error);

  const [state, setState] = React.useState({
    checkedA: true,
  });
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [nameExist, setNameExist] = useState(false);

  useEffect(() => {
    if (id && groupDetails) {
      const data = groupDetails.body.data;
      setName(data.name);
      setDescription(data.description);
    }
  }, [id, groupDetails]);

  const [errors, setErrors] = useState({
    name: ''
  });

  const handleInputChange = (e) => (setState) => {
    setErrors((prev) => {
      prev[e.target.name] = '';
      return prev;
    });
    setState(e.target.value);
  };
  const navigate = useNavigate();

  const handleAddAttendanceCode = async () => {
    const data = {
      name,
      description,
    };

    const isValid = validateFormData(data, setErrors, {
      name: 'Enter group name'
    });

    if (name == groupDetails?.body?.data?.name) {
      setNameExist(false)
    } else {
      let checkResult = await checkGroupName({ name })
      if (checkResult?.data && checkResult?.data?.body?.data) {

        setErrors((prevErrors) => ({
          ...prevErrors,
          name: 'Group name already exists'
        }));
        setNameExist(true)
        return;
      } else {
        setNameExist(false)
      }

    }

    if (!isValid || nameExist) {
      return;
    }

    let result = null;
    if (id) {
      result = await updateGroup({ ...data, id });
    } else {
      result = await addGroup(data);
    }

    if (result?.data) {
      if (id) {
        toast.success('Group updated successfully');
      } else {
        toast.success('Group created');
      }
      setName('');
      setDescription('');
      closeDrawer();
      navigate(`/group-details/${result?.data?.body?.data?._id}`)
    } else {
      toast.error(result?.data?.message);
    }
  };
  const checkGroupNameHandler = async (group_name) => {
    const groupName = group_name
    if (groupName == groupDetails?.body?.data?.name) {
      setNameExist(false)
      return false
    }

    let result = await checkGroupName({ name: groupName })
    if (result?.data && result?.data?.body?.data) {

      setErrors((prevErrors) => ({
        ...prevErrors,
        name: 'Group name already exists'
      }));
      setNameExist(true)
    } else {
      setNameExist(false)
    }
  }
  return (
    <>
      <div className="">
        {isGroupDetailsLoading ||
          addGroupOptions.isLoading ||
          updateGroupOptions.isLoading ? (
          <Loader
            height="80px"
            width="80px"
            borderWidth="8px"
            isFullWidth={true}
            isOverlay={true}
          />
        ) : (
          <Grid container spacing={3}>
            <Grid item md={12} xs={12}>
              <TextField
                label="Group Name"
                validation={true}
                CharAlignMid="CharAlignMid"
                Uppercase="Uppercase"
                count={60}
                name={'name'}
                handleChange={(val, e) => handleInputChange(e)(setName)}
                helperText={errors?.name}
                error={errors?.name}
                value={name}
                onBlur={(e) => checkGroupNameHandler(e.target.value)}
              //value={courseData.Id}
              //handleChange={handleChanges("Id")}
              />
            </Grid>

            <Grid item md={12} xs={12}>
              <TextArea
                FieldLabel={true}
                label="Description"
                CharAlignMid="CharAlignMid"
                Uppercase="Uppercase"
                count={100}
                name={'description'}
                handleChange={(e) => handleInputChange(e)(setDescription)}
                value={description}
              //value={courseData.Id}
              //handleChange={handleChanges("Id")}
              />
            </Grid>
          </Grid>
        )}
      </div>
      <DrawerFooterActions
        isLoading={addGroupOptions.isLoading || updateGroupOptions.isLoading}
        handleClose={closeDrawer}
        handleAction={handleAddAttendanceCode}
        closeBtnLabel={'CANCEL'}
        mobileCloseBtnLabel={'Mobile Close'}
        actionButtonLabel={id ? 'Update' : 'CREATE GROUP'}
      />
    </>
  );
};

export default AddNewGroup;
