import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import config from '../../config';

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: config.api_url,
    prepareHeaders: (headers, { getState, extra }) => {
      const token = getState().auth.token;
      // Conditionally set the Authorization header based on the endpoint requirement
      console.log('auth', getState(), { extra });
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
  }),
  tagTypes: [
    'AttendanceCode',
    'Attendance',
    'Groups',
    'InviteUser',
    'InviteUserDetails',
    'OrderId',
    'Media',
    'UserDetails'
  ],
  endpoints: () => ({}),
});
