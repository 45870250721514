import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import RemoveRoundedIcon from '@mui/icons-material/RemoveRounded';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import './CustomAccordion.css';

const CustomAccordion = (props) => {
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };
  return (
    <div>
      <Accordion
        className="customAccordionContainer"
        expanded={expanded === 'panel1'}
        onChange={handleChange('panel1')}
        disabled={props.disabled}
      >
        <AccordionSummary
          expandIcon={
            <span
              className={`IconContainer ${expanded ? 'expandedLabel' : 'collapsedLabel'
                }`}
            >
              {expanded ? (
                <RemoveRoundedIcon className="minusIcon" />
              ) : (
                <AddRoundedIcon className="addIcon" />
              )}
            </span>
          }
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography
            className={`accordionLabel ${expanded ? 'expandedLabel' : 'collapsedLabel'
              }`}
          >
            {props.ModalLabel}
          </Typography>
          <div className="varifyContainer">
            {
              props?.isCompleted ? (
                <CheckCircleRoundedIcon className='tick' />
              ) : (
                <FiberManualRecordIcon className="dot" />
              )
            }

          </div>
        </AccordionSummary>
        <AccordionDetails sx={{ width: '100% !important' }}>
          {props.ModalBody}
        </AccordionDetails>
      </Accordion>
    </div>
  );
};

export default CustomAccordion;
