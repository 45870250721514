import React from 'react';
import { Checkbox } from '@mui/material';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import './CheckBox.css';

// const [state, setState] = React.useState({
//   checkedA: true,
// });

// const handleChangeCheck = (event) => {
//   setState({ ...state, [event.target.name]: event.target.checked });
// };

{
  /* <CheckBox
  label="Set this as most popular"
  checked={state.checkedB}
  onChange={handleChangeCheck}
  color="primary"
/> */
}

function CheckBox(props) {
  const { checked, onChange, label, color, disabled, name, helperText } = props;

  return (
    <div className="CheckBox">
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={onChange}
            name={name}
            color={color}
            disabled={disabled}
          />
        }
        label={label}
      />
      {helperText && <p className="error-msg">{helperText}</p>}
    </div>
  );
}

export default CheckBox;
