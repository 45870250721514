import React, { useState, useEffect } from "react";


import Buttons from "../../../components/Form/Button/Button";
import Grid from "@mui/material/Grid";
import TextField from "../../../components/Form/TextField/TextField";
import SelectField from "../../../components/Form/SelectField/SelectField";
import Datefield from "../../../components/Form/Datefield/Datefield";
import DateTimePickerField from "../../../components/Form/DateTimePicker/DateTimePicker";
import FieldPassword from '../../../components/Form/FieldPassword/FieldPassword';
import PasswordStrength from '../../../components/Form/FieldPassword/PasswordStrength';

import ImageCrop from '../../../components/Form/ImageCrop/ImageCrop';


function FormsExample(props) {

  //== Text Field
  const handleChanges = (fieldName) => (event) => {
    const fieldData = event.target.value;
  };

  //== Password Strenght
  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState(true);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [token, setToken] = useState("");


//== Select Field

  const [age, setAge] = React.useState('');
  const handleChangesSelect = (event) => {
    setAge(event.target.value);
  };

  const options = [
    {
      label: "Twenty",
      value: "Twenty",
    },

    {
      label: "Twentydd",
      value: "Twentyd",
    },

  ]


  return (
    <>
    
    

      <Grid container spacing={3}>

      <Grid item xs={12} md={12}>
         <h1>Form Fields</h1>
        </Grid>

        <Grid item xs={6} md={6}>
          <TextField
            label="Text Field"
            required={true}
            count={60}
            value="value"
            handleChange={handleChanges}
            error
          />
        </Grid>

        <Grid item xs={6} md={6}>
          <FieldPassword
            label="Password"
            placeholder="Password"
            value="123456"
            handleChange={handleChanges("password")}
          />
        </Grid>

        <Grid item xs={6} md={6}>
          <PasswordStrength
            label="Password Stenght"
            placeholder="New Password"
            handlePasswordText={setPassword}
            handlePasswordValidation={setPasswordValidation}
          />
        </Grid>

        {/* <Grid item xs={6} md={6}>
            <AddTextField
              placeholder="Type here"
              options={option}
              handleChange={handleChangeWithData}
              maxText="80"
              minItem={2}
              maxItem={6}
              primaryField="objId"
              textField="title"
              addButtonLabel="Add More Objective"
            />
        </Grid> */}

        <Grid item xs={6} md={6}>
          < SelectField
            label="Select Field"
            options={options}
            handleChange={handleChangesSelect}
          />
        </Grid>

        {/* <Grid item xs={6} md={6}>
          < Datefield
            label="Date Filed"
          />
        </Grid>

        <Grid item xs={6} md={6}>
          < DateTimePickerField
            label="Date and Time Field"
          />
        </Grid> */}

      </Grid>


      <ImageCrop />

    </>
  );
}


export default FormsExample;

