import React, { useState } from "react";
import { Box, Button, IconButton } from "@mui/material";
import VerifiedUser from "@mui/icons-material/Verified";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import VerifiedRoundedIcon from "@mui/icons-material/VerifiedRounded";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";

import { FiSearch } from "react-icons/fi";
import CancelRoundedIcon from "@mui/icons-material/CancelRounded";
import FiberManualRecordRoundedIcon from "@mui/icons-material/FiberManualRecordRounded";
import DownloadForOfflineOutlinedIcon from '@mui/icons-material/DownloadForOfflineOutlined';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import "./User.css";
import SideDrawer from "../../SideDrawer/SideDrawer";
import UserTable from "./UserTable/UserTable";
import UserDetails from "./UserDetails/UserDetails";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8",
    },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

const User = () => {
  //=======  Drawer Start ===== //
  const [openDrawer, setOpen] = useState(false);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  //=======  Drawer End ===== //


  //=======  Drawer With Sub Drawer Start ===== //
  const [openDrawerSub, setOpenSub] = useState(false);
  const handleDrawerOpenSub = () => {
    setOpenSub(true);
  };
  const handleDrawerCloseSub = () => {
    setOpenSub(false);
  };
  //=======  Drawer With Sub Drawer End ===== //





  //=======  Drawer With Sub Menu ===== //
  const tabData = [
    {
      lebel: "Upload from device",
      icon: <DownloadForOfflineOutlinedIcon />,
    },

    {
      lebel: "YouTube video",
      icon: <DownloadForOfflineOutlinedIcon />,
    },
  ]

  const panelTab = [
    {
      id: 0,
      panleBody: <p>Body</p>,
      drawerWidth: '1100px',
      SaveButtonLabel: "Upload File",
      //clickSaveButton:clickSaveButton,
      CancelButtonLabel: "Cancel",

      Checkbox: false,
      CheckboxLabel: "Add another after saving",
      //onClickCheckbox:onClickCheckbox,

      DeleteButton: false,
      DeleteLebel: "Delete Permanently",
      //onClickDelete:onClickDelete,

      SubDrawerBodyWithTab: "Sub Drawer Body With Tab",

    },

    {
      id: 1,
      panleBody: <p>scsdsfvjsdvlds</p>,
      drawerWidth: '1100px',
      SaveButtonLabel: "Import From YouTube",
      //clickSaveButton:clickSaveButton,
      cancelButtonLabel: "Cancel",
    },

  ]
  //=======  Drawer With Sub Menu ===== //


  return (
    <div>
      <Button
        startIcon={<FiSearch style={{ color: "black" }} />}
        sx={{
          padding: "12px 16px",
          width: "100%",
          color: "#ACAAAF",
          background: "#F2F0F4",
          boxShadow: "none",
          borderRadius: "8px",
          justifyContent: "flex-start",
          "&:hover": {
            boxShadow: "none",
            background: "#F2F0F4",
          },
        }}
        variant="contained"
        onClick={handleDrawerOpenSub}
      >
        Select user
      </Button>
      <br />
      <br />
      <div className="UserContainer">
        <div className="User_Info">
          <FiSearch style={{ color: "black", fontSize: "22px" }} />
          <b>Shayan Mahmud</b>
          <FiberManualRecordRoundedIcon
            fontSize="small"
            sx={{ color: "#D9D9D9", fontSize: "12px" }}
          />
          <p>ID # 000022</p>
        </div>
        <IconButton sx={{ p: 0 }}>
          <CancelRoundedIcon />
        </IconButton>
      </div>
      <br />
      {/* ---------------------- table -------------------- */}
      <div>
        <div className="TableContainer oddEvenTable">
          {/* <div className="TableInnerHeader">
            <h3>Access details</h3>
          </div> */}
          <TableContainer component={Paper}>
            <Table aria-label="customized table">
              <TableBody>
                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    ID
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <div className="cellwithchip">000022</div>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    User type
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <div className="cellwithchip">Staff</div>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    Gender
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <div className="cellwithchip">Male</div>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    Birthday
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <div className="cellwithchip">January 1980</div>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    Email
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <div className="cellwithchip">abdullahalrazi@gmail.com</div>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    Phone number
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <div className="cellwithchip">+1 (123) 123-1234</div>
                  </StyledTableCell>
                </StyledTableRow>

                <StyledTableRow>
                  <StyledTableCell align="left" className="TableLabel">
                    Address
                  </StyledTableCell>
                  <StyledTableCell align="left" className="TableInfo">
                    <div className="cellwithchip">
                      1234 Street Address City, NY 11210
                    </div>
                  </StyledTableCell>
                </StyledTableRow>
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      </div>

      {/* Drawer with Sub Drawer Start*/}
      <SideDrawer
        title="Search user"
        sideOpen="right"
        open={openDrawerSub}
        handleDrawerClose={handleDrawerCloseSub}

        LeftButton={false}
        ButtonLeft="Upload New"
        ButtonLeftIcon={<FileUploadOutlinedIcon />}
        FooterWithoutTab={true}
        panelTab={panelTab}
        tabData={tabData}
        arrowIcon={false}

        TabsStatus={false}
        CheckBoxTabsStatus={false}
        TabsPannelStatus={false}

        SubDrawerWithOutTab={true}
        SubDrawerTabSatus={false}
        SubDrawerButtonSatus={true}
        SubDrawer="SubDrawer"
        SubDrawerWithOutTabBody={<UserDetails />}

        TitleUnderHeaderStatus={false}
        TitleUnderHeader="Edit Payment"
        SubTitle="Payment Method*"

        // ---------- body ------------- 
        body={<UserTable />}

        // ---------- footer ------------- 
        CheckboxLabel="Add another after saving"
        Checkbox={false}
        DeleteButton={false}
        // deleteText="Delete Permanently"
        // onClickDelete={onClickDelete}
        ButtonLabel="SELECT"
        cancelButtonLabel="CANCEL"
        drawerWidth='1100px'

      />
    </div>
  );
};

export default User;
