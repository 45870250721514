import React, { useContext, useState } from "react";
import Grid from '@mui/material/Grid';
import TextField from "../../components/Form/TextField/TextField";
import PasswordStrength from "../../components/Form/FieldPassword/PasswordStrength";
import SecurityOutlinedIcon from '@mui/icons-material/SecurityOutlined';
// import AlrtMessage from '../../../components/AlrtMessage/AlrtMessage';
import Buttons from "../../components/Form/Button/Button";
import "../../assets/css/form.css"
import LogoHeader from "./LogoHeader/LogoHeader";
import { useNavigate } from "react-router-dom";
import { calculatePasswordStrength, validateFormData } from "../../utils/form";
import { toast } from "react-toastify";
import axiosApi from "../../utils/axios";
import AlrtMessage from "../../components/Form/AlrtMessage/AlertMessage";
import { useSelector } from "react-redux";
import { useChangeUserCurrentPasswordMutation } from "../../redux/features/users/userApi";
import { PasswordContext } from "../../context/PaswordProvider";

function CreateNewPassword(props) {
  const { _id: id } = useSelector(state => state.auth.user);
  const navigate = useNavigate()

  //context api
  const { password: oldPassword } = useContext(PasswordContext)
  const [changePassword, options] = useChangeUserCurrentPasswordMutation()

  const [password, setPassword] = useState("");
  const [passwordValidation, setPasswordValidation] = useState(true);
  const [alertOptions, setAlertOptions] = useState({
    msg: '',
    type: 'error',
  });
  const [errors, setErrors] = useState({
    password: ''
  });

  const signInHandler = async () => {

    const isValid = validateFormData({ password }, setErrors, {
      password: 'Enter new password',
    });
    if (!isValid) {
      return;
    }
    const strength = calculatePasswordStrength(password);
    if (strength < 4) {
      let err = { ...errors };
      err['password'] = 'Please enter a strong password';
      setErrors(err);
      return;
    }

    try {
      let data = {
        oldPassword: oldPassword,
        newPassword: password
      }

      const result = await changePassword(data)
      // console.log(result, 328, data)
      if (result.data.error) {
        setAlertOptions({
          msg: result.data.message,
          type: 'error',
        });
        return;
      }

      toast.success('Password changed successfully. Redirecting you now...')
      setTimeout(() => {
        navigate(`/signin`);
      }, 2000);
    } catch (err) {
      setAlertOptions({
        msg: err.message,
        type: 'error',
      });
    }
  }
  const handleAlertClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setAlertOptions({
      msg: '',
      type: '',
    });
  };


  return (
    <>

      <div className="SignContainer">
        <LogoHeader />
        <div className="SignContainerInner">

          <div className="SignForm">
            <h1>Create new password</h1>
            <AlrtMessage
              open={Boolean(alertOptions.msg)}
              message={alertOptions.msg}
              severity={alertOptions.type}
              closeHandler={handleAlertClose}
            />
            <Grid container spacing={3}>


              {/* <Grid item xs={12} md={12}>
                <AlrtMessage open={alertOpen} message={alertMessage} severity={alertSeverity} closeHandler={alertCloseHandler}/>
            </Grid> */}

              <Grid item xs={12} md={12}>
                <PasswordStrength
                  handlePasswordValidation={setPasswordValidation}
                  placeholder="Password"
                  handlePasswordText={(value) => {
                    setErrors((prev) => {
                      prev.password = '';
                      return prev;
                    });
                    setPassword(value);
                  }}
                  helperText={errors?.password}
                  error={errors?.password}
                />
              </Grid>

              <Grid item xs={12} md={12}>
                <Buttons
                  label="Create Account"
                  buttonColor="#FFB606"
                  border="#FFB606 1px solid"
                  color="#000000"
                  width="100%"
                  height="48px"
                  fontSize="14px"
                  link=""
                  onClick={() => signInHandler()}
                />
              </Grid>

            </Grid>
          </div>
        </div>

        <div className="SignFormFooter">
          <SecurityOutlinedIcon />
          <span>SECURE SSL ENCRYPTION</span>
        </div>

      </div>
    </>

  );
}


export default CreateNewPassword;

