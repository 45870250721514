import { api } from "../../api/apiSlice";

const applicationApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getApplicationListByUserId: builder.query({
            query: (userId) => ({
                url: `/new-application/findApplicationByUserId/${userId}`
            })
        })
    })
});

export const { useGetApplicationListByUserIdQuery, useLazyGetApplicationListByUserIdQuery } = applicationApi;