// PasswordContext.js
import React, { createContext, useState } from 'react';

//contextAPi
export const PasswordContext = createContext(null);

const PasswordProvider = ({ children }) => {
  const [password, setPassword] = useState('');

  const setPasswordValue = (newPassword) => {
    setPassword(newPassword);
  };

  const passwordInfo = { password, setPasswordValue };

  return (
    <PasswordContext.Provider value={passwordInfo}>
      {children}
    </PasswordContext.Provider>
  );
};
export default PasswordProvider;
