import React, { useState, useEffect } from "react";
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import styles from './FieldPassword.module.css';
import { calculatePasswordStrength } from "../../../utils/form";



function PasswordStrength(props) {

  const { label, validation, placeholder, handlePasswordText, handlePasswordValidation, helperText } = props;

  const [type, setType] = useState('password');
  const [password, setPassword] = useState('');

  // validated states
  const [lowerValidated, setLowerValidated] = useState(false);
  const [upperValidated, setUpperValidated] = useState(false);
  const [numberValidated, setNumberValidated] = useState(false);
  // const [specialValidated, setSpecialValidated] = useState(false);
  const [lengthValidated, setLengthValidated] = useState(false);

  const handleChange = (value) => {

    setPassword(value);
    setInputValue(value);



    const lower = new RegExp('(?=.*[a-z])');
    const upper = new RegExp('(?=.*[A-Z])');
    const number = new RegExp('(?=.*[0-9])');
    // const special = new RegExp('(?=.*[!@#\$%\^&\*])');
    const length = new RegExp('(?=.{8,})')

    if (lower.test(value)) {
      setLowerValidated(true);
    }
    else {
      setLowerValidated(false);
    }
    if (upper.test(value)) {
      setUpperValidated(true);
    }
    else {
      setUpperValidated(false);
    }
    if (number.test(value)) {
      setNumberValidated(true);
    }
    else {
      setNumberValidated(false);
    }
    // if (special.test(value)) {
    //   setSpecialValidated(true);
    // }
    // else {
    //   setSpecialValidated(false);
    // }
    if (length.test(value)) {
      setLengthValidated(true);
    }
    else {
      setLengthValidated(false);
    }




  }

  const handleResult = (value) => {
    // set password
    handlePasswordText(value);
    // set password validation status
    console.log(lowerValidated, upperValidated, numberValidated, lengthValidated);
    if (lowerValidated == false || upperValidated == false || numberValidated == false || lengthValidated == false) {
      handlePasswordValidation(false);
    } else {
      handlePasswordValidation(true);
    }

  }

  useEffect(() => {
    // set password and it's validation status
    handleResult(password);
  }, [password]);

  const [inputValue, setInputValue] = useState('');



  // ========= Progress bar Start ===============//

  const [strength, setStrength] = useState('');

  const handlePasswordChange = (e) => {
    const newPassword = e.target.value;
    setPassword(newPassword);
    calculateStrength(newPassword);
    handleChange(e.target.value);
  };

  const getProgressBarColor = () => {
    const strength = calculatePasswordStrength(password);

    switch (strength) {
      case 0:
        return '';
      case 1:
        return '';
      case 2:
        return '';
      case 3:
        return '';
    }
  };

  const calculateStrength = (password) => {
    let score = 0;

    if (/[A-Z]/.test(password)) {
      score += 1;
    }

    if (/[a-z]/.test(password)) {
      score += 1;
    }

    if (/[0-9]/.test(password)) {
      score += 1;
    }

    if (password.length > 8) {
      score += 1;
    }

    // Set the strength based on the score

    if (score === 1) {
      setStrength('Weak');
    } else if (score === 3) {
      setStrength('Good');
    } else if (score === 4) {
      setStrength('Strong');
    }
  };

  const isPasswordValid = () => {
    // Perform additional validation if needed
    return password.length >= 8 && strength === 'Very Strong';
  };




  const renderProgressBar = () => {
    const strength = calculatePasswordStrength(password);
    const progressBarWidth = (strength / 4) * 100;

    return (
      <div className="StrengthBar">
        <span className="line_1"></span>
        <span className="line_2"></span>
        <span className="line_3"></span>
      </div>
    );
  };

  // ========= Progress bar End ===============//


  return (
    <div className={styles.FieldPasswordC}>
      <div className={styles.FieldLabel}> {label} {validation && <span>*</span>}</div>

      <div className={styles.wrapper}>

        {/* box containing input field and validation tracker  */}
        <div className={styles.box}>

          {/* input */}
          <div className={styles.FieldPassword}>

            <div className={styles.PasswordFieldCont}>
              <input
                className={styles.customInput}
                type={type}
                //onChange={(e) => handleChange(e.target.value)}
                placeholder={placeholder}
                onChange={handlePasswordChange}
              />
              <div className={styles.PassIcon}>
                {type === "password" ? (
                  <span className={styles.iconSpan} onClick={() => setType("text")}>
                    <VisibilityOff />
                  </span>
                ) : (
                  <span className={styles.iconSpan} onClick={() => setType("password")}>
                    <Visibility />

                  </span>
                )}
              </div>
            </div>
            {
              helperText && <p className="error-msg">{helperText}</p>
            }
            


            {password &&
              <>
              <div className="PassStrengthBarContainer">
                <div className="PassStrengthBar">
                  <div className="PassStrengthTitle">Password strength</div> <div className={strength}>{strength}</div>
                </div>
                <div className='passwordStrengthProgressBar'>
                  <div className={strength}>
                    {renderProgressBar()}
                  </div>

                </div>
                </div>

              </>
            }



          </div>

          {/* validation tracker */}
          {password &&
            <main className={styles.trackerBox}>

              <div className={lowerValidated ? 'validated' : 'not-validated'}>
                {lowerValidated ? (
                  <span className={styles["listIcon green"]}>
                    <CheckRoundedIcon />
                  </span>
                ) : (
                  <span className={styles.listIcon}>
                    <CloseRoundedIcon />
                  </span>
                )}
                At least one lowercase letter (a-z)
              </div>

              <div className={upperValidated ? 'validated' : 'not-validated'}>
                {upperValidated ? (
                  <span className={styles.listIcon}>
                    <CheckRoundedIcon />
                  </span>
                ) : (
                  <span className={styles.listIcon}>
                    <CloseRoundedIcon />
                  </span>
                )}
                At least one uppercase letter (A-Z)
              </div>

              <div className={numberValidated ? 'validated' : 'not-validated'}>
                {numberValidated ? (
                  <span className={styles["listIcon green"]}>
                    <CheckRoundedIcon />
                  </span>
                ) : (
                  <span className={styles.listIcon}>
                    <CloseRoundedIcon />
                  </span>
                )}
                At least one number (0-9)
              </div>

              {/* <div className={specialValidated ? 'validated' : 'not-validated'}>
              {specialValidated ? (
                <span className={styles["listIcon green"]}>
                  <CheckRoundedIcon />
                </span>
              ) : (
                <span className={styles.listIcon}>
                  <CloseRoundedIcon />
                </span>
              )}
              At least one special character
            </div> */}

              <div className={lengthValidated ? 'validated' : 'not-validated'}>
                {lengthValidated ? (
                  <span className={styles["listIcon green"]}>
                    <CheckRoundedIcon />
                  </span>
                ) : (
                  <span className={styles.listIcon}>
                    <CloseRoundedIcon />
                  </span>
                )}
                At least 8 characters
              </div>
            </main>}
        </div>

      </div>
    </div>
  );
}


export default PasswordStrength;