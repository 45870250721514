import { Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TextField from '../../Form/TextField/TextField';
import Buttons from '../../Form/Button/Button';
import SelectField from '../../Form/SelectField/SelectField';
import CheckBox from '../../Form/CheckBox/CheckBox';
import { useUpdateOrderDetailsShipToMutation } from '../../../redux/features/order/orderApi';
import useProtected from '../../../hooks/useProtected';
import { toast } from 'react-toastify';
import Loader from '../../Loader/Loader';
import { countries } from '../../../assets/data/countries';

const ShippingDetails = ({ shippingDetails }) => {
  const [updateOrderDetailsShipTo, updateOrderDetailsShipToOptions] =
    useUpdateOrderDetailsShipToMutation();
  useProtected(updateOrderDetailsShipToOptions.error);

  const [innerDetails, setInnerDetails] = useState(null);

  useEffect(() => {
    const details = { ...shippingDetails };
    delete details._id;

    setInnerDetails(details);
  }, [shippingDetails]);

  const [checkbox, setCheckbox] = React.useState({ checkedA: true });

  const handleInputChange = (e) => {
    let details = innerDetails;
    details = { ...details, [e.target.name]: e.target.value };
    setInnerDetails(details);
  };

  const handleChangeCheck = (e) => {
    let details = innerDetails;
    details = { ...details, [e.target.name]: e.target.checked ? true : false };
    setInnerDetails(details);
  };

  const handleUpdateOrderDetailsShippingInfo = async () => {
    const data = {
      name: innerDetails?.name,
      country: innerDetails?.country,
      addressLine1: innerDetails?.addressLine1,
      addressLine2: innerDetails?.addressLine2,
      city: innerDetails?.city,
      state: innerDetails?.state,
      zip: innerDetails?.zip,
    };

    // const isValid = validateFormData(data, setErrors, {
    //   orderName: 'Enter Order Name',
    // });

    // if (!isValid) {
    //   return;
    // }

    const result = await updateOrderDetailsShipTo({
      shipTo: { ...data },
      id: shippingDetails?._id,
    });

    if (!result?.data?.error) {
      toast.success(result?.data.message);
      setInnerDetails(null);
    } else {
      toast.success(result?.data?.message);
    }
  };

  console.log("test", innerDetails?.country)

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            name="name"
            value={innerDetails?.name}
            label="Name"
            validation={true}
            handleChange={(val, e) => handleInputChange(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <SelectField
            FieldLabel
            label="Country"
            options={countries}
            handleChange={handleInputChange}
            name="country"
            selectedOption={innerDetails?.country}
          // selectedOption={'BD'}
          />
        </Grid>
        <Grid item xs={12} md={8}>
          <TextField
            name="addressLine1"
            value={innerDetails?.addressLine1}
            label="Street address"
            validation={true}
            handleChange={(val, e) => handleInputChange(e)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="addressLine2"
            value={innerDetails?.addressLine2}
            label="Fl/Suite No. (optional)"
            validation={true}
            handleChange={(val, e) => handleInputChange(e)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="city"
            value={innerDetails?.city}
            label="City/Town"
            validation={true}
            handleChange={(val, e) => handleInputChange(e)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="state"
            value={innerDetails?.state}
            label="State/Province"
            validation={true}
            handleChange={(val, e) => handleInputChange(e)}
          />
        </Grid>
        <Grid item xs={12} md={4}>
          <TextField
            name="zip"
            value={innerDetails?.zip}
            label="Zip/Post Code"
            validation={true}
            handleChange={(val, e) => handleInputChange(e)}
          />
        </Grid>
        <Grid item xs={12}>
          <CheckBox
            label="Set this as most popular"
            name="saveAddress "
            checked={Boolean(innerDetails?.saveAddress)}
            onChange={handleChangeCheck}
            color="success"
          />
        </Grid>
        <Grid item xs={12} md={12}>
          <Buttons
            label="Continue"
            buttonColor="#FFB606"
            color="#000000"
            width="100%"
            height="48px"
            fontSize="14px"
            // link={item.link}
            disabled={
              !innerDetails?.name ||
              !innerDetails?.addressLine1 ||
              !innerDetails?.addressLine2 ||
              !innerDetails?.city ||
              !innerDetails?.state ||
              !innerDetails?.zip ||
              updateOrderDetailsShipToOptions.isLoading
            }
            onClick={handleUpdateOrderDetailsShippingInfo}
            icon={
              updateOrderDetailsShipToOptions.isLoading ? (
                <Loader height="20px" width="20px" />
              ) : null
            }
          />
        </Grid>
      </Grid>
    </>
  );
};

export default ShippingDetails;
