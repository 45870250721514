import * as React from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import './Snackbar.css';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function CustomizedSnackbar(props) {

  const {open, severity, message, handleClose} = props;

  return (
    <Stack spacing={2} sx={{ width: '100%' }} className="SnackbarMessage">
      <Snackbar anchorOrigin={ {vertical: 'top', horizontal: 'center'} } open={open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={severity} sx={{ width: '100%' }} style={{ background: props.background }}>
          {message}
        </Alert>
      </Snackbar>
    </Stack>
  );
}