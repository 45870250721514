import React from "react";
import VerifiedUser from "@mui/icons-material/Verified";
import { withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import person from "../../../../assets/imgs/randomPerson.avif";
import "./UserDetails.css";
import { Alert } from "@mui/material";

// ================= Table OD and EVEN Code ========//
const StyledTableCell = withStyles((theme) => ({}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: "#F8F8F8",
    },
  },
}))(TableRow);
// ================= Table OD and EVEN Code ========//

const UserDetails = () => {
  return (
    <>
      <div className="useDetails_container">
        <h3>User Details</h3>
        <div className="useDetails_userprofile">
          <img src={person} alt="user" />
          <div>
            <p>Shayan Mahmud</p>
            <span
              className="status rounde statussmall"
              style={{ background: "#E1F2E8", color: "#0B9444" }}
            >
              Active
            </span>
            <span
              className="status rounde statussmall"
              style={{ background: "#FCE7E9", color: "#E63946" }}
            >
              Inactive
            </span>
          </div>
        </div>
        <Alert variant="filled" severity="error" sx={{my: 3}}>
        User must be active in order to print ID
        </Alert>
        {/* ----------------  user info table ---------------- */}
        <div>
          <div className="TableContainer oddEvenTable">
            {/* <div className="TableInnerHeader">
            <h3>Access details</h3>
          </div> */}
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableBody>
                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      ID
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="cellwithchip">000022</div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      User type
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="cellwithchip">Staff</div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      Gender
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="cellwithchip">Male</div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      Birthday
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="cellwithchip">January 1980</div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      Email
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="cellwithchip">
                        abdullahalrazi@gmail.com
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      Phone number
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="cellwithchip">+1 (123) 123-1234</div>
                    </StyledTableCell>
                  </StyledTableRow>

                  <StyledTableRow>
                    <StyledTableCell align="left" className="TableLabel">
                      Address
                    </StyledTableCell>
                    <StyledTableCell align="left" className="TableInfo">
                      <div className="cellwithchip">
                        1234 Street Address City, NY 11210
                      </div>
                    </StyledTableCell>
                  </StyledTableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </div>
        </div>
      </div>
    </>
  );
};

export default UserDetails;
