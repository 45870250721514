import { api } from '../../api/apiSlice';

const userTypeApi = api.injectEndpoints({
    endpoints: (builder) => ({
        getUserTypes: builder.query({
            query: () => ({
                url: '/user-type/all',
            }),
            providesTags: ['UserTypes'],
        }),
    }),
});

export const {
    useGetUserTypesQuery
} = userTypeApi;
