import React, { useEffect, useState } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import Buttons from '../Form/Button/Button';

import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import './TableHeader.css';



const TableHeader = (props) => {
  const {
    AddButtonOnClick,
    AddButtonLabel,
    AddButtonWidth,
    AddIcon,
    AddButtonLink,
    TableTitle,
    AddButton,
    AddOutlinedButton,
    AddOutlinedButtonLabel,
    AddIconOutlined,
    AddOutlinedButtonWidth,
    AddOutlinedButtonLink,
    AddOutlinedButtonOnClick,
    AddOutlinedButtonBgColor,
    inviteUserButtondisabled,
    AddMenuButton,
    addMenuBtnArr,
    TableTitleSmall,
  } = props;
  // * ==================== Menu btn ======================= * //
  const [menuBtnEl, setMenuBtnEl] = React.useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const open = Boolean(menuBtnEl);
  const handleClick = (event) => {
    // console.log(event.currentTarget)
    setMenuBtnEl(event.currentTarget);

  };
  const handleClose = () => {
    setMenuBtnEl(null);
  };

  return (
    <>
      <div className="TableHeaderSection">
        <div>
          <h1>{TableTitle}</h1>
          <h2>{TableTitleSmall}</h2>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', flexDirection: 'row-reverse', gap: '8px' }}>
          {AddButton &&
            <Buttons
              label={AddButtonLabel}
              icon={AddIcon}
              buttonColor="#0450E1"
              border="#0450E1 1px solid"
              color="#fff"
              width={AddButtonWidth}
              height="32px"
              fontSize="14px"
              link={AddButtonLink}
              onClick={AddButtonOnClick}
              disabled={inviteUserButtondisabled}
            />
          }
          {
            AddMenuButton && <div>
              <Button
                id="demo-customized-button"
                aria-controls={open ? 'demo-customized-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                variant="contained"
                disableElevation
                onClick={handleClick}
                endIcon={<KeyboardArrowDownIcon />}
                disabled={inviteUserButtondisabled}
                // endIcon={<KeyboardArrowDownIcon style={{ transform: isMenuOpen ? 'rotate(180deg)' : 'rotate(0deg)' }} />}
                sx={{
                  padding: '3px 12px',
                  background: '#0450E1',
                  borderRadius: '8px',
                  fontWeight: '700',
                  "&:hover": {
                    background: '#0450E1',
                  }

                }}
              >
                {props.AddMenuButtonLabel}
              </Button>
              <StyledMenu
                id="demo-customized-menu"
                MenuListProps={{
                  'aria-labelledby': 'demo-customized-button',
                }}
                anchorEl={menuBtnEl}
                open={open}
                onClose={handleClose}

              >
                {addMenuBtnArr.map((menuItem, index) => (
                  <MenuItem key={index} onClick={() => { menuItem.btnFnc(); handleClose(); }} disableRipple className="TableHeaderDropdownListing">
                    {menuItem.label}
                  </MenuItem>
                ))}
              </StyledMenu>
            </div>
          }
          {AddOutlinedButton &&
            <Buttons
              label={AddOutlinedButtonLabel}
              icon={AddIconOutlined}
              buttonColor={AddOutlinedButtonBgColor}
              border="#E6EAF0 1px solid"
              color="#000000"
              width={AddOutlinedButtonWidth}
              height="32px"
              fontSize="14px"
              link={AddOutlinedButtonLink}
              onClick={AddOutlinedButtonOnClick}
            />
          }
        </div>


      </div>

      {props.Border &&
        <div className='BorderBottom'></div>
      }
    </>
  );
};

export default TableHeader;


const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'right',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'right',
    }}
    {...props}
  />
))(({ theme }) => ({
  '& .MuiPaper-root': {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
    boxShadow:
      'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
    '& .MuiMenu-list': {
      padding: '4px 0',
    },
    '& .MuiMenuItem-root': {
      '& .MuiSvgIcon-root': {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      '&:active': {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity,
        ),
      },
    },
  },
}));
